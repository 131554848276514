import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { PmcSharedModule } from "@pmc/shared.module";
import { ForgotPasswordComponent } from "./forgot-password.component";

const routes = [
    {
        path: "forgotpassword",
        component: ForgotPasswordComponent
    }
];

@NgModule({
    declarations: [ForgotPasswordComponent],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        TranslateModule,
        PmcSharedModule
    ],
    exports: [ForgotPasswordComponent]
})
export class ForgotPasswordModule {}
