import { Component, OnInit } from "@angular/core";
import { PmcConfigService } from "@pmc/services/config.service";
import { pmcConfig } from "app/pmc-config";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
    containWidth: boolean;
    logoUrl: string;
    tollFreeNumber: string;
    tollFreeNumberTel: string;

    constructor() {}

    ngOnInit(): void {
        this.containWidth = pmcConfig.layout.footer.contain;
        this.logoUrl = pmcConfig.layout.header.logoUrl;
        this.tollFreeNumber = pmcConfig.layout.header.tollFreeNumber;
        this.tollFreeNumberTel = pmcConfig.layout.header.tollFreeNumber.replace(
            /\D/g,
            ""
        );
    }
}
