import { Component, OnInit, ViewChild, Input } from "@angular/core";
import {
    animate,
    state,
    style,
    transition,
    trigger
} from "@angular/animations";
import { PmcTranslationLoaderService } from "@pmc/services/translation-loader.service";
import { locale as english } from "./i18n/en";
import { UserService } from "app/services/user.service";
import { PrintSaveService } from "app/services/print-save.service";
import {
    PaymentHistory,
    PaymentDetail,
    Order
} from "app/models/paymentHistory";
import { Router } from "@angular/router";
import { EventEmitService } from "app/services/event-emit.service";
import { Subscription } from "rxjs/Subscription";
import { CdkDetailRowDirective } from "@pmc/directives/cdk-detail-row/cdk-detail-row.directive";
import { DataSource } from "@angular/cdk/collections";
import { Observable } from "rxjs/Observable";
import { of, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DropdownValue } from "../medication-list/medication-list.component";
import { AlertService } from "app/services/alert.service";
import { StringService } from "app/services/string.service";
import { PmcConfigService } from "@pmc/services/config.service";

//update payment stuff ** to be removed and made as a separate component
import { ResidentAccount } from "app/models/user/userProfile";
import { ResidentRequest } from "app/models/ResidentRequest";
import { ResidentFinPlan } from "app/models/residentFinPlan";
import { UpdatePaymentService } from "app/services/update-payment.service";
import { ChangePaymentDialogComponent } from "app/main/account/settings/change-payment-dialog/change-payment-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { pmcConfig } from "app/pmc-config";
import { Constants } from "app/services/constants";
import { AppInsightsService } from 'app/appInsights/app-insights.service';


@Component({
    selector: "order-history",
    templateUrl: "./order-history.component.html",
    styleUrls: ["./order-history.component.scss"],
    animations: [
        trigger("detailExpand", [
            state(
                "collapsed",
                style({ height: "0px", minHeight: "0", visibility: "hidden" })
            ),
            state("expanded", style({ height: "*", visibility: "visible" })),
            transition(
                "expanded <=> collapsed",
                animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            )
        ])
    ]
})
export class OrderHistoryComponent implements OnInit {
    private contextSubscription: Subscription;
    paymentHistory: PaymentHistory;
    paymentDetails: PaymentDetail[];
    dataSource: ExampleDataSource;
    isLoading: boolean;
    currentResidentId: number;
    selectedResident: any;
    tempElementData: any;
    activeMonthSelect: number[] = [1, 2, 3, 6, 9, 12, 15, 18, 21, 24];
    activeMonth: number = 1;

    //update payment stuff ** to be removed and made as a separate component
    errorMessage: string = "";
    residentFinPlans: ResidentFinPlan;

    displayedColumns = ["manifestNumber", "totalAmountPaid", "dateOfService"];
    isExpansionDetailRow = (index, row) => row.hasOwnProperty("detailRow");

    @Input() singleChildRowDetail: boolean;

    tollFreeNumberMarkup: string;

    constructor(
        private _pmcTranslationLoaderService: PmcTranslationLoaderService,
        private _pmcConfigService: PmcConfigService,
        private userService: UserService,
        private _eventEmitService: EventEmitService,
        private router: Router,
        private _prtSaveService: PrintSaveService,
        private _stringService: StringService,
        private alertService: AlertService,
        private updatePaymentService: UpdatePaymentService,
        private dialog: MatDialog,
        private appInsightsService: AppInsightsService
    ) {
        this._pmcTranslationLoaderService.loadTranslations(english);

        this.contextSubscription = this._eventEmitService.userContextChangeEvent.subscribe(
            (data: any) => {
                if (
                    this.router.url === "/medications/order-history" &&
                    data.Id !== this.currentResidentId
                )
                    this.activeMonth = 1;
                this.displayPaymentHistory();
                this.enableButtons();
            }
        );
    }

    ngOnDestroy() {
        this.contextSubscription.unsubscribe();
    }

    ngOnInit() {
        this.tollFreeNumberMarkup = this._stringService.markupGetTollFree();
        this.displayPaymentHistory();
        this.enableButtons();
    }

    enableButtons() {
        let self = this;
        self.selectedResident = this.userService.getActiveResident();
    }

    changeDayFilter($event: any, isActiveMeds: boolean) {
        this.filterPaymentDetails(parseInt($event.value));
        this.dataSource = new ExampleDataSource(this.paymentDetails);
    }

    displayPaymentHistory() {
        let self = this;
        this.isLoading = true;
        let fromDate: Date = new Date();
        fromDate.setMonth(13 * -1);
        this.userService.getPaymentHistory().subscribe(
            value => {
                self.paymentHistory = value;
                self.paymentDetails = self.paymentHistory.PaymentDetails;

                self.filterPaymentDetails(self.activeMonth);
                self.dataSource = new ExampleDataSource(self.paymentDetails);
                this.isLoading = false;
            },
            () => {
                this.alertService.info(
                    "Error ocurred while fetching order history"
                );
            }
        );
    }

    filterPaymentDetails(month: number) {
        this.paymentDetails = this.paymentHistory.PaymentDetails.sort(function(
            a,
            b
        ) {
            var c = new Date(a.DateOfService);
            var d = new Date(b.DateOfService);
            return c < d ? 1 : -1;
        });

        let fromDate: Date = new Date();
        fromDate.setMonth(fromDate.getMonth() - month);

        this.paymentDetails = this.paymentDetails.filter(d => {
            return (
                Date.parse(d.DateOfService) >= Date.parse(fromDate.toString())
            );
        });
    }

    private openedRow: CdkDetailRowDirective;
    onToggleChange(cdkDetailRow: CdkDetailRowDirective): void {
        if (
            this.singleChildRowDetail &&
            this.openedRow &&
            this.openedRow.expended
        ) {
            this.openedRow.toggle();
        }
        this.openedRow = cdkDetailRow.expended ? cdkDetailRow : undefined;
    }

    printOrderHistory() {
        this.appInsightsService.logEvent("Order History List Print");
        this._prtSaveService.printAsPdf("savePrint");
    }

    saveOrderHistory() {
        this.appInsightsService.logEvent("Order History List Save");
        this._prtSaveService.saveAsPdf("savePrint", "order_history");
    }

    openChangePaymentDialog() {
        var resident: ResidentAccount = this.userService.getActiveResident();
        var residentRequest: ResidentRequest = new ResidentRequest();
        residentRequest.FacilityCode = resident.FacilityCode;
        residentRequest.PharmacyCode = resident.PharmacyCode;
        residentRequest.ResidentCode = resident.ResidentCode;
        let self = this;
        this.updatePaymentService
            .getResidentFinPlans(residentRequest)
            .subscribe(
                (finPlans: ResidentFinPlan) => {
                    if (
                        finPlans !== null &&
                        finPlans.FinancialPlans !== null &&
                        finPlans.FinancialPlans.length > 0
                    ) {
                        self.errorMessage = "";
                        self.residentFinPlans = finPlans;
                        if (self.residentFinPlans.FinancialPlans.length == 1) {
                            self.getSAMLResponse(
                                self.residentFinPlans.FinancialPlans[0]
                                    .AccountNumber,
                                self
                            );
                        } else {
                            let paymentDailog = this.dialog.open(
                                ChangePaymentDialogComponent,
                                {
                                    width: "380px",
                                    autoFocus: false
                                }
                            );
                            this.appInsightsService.logEvent("Bill Pay Redirect Success. ");
          
                            paymentDailog.componentInstance.residentFinPlans = finPlans;
                        }
                    } else {
                        this.appInsightsService.logEvent("Bill Pay Redirect Failure");
                        this.alertService.info(
                            "Account Information is missing, please call " +
                                pmcConfig.layout.header.tollFreeNumber
                        );
                    }
                },
                () => {
                    this.appInsightsService.logEvent("Bill Pay Redirect Failure");
                    this.alertService.info(
                        "Account Information is missing, please call " +
                            pmcConfig.layout.header.tollFreeNumber
                    );
                }
            );
    }

    getSAMLResponse(accountNumber: string, context: any) {
        this.updatePaymentService.getSAMLResponse(accountNumber);
    }
}

export class ExampleDataSource extends DataSource<any> {
    /** Connect function called by the table to retrieve one stream containing the data to render. */
    paymentDetails: PaymentDetail[];
    constructor(paymentDetails: PaymentDetail[]) {
        super();
        this.paymentDetails = paymentDetails;
    }
    connect(): Observable<PaymentDetail[]> {
        return of(this.paymentDetails);
    }

    disconnect() {}
}
