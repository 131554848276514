import { Component, OnInit, ViewEncapsulation, Output } from "@angular/core";
import {
    FormGroup,
    Validators,
    FormBuilder,
    FormArray,
    AbstractControl,
    ValidatorFn
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { PmcConfigService } from "@pmc/services/config.service";
import { pmcAnimations } from "@pmc/animations";
import {
    SignupUser,
    InviteRequest,
    InviteResponse
} from "../../../models/signup";
import { SignupService } from "../../../services/signup.service";
import { AppUserAuth, AppUser } from "app/models/user/app-user";
import { UserService } from "app/services/user.service";
import { UserProfile, ResidentAccount, LogUser } from "app/models/user/userProfile";
import { AuthService } from "app/services/auth.service";
import { pmcConfig } from "app/pmc-config";
import { PmcNavigationService } from "@pmc/components/navigation/navigation.service";
import { PmcPerfectScrollbarDirective } from "@pmc/directives/pmc-perfect-scrollbar/pmc-perfect-scrollbar.directive";
import { Constants } from "app/services/constants";
import { AppInsightsService } from "app/appInsights/app-insights.service";

function passwordMatcher(
    c: AbstractControl
): { [key: string]: boolean } | null {
    const passwordControl = c.get("password");
    const confirmControl = c.get("confirmPassword");
    if (passwordControl.pristine || confirmControl.pristine) {
        return null;
    }

    if (passwordControl.value === confirmControl.value) {
        return null;
    }
    return { match: true };
}

@Component({
    selector: "account-signup",
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: pmcAnimations
})
export class SignupComponent implements OnInit {
    signupForm: FormGroup;

    signupUser = new SignupUser();
    inviteRequest: InviteRequest;
    inviteResponse: InviteResponse;
    submitted: boolean = false;
    pageStatus: string = "";
    authObject: AppUserAuth = null;
    isEmailSignup: boolean = false;
    errorMessage: string = "";
    lblFirstName: string = "First Name";
    lblLastName: string = "Last Name";
    userProfile: UserProfile;
    residents: ResidentAccount[];
    isLockedByAttempts: boolean;
    logUser: LogUser;

    tollFreeNumber: string;
    tollFreeNumberTel: string;
    attemptMax: number;

    @Output()
    isLoading: boolean;
    constructor(
        private _pmcConfigService: PmcConfigService,
        private _pmcNavigationService: PmcNavigationService,
        private fb: FormBuilder,
        private signupService: SignupService,
        private authService: AuthService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private _pmcPerfectScrollbar: PmcPerfectScrollbarDirective,
        private appInsightsService: AppInsightsService
    ) {
        // Configure the layout
        this._pmcConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                header: {
                    hidden: true
                },
                body: {
                    contain: false
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.attemptMax = pmcConfig.lockoutAttemptMax;
        this.tollFreeNumber = pmcConfig.layout.header.tollFreeNumber;
        this.tollFreeNumberTel = pmcConfig.layout.header.tollFreeNumber.replace(
            /\D/g,
            ""
        );

        this.authService.logout();
        const usernameRegex = /^[a-zA-Z0-9\.]*$/;
        const passwordRegex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&amp;*\-_+=[\]{}|\\:',?/`~&quot;();!]|\.(?!@)){8,16}$/;
        const last4SsnRegex = /^\d{4}$/;
        this.signupForm = this.fb.group({
            username: [
                "",
                [Validators.required, Validators.pattern(usernameRegex)]
            ],
            emailGroup: this.fb.group({
                email: ["", Validators.email]
            }),
            
            memberIdGroup: this.fb.group({
                memberId: ["", Validators.required]
            }),
            last4SsnGroup: this.fb.group({
                last4Ssn: [
                    "",
                    [Validators.required, Validators.pattern(last4SsnRegex)]
                ]
            }),
            pswdGroup: this.fb.group(
                {
                    password: [
                        "",
                        [Validators.required, Validators.pattern(passwordRegex)]
                    ],
                    confirmPassword: ["", Validators.required]
                },
                { validator: passwordMatcher }
            ),
           
            nameGroup: this.fb.group({
                lastName: ["", Validators.required],
                firstName: ["", Validators.required]

            }),
            userTypeGroup: this.fb.group({
                userType: ["", Validators.required]
            })
        });
        this.getInvite();
    }
    private onChange()
    {
        
        if (this.signupForm.get("userTypeGroup").get("userType").value == "resident")
        {
            this.lblFirstName = "Resident First Name";
            this.lblLastName = "Resident Last Name";
        }
        else
        {
            this.lblFirstName = "Responsible Party First Name";
            this.lblLastName = "Responsible Party Last Name";
        }
    }
    /**
     * Check if username valid, true is not exist
     *
     * @private
     * @returns {boolean} if username exist
     * @memberof Signup new user
     */
    private isValidUserName(): boolean {
        let valid: boolean = false;
        this.errorMessage = "";
        this.signupService
            .validateUserName(this.signupForm.get("username").value)
            .subscribe(value => {
                if (value == true) {
                    this.errorMessage =
                        "Username already exist. Please try another one.";
                }
                return value;
            });
        return valid;
    }

    lockPage() {
        this.setStatus("status--restricted");
        this.isLockedByAttempts = true;
    }

    save(): void {
        var self = this;
        self.signupUser.Message = "";
        if (this.signupForm.valid) {
            if (this.signupForm.dirty) {
                this.isLoading = true;
                this.pageStatus = "";
                this.setSignUpUserFromForm();
                this.appInsightsService.clearAuthenticatedUserId();
                this.signupService.signup(this.signupUser).subscribe(
                    data => {
                        self.signupUser = data;
                        //check message if
                        if (self.signupUser.Message == "OK") {
                            self.login(self.signupUser);
                        } 
                        else if (self.signupUser.Message == "Error: try end") {
                            this.appInsightsService.logEvent("Registration Failure");
                            self.router.navigate(["/login"]);
                        } else if (self.signupUser.Message == "login") {
                           
                            this.appInsightsService.logEvent("Registration Failure");
                            self.router.navigate(["/login"]);
                        } else {
                           
                            this.appInsightsService.logEvent("Registration Failure");
                            if (self.signupUser.Attempts > this.attemptMax) {
                                this.lockPage();
                            } else {
                                this.errorMessage = self.signupUser.Message;
                            }
                        }
                    },
                    () => null,
                    () => {
                        this.isLoading = false;
                    }
                );
            }
        } else {
            this.appInsightsService.logEvent("Registration Failure");
            this.errorMessage = "Invalid member information.";
        }
    }
    login(user: SignupUser) {
        var self = this;
        this.isLoading = true;
        self.errorMessage = "";
        var appuser = new AppUser();
        appuser.password = user.Password;
        appuser.userName = user.UserName;
        appuser.token = user.Token;
        this.authService.loginNewUser(
            appuser,
            (authObj: AppUserAuth) => {
                self.authObject = authObj;
                this.userService.getUserProfile().subscribe(value => {
                    self.isLoading = false;
                    self.userProfile = value;
                    if (
                        self.userProfile != null &&
                        self.userProfile.ResidentAccounts != null
                    ) {
                        self.residents = self.userProfile.ResidentAccounts;
                        self.userService.setMyUserProfile(self.userProfile);
                        self.userService.setAllResidents(self.residents);
                        self.userService.setActiveResident(
                            self.residents[0].Id
                        );
                        self.logUser = new LogUser();
                        self.logUser.FacilityName = self.residents[0].FacilityName;
                        self.logUser.PharmacyName = self.residents[0].PharmacyName;
                        self.logUser.UserType = self.residents[0].Role;
                        self.loadNavigation();
                        self.router.navigate(["/dashboard"]);
                        this.appInsightsService.setAuthenticatedUserId(appuser.userName);
                        this.appInsightsService.logEventJson("Registration Success", self.logUser);
                       

                    }
                });
            },
            (data: any) => {
                self.errorMessage =
                    "Unable to validate your credential. Please verify your credentials and try again.";
                self.isLoading = false;
            },
            true
        );
    }

    loadNavigation() {
        var user = this.userService.getMyUserProfile();
        var navs = user.Navigations;
        this._pmcNavigationService.onNavigationUnregistered;
        this._pmcNavigationService.unregister("main");
        // Register the navigation to the service
        this._pmcNavigationService.register("main", navs);

        // Set the main navigation as our current navigation
        this._pmcNavigationService.setCurrentNavigation("main");
    }
    setSignUpUserFromForm(): void {
        this.signupUser.Message = "";
        if (this.signupForm.get("emailGroup") == null)
            this.signupUser.Email = this.inviteResponse.Email;
        else
            this.signupUser.Email = this.signupForm
                .get("emailGroup")
                .get("email").value;
        if (this.signupForm.get("memberIdGroup") == null)
            this.signupUser.MemberId = this.inviteResponse.MemberId;
        else
            this.signupUser.MemberId = this.signupForm
                .get("memberIdGroup")
                .get("memberId").value;

        this.signupUser.Password = this.signupForm
            .get("pswdGroup")
            .get("password").value;
        this.signupUser.UserName = this.signupForm.get("username").value;
        if (this.signupForm.get("last4SsnGroup") == null)
            this.signupUser.Last4Ssn = "";
        else
            this.signupUser.Last4Ssn = this.signupForm
                .get("last4SsnGroup")
                .get("last4Ssn").value;
        if (this.signupForm.get("nameGroup") == null) {
            this.signupUser.FirstName = this.inviteResponse.FirstName;
            this.signupUser.LastName = this.inviteResponse.LastName;
        } else {
            this.signupUser.FirstName = this.signupForm
                .get("nameGroup")
                .get("firstName").value;
            this.signupUser.LastName = this.signupForm
                .get("nameGroup")
                .get("lastName").value;
        }
        if (this.signupForm.get("userTypeGroup") == null) {
            this.signupUser.UserType = this.inviteResponse.UserType;
        } else {
            this.signupUser.UserType = this.signupForm
                .get("userTypeGroup")
                .get("userType").value;
        }
    }

    cancel(e: any) {
        e.preventDefault();
        this.router.navigate(["/login"]);
    }
    /**
     * get invite resposne from invite request: invite is active user goes to login, load signup based on
     *  invite resposne
     * @private
     * @returns inviteResponse
     * @memberof signup
     */
    getInvite(): void {
        var self = this;
        this.inviteRequest = new InviteRequest();
        this.inviteRequest.token = this.route.snapshot.queryParamMap.get(
            "emailinvite"
        );
        this.signupService.invite(this.inviteRequest).subscribe(
            data => {
                if (data !== null) {
                    self.isEmailSignup = true;
                    self.inviteResponse = data;
                    if (self.inviteResponse.Redirect == "login")
                        self.router.navigate(["/login"]);
                    else if (self.inviteResponse.Redirect == "Error") {
                        self.submitted = true;
                        // need to show error page
                        self.errorMessage = self.inviteResponse.Result;
                    } else {
                        self.signupForm.patchValue({
                            memberIdGroup: {
                                memberId: self.inviteResponse.MemberId
                            },
                            emailGroup: { email: self.inviteResponse.Email },
                            nameGroup: {
                                firstName: self.inviteResponse.FirstName,
                                lastName: self.inviteResponse.LastName
                            },
                            userTypeGroup: {
                                userType: self.inviteResponse.UserType
                            }
                        });
                        // default
                        self.signupForm
                            .get("emailGroup")
                            .get("email")
                            .disable();
                        self.signupForm
                            .get("memberIdGroup")
                            .get("memberId")
                            .disable();
                        self.signupForm
                            .get("nameGroup")
                            .get("firstName")
                            .disable();
                        self.signupForm
                            .get("nameGroup")
                            .get("lastName")
                            .disable();

                        if (self.inviteResponse.UserType == "Resident") {
                            self.signupForm.removeControl("nameGroup");
                            self.signupForm.removeControl("userTypeGroup");
                        } else if (
                            self.inviteResponse.UserType == "ResponsibleParty"
                        ) {
                            self.signupForm.removeControl("userTypeGroup");
                            self.signupForm
                                .get("nameGroup")
                                .get("firstName")
                                .enable();
                            self.signupForm
                                .get("nameGroup")
                                .get("lastName")
                                .enable();
                            self.signupForm.patchValue({
                                nameGroup: {
                                    firstName: "",
                                    lastName: ""
                                }
                            });
                            this.lblFirstName = "Responsible Party First Name";
                            this.lblLastName = "Responsible Party Last Name";
                        } else if (
                            self.inviteResponse.UserType ==
                            "AuthorizedRepresentative"
                        ) {
                            //hide ssn, memberId
                            self.signupForm.removeControl("userTypeGroup");
                            self.signupForm.removeControl("emailGroup");
                            self.signupForm.removeControl("memberIdGroup");
                            self.signupForm.removeControl("last4SsnGroup");
                            self.signupForm
                                .get("nameGroup")
                                .get("firstName")
                                .enable();
                            self.signupForm
                                .get("nameGroup")
                                .get("lastName")
                                .enable();
                            self.signupForm.patchValue({
                                nameGroup: {
                                    firstName: "",
                                    lastName: ""
                                }
                            });
                        }
                    }
                }
            },
            error => (this.errorMessage = <any>error)
        );
    }

    setStatus(status: string) {
        this.pageStatus = status;
        this._pmcPerfectScrollbar.scrollToTop();
    }
}
