import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
//import {
//    MatButtonModule, MatCheckboxModule, MatDividerModule, MatFormFieldModule, MatIconModule, MatOptionModule, MatRadioModule, MatSelectModule, MatSlideToggleModule
//} from '@angular/material';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { PmcDirectivesModule } from '@pmc/directives/directives';
import { PmcMaterialColorPickerModule } from '@pmc/components/material-color-picker/material-color-picker.module';
import { PmcSidebarModule } from '@pmc/components/sidebar/sidebar.module';

import { PmcThemeOptionsComponent } from '@pmc/components/theme-options/theme-options.component';

@NgModule({
    declarations: [
        PmcThemeOptionsComponent
    ],
    imports     : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        MatButtonModule,
        MatCheckboxModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,

        PmcDirectivesModule,
        PmcMaterialColorPickerModule,
        PmcSidebarModule
    ],
    exports     : [
        PmcThemeOptionsComponent
    ]
})
export class PmcThemeOptionsModule
{
}
