import{ ResidentRequest } from './ResidentRequest'

export class DemographicInfo{
    constructor(
    public DateOfBirth: Date,
    public FacilityName: string,
    public FirstName: string,
    public LastName: string,
    public Plan: PlanObj,
    public  ResidentRequest: ResidentRequest,
    public ResponsiblePartyLastName: string,
    public ResponsiblePartyFirstName: string,
    public ResponsiblePartyHomePhone: string,
    public ResponsiblePartyWorkPhone: string,
    public ResponsiblePartyEmail: string,
    public ResponsiblePartyAddressLine1: string,
    public ResponsiblePartyAddressLine2: string,
    public ResponsiblePartyCity: string,
    public ResponsiblePartyState: string,
    public ResponsiblePartyZipCode: string,
    public ResponsiblePartyZipSuffix: string,
    public ResponsibleParty: DResponsibleParty) 
    {}
}

export class PlanObj{
    constructor (
    public PlanName: string,
    public PlanDate: string,
    public InsurerProviderName: string,
    public InsurerProviderPhone: string,
    public InsurerProviderAddressLine1: string,
    public InsurerProviderAddressLine2 : string,
    public InsurerProviderAddressCity : string,
    public InsurerProviderAddressState : string,
    public InsurerProviderAddressZipCode : string,
    public InsurerProviderWebAddress : string)
    {}
}
export class DResponsibleParty
{
    constructor(
    public ResponsiblePartyLastName:  string,
    public ResponsiblePartyFirstName:  string,
    public ResponsiblePartyHomePhone:  string,
    public ResponsiblePartyWorkPhone:  string,
    public ResponsiblePartyEmail:  string,
    public ResponsiblePartyAddressLine1:  string,
    public ResponsiblePartyAddressLine2:  string,
    public ResponsiblePartyCity:  string,
    public ResponsiblePartyState:  string,
    public ResponsiblePartyZipCode:  string,
    public ResponsiblePartyZipSuffix:  string)
    {}

}

