import { Injectable, EventEmitter } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class EventEmitService {
    addAccountEvent = new EventEmitter();
    userContextChangeEvent = new EventEmitter<any>();
    constructor() {}
}
