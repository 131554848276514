import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PmcTranslationLoaderService } from "@pmc/services/translation-loader.service";
import { locale as english } from "./i18n/en";

import { UserProfile } from "app/models/user/userProfile";
import { UserService } from "app/services/user.service";

import { EventEmitService } from "app/services/event-emit.service";
import { Subscription } from "rxjs/Subscription";
import { MatDialog } from "@angular/material/dialog";
import { ChangePasswordDialogComponent } from "./change-password-dialog/change-password-dialog.component";
import { ChangePaymentDialogComponent } from "./change-payment-dialog/change-payment-dialog.component";
import { ResidentFinPlan } from "app/models/residentFinPlan";
import { UpdatePaymentService } from "app/services/update-payment.service";
import { ResidentAccount } from "app/models/user/userProfile";
import { ResidentRequest } from "app/models/ResidentRequest";
import { BofaSAMLResponse } from "app/models/bofaSAMLResponse";
import { AlertService } from "app/services/alert.service";
import { pmcConfig } from "app/pmc-config";
import { Constants } from "app/services/constants";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AppInsightsService } from "app/appInsights/app-insights.service";
import { Console } from "console";

@Component({
    selector: "account-settings",
    templateUrl: "./account-settings.component.html",
    styleUrls: ["./account-settings.component.scss"]
})
export class AccountSettingsComponent implements OnInit {
    private contextSubscription: Subscription;
    userProfile: UserProfile;
    isLoading: boolean;
    errorMessage: string = "";
    currentResidentId: number;
    selectedResident: ResidentAccount;
    residentFinPlans: ResidentFinPlan;
    emailForm: FormGroup;

    /**
     * Constructor
     *
     * @param {PmcTranslationLoaderService} _pmcTranslationLoaderService
     */
    constructor(
        private fb: FormBuilder,
        private _pmcTranslationLoaderService: PmcTranslationLoaderService,
        private dialog: MatDialog,
        private userService: UserService,
        private _eventEmitService: EventEmitService,
        private router: Router,
        private updatePaymentService: UpdatePaymentService,
        private alertService: AlertService,
        private appInsightsService: AppInsightsService
    ) {
        this._pmcTranslationLoaderService.loadTranslations(english);
        this.contextSubscription = this._eventEmitService.userContextChangeEvent.subscribe(
            (data: any) => {
                if (
                    this.router.url === "/account/settings" &&
                    data.Id !== this.currentResidentId
                )
                    this.ngOnInit();
            }
        );
    }
    onSubmit(){
        this.isLoading = true;
        var self = this;
        var email = this.userProfile.Email;
        console.log(email);
        if ( !this.emailForm.valid) return;
        if ( this.emailForm.get("emailGroup").get("email").value == "")
        {
            this.alertService.error("Please enter a valid email address.");
            this.isLoading = false
            return;
        }
        if ( this.emailForm.get("emailGroup").get("email").value == this.userProfile.Email)
        {
            this.alertService.error("Please enter a different email address.");
            this.isLoading = false
            return;
        }
        this.userProfile.Email = this.emailForm.get("emailGroup").get("email").value;

        this.userService
            .changeMyEmail(this.userProfile)
            .subscribe(
                value => {
                    if (value === true) {
                       
                        this.appInsightsService.logEvent("Email has been changed Success.");
                        this.userProfile.EmailStatus = "changed";
                        this.userService.setMyUserProfile(this.userProfile);
                        this.alertService.success(
                            "Your email address has been saved."
                        );
                       
                    }
                },
                (error: any) => {
                    
                    this.appInsightsService.logEvent("Email Change Failure.");
                    this.alertService.error(
                        "Unable to change email please try again"
                    );
                },
                () => (this.isLoading = false)
            );

    }
    ngOnInit(): void {
        this.userProfile = this.userService.getMyUserProfile();
        this.enableButtons();
        this.emailForm = this.fb.group({
            emailGroup: this.fb.group ({
                email: [this.userProfile.Email,  [Validators.required, Validators.email]]
            })
        });
        
    }

    enableButtons() {
        let self = this;
        self.selectedResident = this.userService.getActiveResident();
    }

    addAccount() {
        this.router.navigate(["account/add-account"]);
    }

    openChangePasswordDialog(): void {
        this.dialog.open(ChangePasswordDialogComponent, {
            width: "500px",
            autoFocus: false
        });
    }

    openChangePaymentDialog() {
        var resident: ResidentAccount = this.userService.getActiveResident();
        var residentRequest: ResidentRequest = new ResidentRequest();
        residentRequest.FacilityCode = resident.FacilityCode;
        residentRequest.PharmacyCode = resident.PharmacyCode;
        residentRequest.ResidentCode = resident.ResidentCode;
        let self = this;
        this.updatePaymentService
            .getResidentFinPlans(residentRequest)
            .subscribe(
                (finPlans: ResidentFinPlan) => {
                    if (
                        finPlans !== null &&
                        finPlans.FinancialPlans !== null &&
                        finPlans.FinancialPlans.length > 0
                    ) {
                        self.errorMessage = "";
                        self.residentFinPlans = finPlans;
                        if (self.residentFinPlans.FinancialPlans.length == 1) {
                            self.getSAMLResponse(
                                self.residentFinPlans.FinancialPlans[0]
                                    .AccountNumber,
                                self
                            );
                        } else {
                            let paymentDailog = this.dialog.open(
                                ChangePaymentDialogComponent,
                                {
                                    width: "420px"
                                }
                            );
                            this.appInsightsService.logEvent("Bill Pay Redirect Success");
                            paymentDailog.componentInstance.residentFinPlans = finPlans;
                        }
                    } else {
                        this.appInsightsService.logEvent("Bill Pay Redirect Failure.");
                        this.alertService.info(
                            "Account Information is missing, please call " +
                                pmcConfig.layout.header.tollFreeNumber
                        );
                    }
                },
                () => {
                    this.appInsightsService.logEvent("Bill Pay Redirect Failure.");
                    this.alertService.info(
                        "Account Information is missing, please call " +
                            pmcConfig.layout.header.tollFreeNumber
                    );
                }
            );
    }
    invite() {
        this.router.navigate(["account/invite-family-member"]);
    }

    getSAMLResponse(accountNumber: string, context: any) {
        let me = context;
        this.updatePaymentService.getSAMLResponse(accountNumber);
    }
}
