export class AppUser  {
    userName: string = "";
    password: string = "";
    token: string = "";
  }
export class AppUserAuth {
  userName: string = "";
  bearerToken: string = "";
  isAuthenticated: boolean = false;
  isNotification: boolean = false;
  isExternalLink: boolean = false;
  isStoreLink: boolean = false;
  
}
  