import { Injectable } from "@angular/core";
import { AppInsightsService } from "app/appInsights/app-insights.service";

@Injectable({
    providedIn: "root"
})
export class PrintSaveService {
    constructor(
        private appInsightsService: AppInsightsService
    ) { }

    printAsPdf(elementId: string) {
        if (elementId === 'active-med-list')
            this.appInsightsService.logEvent("Active Medication List Print");
        else
            this.appInsightsService.logEvent("Medication History List Print");
        var data = document.getElementById(elementId);
        data.style.display = "block";
        var wH = data.clientHeight + 200;
        data.style.display = "none";
        const WindowPrt = window.open(
            "",
            "",
            "left=0,top=0,width=900,height=" +
            wH +
            ",toolbar=0,scrollbars=0,status=0"
        );
        WindowPrt.document.write(
            '<html><head><base href="/" /><title>' + document.title + "</title>"
        );
        WindowPrt.document.write(
            '<style type="text/css">@media print { .noprint { display: none; } .print-only{ display: block;}}</style>'
        );
        WindowPrt.document.write(
            '<link rel="stylesheet" href="/assets/scss/order-history-print.css" media="screen,print" />'
        );
        WindowPrt.document.write(
            '<link href="https://fonts.googleapis.com/css?family=Roboto:400,300,200,500,700&amp;subset=latin,cyrillic" rel="stylesheet" type="text/css" />'
        );
        WindowPrt.document.write("</head><body>");
        WindowPrt.document.write(data.innerHTML);
        WindowPrt.document.write("</body></html>");
        WindowPrt.document.close();
        setTimeout(function () {
            WindowPrt.focus();
            WindowPrt.print();
            WindowPrt.close();
        }, 1000);
    }

    saveAsPdf(elementId: string, fileName: string, jsEngineLocation?: string) {
        if (elementId === 'active-med-list')
            this.appInsightsService.logEvent("Active Medication List Save");
        else
            this.appInsightsService.logEvent("Medication History List Save");
        var _jsfile = jsEngineLocation || "/assets/pdf/pdf-engine.js";
        var data = document.getElementById(elementId);
        data.style.display = "block";
        var wH = data.clientHeight;
        var bH = window.innerHeight;
        var h = wH < bH - 300 ? bH : wH;
        data.style.display = "none";
        const WindowPrt = window.open(
            "",
            "",
            "left=0,top=0,width=933,height=" +
            h +
            ",toolbar=0,scrollbars=0,status=0"
        );
        WindowPrt.document.write(
            '<html><head><base href="/" /><title>' + document.title + "</title>"
        );
        WindowPrt.document.write(
            '<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />'
        );
        WindowPrt.document.write(
            '<link rel="stylesheet" href="/assets/scss/order-history-print.css" />'
        );
        WindowPrt.document.write(
            '<style type="text/css">@media print { .noprint { display: none; } .print-only{ display: block;}} .fullWidth { width: 100%; }</style>'
        );
        WindowPrt.document.write(
            '<link href="https://fonts.googleapis.com/css?family=Roboto:400,300,200,500,700&amp;subset=latin,cyrillic" rel="stylesheet" type="text/css" />'
        );
        WindowPrt.document.write(
            '<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>'
        );
        WindowPrt.document.write(
            '<script src="/assets/pdf/html2pdf.bundle.min.js"></script>'
        );
        WindowPrt.document.write('<script src="' + _jsfile + '"></script>');
        WindowPrt.document.write('</head><body id="savePdf">');
        WindowPrt.document.write(data.innerHTML);
        WindowPrt.document.write("</body><script>");
        WindowPrt.document.write(
            `$(document).ready(function () { setTimeout(function() { getPDF('savePdf','${fileName}'); }, 500); });`
        );
        WindowPrt.document.write("</script></html>");
        WindowPrt.document.close();
        WindowPrt.focus();
    }
}
