export class UserStatusIndicator {
    type: UserStatusIndicatorType;
    message: string;
    userStatusIndicatorId: string;
    keepAfterRouteChange: boolean;

    constructor(init?: Partial<UserStatusIndicator>) {
        this.userStatusIndicatorId = "";
        this.message = "";
        this.type = UserStatusIndicatorType.Active;
        Object.assign(this, init);
    }
}

export enum UserStatusIndicatorType {
    Active,
    ReadOnly
}
