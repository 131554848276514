import { Injectable } from '@angular/core';
import { Config } from '../../app/config/config';
import { AppInsights } from 'applicationinsights-js';
import { ActivatedRouteSnapshot, ActivatedRoute, ResolveEnd, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  private routerSubscription: Subscription;
  private config: Microsoft.ApplicationInsights.IConfig = {
    instrumentationKey: Config.settings.appInsights.instrumentationKey,
    enableCorsCorrelation: true 
  }
  constructor(private router: Router, 
    private activatedRoute: ActivatedRoute
    ) {
    if (!AppInsights.config) {
      AppInsights.downloadAndSetup(this.config);

    }
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
    ).subscribe((event: NavigationEnd) => {
      const activatedComponent = this.getActivatedComponent(router.routerState.snapshot.root);
      if (activatedComponent) {
          this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(router.routerState.snapshot.root)}`, event.urlAfterRedirects);
      }
    }); 
  }
  setAuthenticatedUserId(userId: string): void {
    AppInsights.setAuthenticatedUserContext(userId);
  }
  clearAuthenticatedUserId(): void {
    AppInsights.clearAuthenticatedUserContext();
  }
  public logPageView(
    name: string,
    url?: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number },
    duration?: number) {

    AppInsights.trackPageView(name, url, this.AddGlobalProperties(properties), measurements, duration);
  }
  logEvent(name: string, properties?: any, measurements?: any) {
    AppInsights.trackEvent(name, properties, measurements);
  }
  logEventJson(name: string, json?: any) {
    AppInsights.trackEvent(name, json);
  }
  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }
  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }
    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
  private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
    if (!properties) {
        properties = {};
    }

    //add your custom properties such as app version
    return properties;
}
}
