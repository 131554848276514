import { Component, Input, OnInit } from "@angular/core";
import { DynamicComponent } from "app/models/ui/dynamicComponent";
import { InfoIcon, InfoIconType } from "app/models/ui/infoIcon";

@Component({
    template: `
        <div class="card pb-0 rounded">
            <div class="tile default tile-resident-allergies pb-0">
                <div class="tile-header">
                    <span class="fa fa-medkit" aria-hidden="true"></span>
                    {{ data.title }}
                </div>
                <div class="tile-body">
                    <div class="info-icons" *ngFor="let ra of infoIcons">
                        <app-info-icon [infoIcon]="ra"></app-info-icon>
                    </div>
                    <ul *ngIf="infoIcons.length == 0" class="nvp-list p-0">
                        <li>No allergies documented</li>
                    </ul>
                </div>
            </div>
        </div>
    `
})
export class TileResidentAllergiesComponent
    implements DynamicComponent, OnInit {
    @Input() data: any;
    infoIcons: InfoIcon[];

    constructor() {
        this.infoIcons = [];
    }

    initTile() {
        this.data.successFn = () => {
            this.loadComponent();
        };
        this.loadComponent();
    }

    ngOnInit() {
        this.initTile();
    }

    loadComponent() {
        if (this.data.tileData && this.data.tileData.Allergies.length > 0) {
            let items = this.data.tileData.Allergies;
            if (items.length > 0) {
                items.forEach(obj => {
                    let icon = new InfoIcon();
                    icon.type = InfoIconType.CustomImage;
                    icon.iconName = obj.ClassificationType;
                    icon.list = obj.ClassificationDescription.split(",");
                    icon.iconInstruction =
                        "/assets/icons/custom/" +
                        obj.ClassificationType +
                        ".png";
                    this.infoIcons.push(icon);
                });
            }
        }
    }
}
