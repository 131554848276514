import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppInsightsService } from 'app/appInsights/app-insights.service';
import { PmcConfigService } from "@pmc/services/config.service";
import { PmcNavigationService } from "@pmc/components/navigation/navigation.service";
import { PmcSidebarService } from "@pmc/components/sidebar/sidebar.service";
import { PmcSplashScreenService } from "@pmc/services/splash-screen.service";
import { PmcTranslationLoaderService } from "@pmc/services/translation-loader.service";

import { EventEmitService } from "./services/event-emit.service";
import { UserService } from "./services/user.service";

import { navigation } from "app/navigation/navigation";
import { NavigationEnd, Router } from "@angular/router";
import { Config } from "app/config/config";


@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
    pmcConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {PmcConfigService} _pmcConfigService
     * @param {PmcNavigationService} _pmcNavigationService
     * @param {PmcSidebarService} _pmcSidebarService
     * @param {PmcSplashScreenService} _pmcSplashScreenService
     * @param {PmcTranslationLoaderService} _pmcTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _pmcConfigService: PmcConfigService,
        private _pmcNavigationService: PmcNavigationService,
        private _pmcSidebarService: PmcSidebarService,
        private _pmcSplashScreenService: PmcSplashScreenService,
        private _pmcTranslationLoaderService: PmcTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private userService: UserService,
        private router: Router,
        private appInsight: AppInsightsService
    ) {
        var user = userService.getMyUserProfile();
        if (user == null) {
            // Get default navigation
            this.navigation = navigation;
        }
        else
            this.navigation = user.Navigations;
        // Register the navigation to the service
        this._pmcNavigationService.register("main", this.navigation);

        // Set the main navigation as our current navigation
        this._pmcNavigationService.setCurrentNavigation("main");

        // Add languages
        this._translateService.addLangs(["en"]);

        // Set the default language
        this._translateService.setDefaultLang("en");

        // Set the navigation translations
        //this._pmcTranslationLoaderService.loadTranslations(navigationEnglish);

        // Use a language
        this._translateService.use("en");
        appInsight.logPageView("MainPage");
        
       
        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._pmcConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                this.pmcConfig = config;

                // Boxed
                if (this.pmcConfig.layout.width === "boxed") {
                    this.document.body.classList.add("boxed");
                } else {
                    this.document.body.classList.remove("boxed");
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith("theme-")) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.pmcConfig.colorTheme);
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._pmcSidebarService.getSidebar(key).toggleOpen();
    }
}
