import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NotificationsModule } from "./notifications/notifications.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { AccountSettingsModule } from "./account/settings/account-settings.module";
import { ManageDoctorsModule } from "./account/manage-doctors/manage-doctors.module";
import { MedicationListModule } from "./medications/medication-list/medication-list.module";
import { OrderHistoryModule } from "./medications/order-history/order-history.module";
import { LoginModule } from "./account/login/login.module";
import { SignupModule } from "./account/signup/signup.module";
import { AuthGuard } from "../services/auth.guard";
import { AuthService } from "../services/auth.service";
import { ErrorInterceptor } from "../helpers/error.interceptors";
import { JwtInterceptor } from "../helpers/jwt.interceptors";
import { SignupComponent } from "./account/signup/signup.component";
import { ToolbarModule } from "app/layout/components/toolbar/toolbar.module";
import { PmcSharedModule } from "@pmc/shared.module";
import { ForgotPasswordModule } from "./account/forgot-password/forgot-password.module";
import { ForgotPasswordComponent } from "./account/forgot-password/forgot-password.component";
import { ForgotPswdlinkComponent } from "./account/forgot-pswdlink/forgot-pswdlink.component";
import { ForgotPswdlinkModule } from "./account/forgot-pswdlink/forgot-pswdlink.module";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { CommonModule } from "@angular/common";
import { EnrollComponent } from "./enroll/enroll.component";
import { EnrollModule } from "./enroll/enroll.module";

const appRoutes: Routes = [
    {
        path: "dashboard",
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: "medications",
        loadChildren: () => import('./medications/medications.module').then(m => m.MedicationsModule),
        canActivate: [AuthGuard]
    },
    {
        path: "account",
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
    },
    {
        path: "notifications",
        loadChildren:
            () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [AuthGuard]
    },
    {
        path: "activate",
        component: SignupComponent
    },
    {
        path: "enroll",
        component: EnrollComponent
    },
    {
        path: "pswdlink",
        component: ForgotPswdlinkComponent
    },
    {
        path: "forgotpswd",
        component: ForgotPasswordComponent
    },
    {
        path: "error/:id",
        component: ErrorPageComponent
    },
    {
        path: "",
        redirectTo: "account/login",
        pathMatch: "full"
    },
    {
        path: "**",
        redirectTo: "error/404"
    }
];

@NgModule({
    imports: [
        CommonModule,
        PmcSharedModule,
        NotificationsModule,
        ToolbarModule,
        DashboardModule,
        AccountSettingsModule,
        ManageDoctorsModule,
        MedicationListModule,
        OrderHistoryModule,
        HttpClientModule,
        ForgotPasswordModule,
        ForgotPswdlinkModule,
        LoginModule,
        SignupModule,
        EnrollModule,
        RouterModule.forRoot(appRoutes, { onSameUrlNavigation: "reload", relativeLinkResolution: 'legacy' })
    ],
    providers: [
        AuthGuard,
        AuthService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    declarations: [ErrorPageComponent]
})
export class PagesModule {}
