import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA} from "@angular/material/dialog";
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { UserService } from 'app/services/user.service';
import { AppInsightsService } from 'app/appInsights/app-insights.service';

@Component({
  selector: 'app-ehealth-dailog',
  templateUrl: './ehealth-dailog.component.html',
  styleUrls: ['./ehealth-dailog.component.scss']
})
export class EhealthDailogComponent implements OnInit {
  zipcodeForm: FormGroup;
  errorMessage: string = "";
  
  constructor(
    public dialogRef: MatDialogRef<EhealthDailogComponent>,
    private fb: FormBuilder,
    private userService: UserService,
    private appInsightsService: AppInsightsService
    ) {}

  ngOnInit() {
    const zipcodeRegex = /^(?:\d{5}(?:-\d{4})?)?$/;
    this.zipcodeForm = this.fb.group({
      zipcodeGroup: this.fb.group( {
        zipcode: new FormControl("",Validators.compose([Validators.required, Validators.pattern(zipcodeRegex)]))
      })
    });
  }
  redirect() : void
  {
    if ( this.zipcodeForm.valid){
      this.appInsightsService.logEvent("eHealth Redirect.");
      let zipcode = this.zipcodeForm.get("zipcodeGroup").get("zipcode").value;
    

      this.userService.getUserUrl().subscribe(
        value => {
          
          let form = document.createElement('form');
          form.setAttribute('method', 'post');
          form.setAttribute('target', '_blank');
          form.setAttribute('target', '_blank');
        
          form.setAttribute('action', value + '&zip='+zipcode);
          document.body.append(form);

          form.submit();
          this.dialogRef.close();
        }
      )
     

      
    }

    
    
    

  }

}
