import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { PMC_CONFIG } from '@pmc/services/config.service';

@NgModule()
export class PmcModule
{
    constructor(@Optional() @SkipSelf() parentModule: PmcModule)
    {
        if ( parentModule )
        {
            throw new Error('PmcModule is already loaded. Import it in the AppModule only!');
        }
    }
    static forRoot(config): ModuleWithProviders<any>
    {
        return {
            ngModule : PmcModule,
            providers: [
                {
                    provide : PMC_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
