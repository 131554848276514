import { Component, OnInit, ViewEncapsulation, Output, ViewChild } from "@angular/core";
import {
    FormGroup,
    Validators,
    FormBuilder,
    FormArray,
    AbstractControl,
    ValidatorFn,
    FormControl
} from "@angular/forms";
import {MatStepper} from '@angular/material/stepper';
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { PmcConfigService } from "@pmc/services/config.service";
import { pmcAnimations } from "@pmc/animations";

import { pmcConfig } from "app/pmc-config";
import { PmcNavigationService } from "@pmc/components/navigation/navigation.service";
import { PmcPerfectScrollbarDirective } from "@pmc/directives/pmc-perfect-scrollbar/pmc-perfect-scrollbar.directive";
import { Constants } from "app/services/constants";
import { AppInsightsService } from "app/appInsights/app-insights.service";
import {EnrollService } from "app/services/enroll.service";
import { EnrollStep4Component} from "./step4/enroll-step4.component";
import { EnrollRequest, Facility } from "app/models/EnrollRequest";

@Component({
    selector: "enroll",
    templateUrl: "./enroll.component.html",
    styleUrls: ["./enroll.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: pmcAnimations
})
export class EnrollComponent implements OnInit {
    enrollForm: FormGroup;
    isEditable = false;
    submitted: boolean = false;
    pageStatus: string = "";
    isSubmitted: boolean;
    errorMessage: string = "";
    tollFreeNumber: string;
    tollFreeNumberTel: string;
    headerText: string;
    enrollRequest: EnrollRequest;
    facilities: Facility [];
    @ViewChild('stepper') private myStepper: MatStepper; 
    @ViewChild(EnrollStep4Component) child: EnrollStep4Component;
    @Output()
    isLoading: boolean;
    constructor(
        private _pmcConfigService: PmcConfigService,
        private _pmcNavigationService: PmcNavigationService,
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private _pmcPerfectScrollbar: PmcPerfectScrollbarDirective,
        private appInsightsService: AppInsightsService,
        private enrollService: EnrollService
    ) {
        // Configure the layout
        this._pmcConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                header: {
                    hidden: true
                },
                body: {
                    contain: false
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
     }

    ngOnInit(): void {
        
        this.tollFreeNumber = pmcConfig.layout.header.tollFreeNumber;
        this.headerText = "Enrollment Form";
        this.tollFreeNumberTel = pmcConfig.layout.header.tollFreeNumber.replace(
            /\D/g,
            ""
        );
        const phoneRegex = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}|(\d{10})$/;
        const ssnRegex = /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
        const dateRegex = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;
        const zipcodeRegex = /^\d{5}$/;
        this.enrollForm = this.fb.group({
            communityGrp: this.fb.group({
                
                'facilityNamelbl': new FormControl(),
                'referal' : new FormControl(),
                'facilityName': new FormControl(null, Validators.required),
                'facilityId' : new FormControl(),
                'facilityCode' : new FormControl(),
                'pharmacyCode' : new FormControl(),
                'pharmacyName' : new FormControl(),
                'zipcode': new FormControl(null, Validators.compose([Validators.required, Validators.pattern(zipcodeRegex)])),    
                'userType': new FormControl('resident', Validators.required),
              }),
            residentGrp: this.fb.group({
             
              'firstname': new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(20)])),
              'lastname': new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(20)])),
              'prescriber' : new FormControl(),
              'admissionDate' : new FormControl(null, Validators.compose([Validators.pattern(dateRegex)])),
              'birthdate' : new FormControl(null, Validators.compose([Validators.required, Validators.pattern(dateRegex)])),
              'phoneNumber' : new FormControl(null, Validators.compose([Validators.pattern(phoneRegex)])),
              'ssn': new FormControl(null, Validators.compose([Validators.required, Validators.pattern(ssnRegex)])),
              'email': new FormControl('', Validators.email),
              'gender': new FormControl(null, Validators.required),
              'station': new FormControl(),
              'roomNumber' : new FormControl(),
              'bedNumber' : new FormControl(),
              'insType' : new FormControl('Medicare/Medicaid', Validators.required),
              'medicareNumber' : new FormControl(null, Validators.required),
              'medicaidNumber' : new FormControl(),
              'insuranceCarrier' : new FormControl(null, Validators.required),
              'memberId' : new FormControl(null, Validators.required),
              'insuranceGroup' : new FormControl(null, Validators.required),
              'bin': new FormControl(null, Validators.required),
              'pcn': new FormControl(null, Validators.required),
              'grp': new FormControl(null, Validators.required),
              'isAllergy': new FormControl('yes'),
              'allergies': new FormControl()

            }),
            'responsiblePartyGrp': this.fb.group({
                
                'name': new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(40)])),
                'relationship': new FormControl(null, Validators.required),
                'email': new FormControl(null, [Validators.required, Validators.email]),
                'phoneNumber': new FormControl(null, Validators.compose([Validators.required, Validators.pattern(phoneRegex)])),        
                'address': new FormControl(null, Validators.required),
                'city': new FormControl(null, Validators.required),
                'state': new FormControl(null, Validators.required),
                'zip': new FormControl(null, Validators.compose([Validators.required, Validators.pattern(zipcodeRegex)])),    
               
            }),
            'reviewGrp':this.fb.group({
                'isSigned': new FormControl(null, Validators.required),
                'includeMail': new FormControl(),
                'signDate': new FormControl()
            })
          });

    // var referal = "111";
    // var para = this.route.snapshot.paramMap.get("code");
    // if ( para != null &&  para.length > 0 )
    // {
    //   referal = para;
    // }
    // this.enrollService.facilityList(referal).subscribe(
    //     data => {
    //       if (data != null ){
    //         this.enrollRequest = data;
    //         console.log('referal: '  + this.enrollRequest.Referal);
    //         let facilities = this.enrollRequest.Facilities;
    //       }
    //     }
    // );

    }
    submitEventHandler($event: any){
        this.lockPage();
    }
    lockPage() {
        this.setStatus("status--restricted");
        this.isSubmitted = true;
       
    }
    indexChangedHandler($event: any)
    {
        this.myStepper.selectedIndex = $event;
    }
    // save(): void {
    //     var self = this;
         
    // }
    // cancel(e: any) {
    //     e.preventDefault();
    //     this.router.navigate(["/login"]);
    // }
    
    setStatus(status: string) {
        this.pageStatus = status;
        this._pmcPerfectScrollbar.scrollToTop();
    }
}
