import { Component, OnInit, Input } from '@angular/core';
import { AllergyList } from "app/models/allergyList";
import { DemographicInfo } from "app/models/demographicInfo";

@Component({
  selector: 'app-print-medications',
  templateUrl: './print-medications.component.html',
  styleUrls: ['./print-medications.component.scss']
})
export class PrintMedicationsComponent implements OnInit {
  @Input() allergyList: AllergyList;
  @Input() demographicInfo: DemographicInfo;
  @Input() primaryDoctor: string;
  @Input() allergies:string;
  constructor() { }

  ngOnInit() {
  }

}
