import {
    Directive,
    Input,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    ElementRef,
    Renderer2,
    HostBinding,
    OnChanges,
    SimpleChanges
} from "@angular/core";
import { Subject } from "rxjs";

@Directive({
    selector: "[pmcLoadProgress]"
})
export class PmcLoadProgressDirective
    implements AfterViewInit, OnDestroy, OnChanges {
    private _parent: any;
    private _unsubscribeAll: Subject<any>;
    private _placeholder: any;
    private _domType: string = "span";
    private _className: string = "_loading";
    private _exists: boolean;

    @HostBinding("class.pmc-is-loading")
    isLoading: boolean;

    @Input()
    pmcIsLoading: string;

    constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {
        this._unsubscribeAll = new Subject();
    }

    ngAfterViewInit(): void {
        // Get the parent
        this._parent = this._renderer.parentNode(
            this._elementRef.nativeElement
        );

        // Return, if there is no parent
        if (!this._parent) {
            return;
        }

        this._placeholder = this._renderer.createElement(this._domType);

        if (this.pmcIsLoading) this.addLoadingAnimationBlock();
        else this.removeLoadingAnimationBlock();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pmcIsLoading.currentValue) this.addLoadingAnimationBlock();
        else this.removeLoadingAnimationBlock();
    }

    ngOnDestroy(): void {
        // Return, if there is no parent
        if (!this._parent) {
            return;
        }

        // Remove the spinner
        this.removeLoadingAnimationBlock();

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private addLoadingAnimationBlock(): void {
        // Add Loading Animation Block
        this._placeholder = this._renderer.createElement(this._domType);
        this._renderer.addClass(this._placeholder, this._className);
        this.isLoading = true;
        this._renderer.appendChild(
            this._elementRef.nativeElement,
            this._placeholder
        );
        this._exists = true;
    }

    private removeLoadingAnimationBlock(): void {
        // Remove Loading Animation Block
        this.isLoading = false;
        if (this._exists) {
            let e = this._parent.querySelector("." + this._className);
            if (e) this._renderer.removeChild(this._parent, e);
            this._exists = false;
        }
    }
}
