import { Injectable } from "@angular/core";
import { PmcConfigService } from "@pmc/services/config.service";

@Injectable({
    providedIn: "root"
})
export class StringService {
    tollFreeNumber: string;
    tollFreeNumberTel: string;

    constructor(private _pmcConfigService: PmcConfigService) {
        this.init();
    }

    init() {
        this._pmcConfigService.config.subscribe(settings => {
            this.tollFreeNumber = settings.layout.header.tollFreeNumber;
            this.tollFreeNumberTel = settings.layout.header.tollFreeNumber.replace(
                /\D/g,
                ""
            );
        });
    }

    replaceContentMatch(
        replaceStr: string,
        replaceKey: string,
        replacementValue: string
    ) {
        let dynamic = "%{2}\\s*" + replaceKey + "\\s*%{2}";
        let re = new RegExp(dynamic, "i");
        return replaceStr.replace(re, replacementValue);
    }
    
    markupGetTollFree() {
        return `<a style='white-space: nowrap;' href='tel:${
            this.tollFreeNumberTel
        }'>${this.tollFreeNumber}</a>`;
    }
    
    markupPhoneNumberLink(formatedPhoneNumber: string) {
        return `<a style='white-space: nowrap;' href='tel:${formatedPhoneNumber.replace(
            /\D/,
            "g"
        )}'>${formatedPhoneNumber}</a>`;
    }
}
