import { Component, OnInit, ViewEncapsulation, Output } from "@angular/core";
import { pmcAnimations } from "@pmc/animations";
import { PmcConfigService } from "@pmc/services/config.service";
import { ForgotpasswordService } from "app/services/forgotpassword.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ResetPswd } from "app/models/user/resetPswd";
import {
    AbstractControl,
    FormGroup,
    FormBuilder,
    Validators
} from "@angular/forms";
import { AppUser, AppUserAuth } from "app/models/user/app-user";
import { AuthService } from "app/services/auth.service";
import { UserService } from "app/services/user.service";
import { UserProfile, ResidentAccount } from "app/models/user/userProfile";
import { pmcConfig } from "app/pmc-config";
import { PmcNavigationService } from "@pmc/components/navigation/navigation.service";
import { Constants } from "app/services/constants";

function passwordMatcher(
    c: AbstractControl
): { [key: string]: boolean } | null {
    const passwordControl = c.get("password");
    const confirmControl = c.get("confirmPassword");

    if (passwordControl.pristine || confirmControl.pristine) {
        return null;
    }

    if (passwordControl.value === confirmControl.value) {
        return null;
    }
    return { match: true };
}
@Component({
    selector: "app-forgot-pswdlink",
    templateUrl: "./forgot-pswdlink.component.html",
    styleUrls: ["./forgot-pswdlink.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: pmcAnimations
})
export class ForgotPswdlinkComponent implements OnInit {
    user: ResetPswd;
    errorMessage: string = "";
    pswdForm: FormGroup;
    userProfile: UserProfile;
    residents: ResidentAccount[];
    isExpired: boolean;
    
    @Output()
    isLoading: boolean;
    constructor(
        private _pmcConfigService: PmcConfigService,
        private _pmcNavigationService: PmcNavigationService,
        private fb: FormBuilder,
        private authService: AuthService,
        private userService: UserService,
        private forgotpasswordService: ForgotpasswordService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this._pmcConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                header: {
                    hidden: true
                },
                body: {
                    contain: false
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit() {
        var self = this;
        const passwordRegex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&amp;*\-_+=[\]{}|\\:',?/`~&quot;();!]|\.(?!@)){8,16}$/;
        this.pswdForm = this.fb.group({
            pswdGroup: this.fb.group(
                {
                    password: [
                        "",
                        [Validators.required, Validators.pattern(passwordRegex)]
                    ],
                    confirmPassword: ["", Validators.required]
                },
                { validator: passwordMatcher }
            )
        });
        
        this.GetResetPswd();
    }
    passwordResetRequestPage() {
        this.router.navigate(["/forgotpswd"]);
    }
    loadNavigation() {
        var user = this.userService.getMyUserProfile();
        var navs = user.Navigations;
        this._pmcNavigationService.onNavigationUnregistered;
        this._pmcNavigationService.unregister("main");
        // Register the navigation to the service
        this._pmcNavigationService.register("main", navs);

        // Set the main navigation as our current navigation
        this._pmcNavigationService.setCurrentNavigation("main");
    }
    GetResetPswd(): void {
        var self = this;
        var isValid = false;
        var link = this.route.snapshot.queryParamMap.get("resetlink");
        if (link == null) {
            isValid = false;
            this.errorMessage = "cannot reset password";
        } else {
            this.forgotpasswordService.GetResetPswd(link).subscribe(
                data => {
                    isValid = true;
                    if (data === null) {
                        this.isExpired = true;
                        this.errorMessage =
                            "Your password reset link has expired, please request a password reset again.";
                        isValid = false;
                    }
                    else
                    {
                        self.user = data;

                    }
                },
                error => (this.errorMessage = <any>error)
            );
        }
      
    }

    submitResetPswd(): void {
        var self = this;
        if (this.pswdForm.valid) {
            if (this.pswdForm.dirty) {
                this.user.VerifyCode = this.route.snapshot.queryParamMap.get(
                    "resetlink"
                );
              
                this.user.Password = this.pswdForm
                    .get("pswdGroup")
                    .get("password").value;
                this.isLoading = true;
                this.forgotpasswordService.ResetPswd(this.user).subscribe(
                    data => {
                        let ret = data;
                      
                        if ( ret.Message == "Error")
                        {
                            this.isExpired = true;
                            this.errorMessage =
                                "Your information is invalid, please request a password reset again.";
                           
                            this.isLoading = false
                            return;
                        }
                        if(ret.Message == "Error: Password exists.")
                        {
                            this.isExpired = true;
                            this.errorMessage = "New Password cannot be the same as old Password.";
                            this.isLoading = false
                            return;
                        }
                        var appuser = new AppUser();
                        appuser.password = self.user.Password;
                        appuser.userName = self.user.UserName;
                        var authObject = new AppUserAuth();
                        this.authService.login(
                            appuser,
                            (authObj: AppUserAuth) => {
                                authObject = authObj;
                                this.userService.getUserProfile().subscribe(
                                    value => {
                                        self.userProfile = value;
                                        if (
                                            self.userProfile != null &&
                                            self.userProfile.ResidentAccounts != null
                                        ) {
                                            self.residents = self.userProfile.ResidentAccounts;
                                            self.userService.setMyUserProfile(self.userProfile);
                                            self.userService.setAllResidents(self.residents);
                                            self.userService.setActiveResident(
                                                self.residents[0].Id
                                            );
                                            self.loadNavigation();
                                            self.router.navigate([
                                                "/dashboard"
                                            ]);
                                        }
                                    },
                                    () => {},
                                    () => {
                                        this.isLoading = false;
                                        window.scrollTo(0, 0);
                                    }
                                );
                            },
                            () => {}
                        );
                    },
                    error => (this.errorMessage = <any>error),
                    () => (this.isLoading = false)
                );
            }
        }
    }
}
