import {
    Component,
    Input,
    ViewChild,
    ComponentFactoryResolver,
    ViewContainerRef
} from "@angular/core";

import { DynamicComponent } from "app/models/ui/dynamicComponent";
import { Tile } from "app/models/ui/tile";
import { TileService } from "./tile.service";

@Component({
    selector: "app-tile",
    styleUrls: ["./tile.component.scss"],
    template: `
        <div
            class="tile-container"
            [ngClass]="
                isTileLoading
                    ? 'title-container tile-is-loading'
                    : 'tile-container'
            "
        >
            <div class="spinner-overlay" *ngIf="isTileLoading && showSpinner">
                <div class="spinner-container">
                    <svg
                        class="spinner"
                        width="45px"
                        height="45px"
                        viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            class="path"
                            fill="none"
                            stroke-width="6"
                            stroke-linecap="round"
                            cx="33"
                            cy="33"
                            r="30"
                        ></circle>
                    </svg>
                </div>
            </div>
            <ng-template #pmcDynamic></ng-template>
        </div>
    `
})
export class TileComponent {
    @Input() tile: Tile;
    @Input() sampleString: string;
    @ViewChild("pmcDynamic", { read: ViewContainerRef, static: true }) myRef;
    public isTileLoading: boolean;
    public showSpinner: boolean;

    constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _tileService: TileService
    ) {}

    ngOnChanges() {
        this.myRef.clear();
        this.loadComponent();
    }

    loadComponent(): any {
        let item = this.tile;
        if (item.showSpinner) this.showSpinner = true;

        try {
            const factory = this._componentFactoryResolver.resolveComponentFactory(
                this.tile.component
            );
            const ref = this.myRef.createComponent(factory);
            (<DynamicComponent>ref.instance).data = item;
        } catch (err) {
            console.error(err);
            item.errorMessage = err.message;
        }
        if (item.dataUrl != null && item.dataUrl != "") {
            this.isTileLoading = true;
            const subscription = this._tileService
                .getTileDataByStringParameters(
                    item.dataUrl,
                    item.dataParameters,
                    item.cacheKey
                )
                .subscribe(
                    data => {
                        item.tileData = data;
                        this.tile.tileData = data;
                        item.successFn(data);
                    },
                    error => {
                        item.failFn(error);
                    },
                    () => {
                        this.isTileLoading = false;
                    }
                );
        }
        return item;
    }
}
