import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import {
    animate,
    state,
    style,
    transition,
    trigger
} from "@angular/animations";
import { PmcTranslationLoaderService } from "@pmc/services/translation-loader.service";
import { Subscription } from "rxjs/Subscription";
import { locale as english } from "./i18n/en";
import { Notification } from "app/models/notification";
import { UserService } from "app/services/user.service";
import { EventEmitService } from "app/services/event-emit.service";
import { Router } from "@angular/router";
import { CdkDetailRowDirective } from "@pmc/directives/cdk-detail-row/cdk-detail-row.directive";
import { MatTableDataSource } from "@angular/material/table";
import { PmcNavigationService } from "@pmc/components/navigation/navigation.service";
import { navigation } from "app/navigation/navigation";
import { UserProfile, ResidentAccount } from "app/models/user/userProfile";

@Component({
    selector: "notifications",
    templateUrl: "./notifications.component.html",
    styleUrls: ["./notifications.component.scss"],
    animations: [
        trigger("detailExpand", [
            state(
                "collapsed",
                style({ height: "0px", minHeight: "0", visibility: "hidden" })
            ),
            state("expanded", style({ height: "*", visibility: "visible" })),
            transition(
                "expanded <=> collapsed",
                animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            )
        ])
    ]
})
export class NotificationsComponent implements OnInit {
    private contextSubscription: Subscription;
    notifications: any;
    currentResidentId: number;
    navigation: any;
    userProfile: UserProfile;
    residents: ResidentAccount[];

    public dataSource: MatTableDataSource<Notification>;
    displayedColumns = ["CreatedDate", "Summary"];
    isExpansionDetailRow = (index, row) => row.hasOwnProperty("detailRow");
    @Input() singleChildRowDetail: boolean;
    /**
     * Constructor
     *
     * @param {PmcTranslationLoaderService} _pmcTranslationLoaderService
     */
    constructor(
        private _pmcTranslationLoaderService: PmcTranslationLoaderService,
        private _pmcNavigationService: PmcNavigationService,
        private _userService: UserService,
        private _eventEmitService: EventEmitService,
        private _router: Router
    ) {
        this._pmcTranslationLoaderService.loadTranslations(english);
        this.contextSubscription = this._eventEmitService.userContextChangeEvent.subscribe(
            (data: any) => {
                if (
                    this._router.url === "/notifications" &&
                    data.Id !== this.currentResidentId
                )
                    this.displayNotification();
            }
        );
    }

    ngOnInit() {
        this.displayNotification();
    }
    displayNotification() {
        let self = this;
        this._userService.getNotification().subscribe(value => {
            self.notifications = value;
            self.dataSource = self.notifications;
            this.loadNavigation();
        });
    }
    
    setBadgeMenuOption(
        menuName: string,
        userNav: any,
        notificationNum: string
        ): any {
        let i = userNav.indexOf(userNav.find(m => m.id == menuName));
        
        userNav[i].badge.title = notificationNum;
        return userNav;
    }
    private openedRow: CdkDetailRowDirective;
    onToggleChange(cdkDetailRow: CdkDetailRowDirective): void {
        if (
            this.singleChildRowDetail &&
            this.openedRow &&
            this.openedRow.expended
        ) {
            this.openedRow.toggle();
        }
        this.openedRow = cdkDetailRow.expended ? cdkDetailRow : undefined;
    }

    
    loadNavigation() {
        var user = this._userService.getMyUserProfile();
        var selectResident = this._userService.getActiveResident();
        selectResident.NotificationCount = "";
        
        var navs = user.Navigations;
        navs = this.setBadgeMenuOption("notifications", navs, "");

        this._pmcNavigationService.unregister("main");

        // Register the navigation to the service
        this._pmcNavigationService.register("main", navs);

        // Set the main navigation as our current navigation
        this._pmcNavigationService.setCurrentNavigation("main");
    }
}
