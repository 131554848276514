import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Config } from 'app/config/config';
import { Facility, EnrollRequest } from '../models/EnrollRequest';
import { Enrollment } from 'app/models/enrollment';
import { EnrollResponse  } from 'app/models/EnrollResponse';


@Injectable({
  providedIn: 'root'
})
export class EnrollService {
  protected apiServer = Config.settings.apiServer;
  constructor(private http: HttpClient, 
    private config: Config ) { 
      this.apiServer = Config.settings.apiServer;
    }
    facilityList(referal: string): Observable<EnrollRequest> {

      return this.http.get<EnrollRequest>(this.apiServer.metadata + '/getfacility/' + referal);
     
    } 

    enroll(entity: Enrollment): Observable<EnrollResponse> {
      
        return this.http.post<EnrollResponse>(this.apiServer.metadata + '/enroll/', entity);
      }

    facilities(): Observable<Facility []> {
        return this.http.get<Facility []>(this.apiServer.metadata + '/getfacilities');
        
      } 
}
