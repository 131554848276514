import {
    Directive,
    Input,
    AfterViewInit,
    OnDestroy,
    ElementRef,
    Renderer2,
    HostBinding
} from "@angular/core";
import { Subject } from "rxjs";

@Directive({
    selector: "[pmcLoadingDots]"
})
export class PmcLoadingDotsDirective implements AfterViewInit, OnDestroy {
    private _parent: any;
    private _unsubscribeAll: Subject<any>;
    private _placeholder: any;
    private _domType: string = "span";
    private _className: string = "loading-dots";
    private _exists: boolean;

    constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {
        this._unsubscribeAll = new Subject();
    }

    ngAfterViewInit(): void {
        // Get the parent
        this._parent = this._renderer.parentNode(
            this._elementRef.nativeElement
        );

        // Return, if there is no parent
        if (!this._parent) {
            return;
        }

        this._placeholder = this._renderer.createElement(this._domType);
        this.addLoadingAnimationBlock();
    }

    ngOnDestroy(): void {
        // Return, if there is no parent
        if (!this._parent) {
            return;
        }

        // Remove the Dots
        this.removeLoadingAnimationBlock();

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    private addLoadingAnimationBlock(): void {
        // Add Loading Dots
        const html = `<span class="loading-animation"><span>.</span><span>.</span><span>.</span></span>`;
        this._placeholder = this._renderer.createElement(this._domType);

        this._renderer.addClass(this._placeholder, this._className);
        this._renderer.setProperty(this._placeholder, "innerHTML", html);

        // this._renderer.appendChild(
        //     this._placeholder,
        //     `<span class="testing">testing</span>`
        // );
        this._renderer.appendChild(
            this._elementRef.nativeElement,
            this._placeholder
        );
        this._exists = true;
    }

    private removeLoadingAnimationBlock(): void {
        // Remove Loading Dots
        if (this._exists) {
            let e = this._renderer.selectRootElement("." + this._className);
            if (e != null) {
                this._renderer.removeChild(this._elementRef.nativeElement, e);
                this._exists = false;
            }
        }
    }
}
