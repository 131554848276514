import { Injectable } from "@angular/core";
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from "@angular/router";
import { ChangePasswordService } from "./change-password.service"

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private changePasswordService: ChangePasswordService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (sessionStorage.getItem("currentUser")) {
            // logged in so return true
            if (this.changePasswordService.getPasswordChange()) {
                this.router.navigate(["/account/changepassword"]);
                return false;
            }
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(["login"]);
        return false;
    }
}
