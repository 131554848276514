import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, ControlContainer, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import {debounceTime, tap, switchMap, finalize , map, startWith} from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Router, ActivatedRoute } from '@angular/router';
import {EnrollService } from "app/services/enroll.service";
import { EnrollRequest, Facility} from "../../../models/EnrollRequest";
import { PmcPerfectScrollbarDirective } from '@pmc/directives/pmc-perfect-scrollbar/pmc-perfect-scrollbar.directive';


@Component({
  selector: 'app-enroll-step1',
  templateUrl: './enroll-step1.component.html',
  styleUrls: ['./enroll-step1.component.scss']
})

export class EnrollStep1Component implements OnInit {
 
  public facility: Facility;
  public facilities : Facility [];
  public enrollRequest: EnrollRequest;
  errorMsg: string;
  isLoading: boolean;
  filteredFacility: any;
  pageStatus: string = "";
  isTouchCommunity: boolean
  
  
  
  @Input() enrollForm: FormGroup;
  facilityName: any;

  constructor(private controlContainer: ControlContainer,
              private router: Router,
              private route: ActivatedRoute,
              private enrollService: EnrollService,
              private _pmcPerfectScrollbar: PmcPerfectScrollbarDirective
    ) { 
      
  }
  ngOnInit() {
   
    
    this.isTouchCommunity = false;
    this.isLoading = false;
    this.getFacility();
    this.clearValidators(this.enrollForm.get('responsiblePartyGrp') as FormGroup);
    this.setResidentDefaultPublic();
  }
  
  private setResidentDefaultPublic(){
    
      this.enrollForm.get('residentGrp').get('insuranceCarrier').clearValidators();
      this.enrollForm.get('residentGrp').get('insuranceCarrier').updateValueAndValidity();
      this.enrollForm.get('residentGrp').get('memberId').clearValidators();
      this.enrollForm.get('residentGrp').get('memberId').updateValueAndValidity();
      this.enrollForm.get('residentGrp').get('insuranceGroup').clearValidators();
      this.enrollForm.get('residentGrp').get('insuranceGroup').updateValueAndValidity();
      
  }
  onRoleChange(){
    const phoneRegex = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}|(\d{10})$/;
    const ssnRegex = /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
    const dateRegex = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;
    const zipcodeRegex = /^\d{5}$/;

    let roleSelected = this.enrollForm.get('communityGrp').get('userType').value;
    if (roleSelected === 'responsibleparty'){
       this.setValidators(this.enrollForm.get('responsiblePartyGrp') as FormGroup); 
       this.enrollForm.get('responsiblePartyGrp').get('phoneNumber').setValidators([Validators.required, Validators.pattern(phoneRegex)]);
       this.enrollForm.get('responsiblePartyGrp').get('phoneNumber').updateValueAndValidity();
       this.enrollForm.get('responsiblePartyGrp').get('email').setValidators([Validators.required, Validators.email]);
       this.enrollForm.get('responsiblePartyGrp').get('email').updateValueAndValidity();  
       this.enrollForm.get('responsiblePartyGrp').get('zip').setValidators([Validators.required, Validators.pattern(zipcodeRegex)]);
       this.enrollForm.get('responsiblePartyGrp').get('zip').updateValueAndValidity();
    }
    else
    {
        this.clearValidators(this.enrollForm.get('responsiblePartyGrp') as FormGroup);  
    }
  }
  setValidators(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key] as FormControl | FormGroup ;
      if(control instanceof FormControl) {
        console.log(`set Validators of ${key}`);
        control.setValidators([Validators.required]);
        control.updateValueAndValidity()
      } else if(control instanceof FormGroup ) {
        console.log(`control '${key}' is nested group or array. calling setValidators recursively`);
        this.setValidators(control);
      } else {
        console.log("ignoring control")
      }
    });
  }
  clearValidators(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key] as FormControl | FormGroup ;
      if(control instanceof FormControl) {
        console.log(`Clearing Validators of ${key}`);
        control.clearValidators();
        control.updateValueAndValidity()
      } else if(control instanceof FormGroup ) {
        console.log(`control '${key}' is nested group or array. calling clearValidators recursively`);
        this.clearValidators(control);
      } else {
        console.log("ignoring control")
      }
    });
  }
  getFacility() : void{
    var self = this;
    this.isLoading = true;
    var para = this.route.snapshot.paramMap.get("code");
    if ( para != null &&  para.length > 0 )
    {

        var code = para.split('-');
        // var pharmacyCode = code[0];
        // var facilityCode = code[1];
        if (code.length == 2){
          this.enrollService.facilityList(para).subscribe(
            data =>{
              self.facilities = data.Facilities;
              if ( self.facilities != null && self.facilities.length == 1 ){
               
                this.facilityName = self.facilities[0].FacilityName;
                this.enrollForm.patchValue({communityGrp: { referal: para}});
                this.enrollForm.patchValue({communityGrp: { facilityNamelbl: this.facilityName}});
                this.enrollForm.patchValue({communityGrp: { facilityName: ''}});
                this.enrollForm.patchValue({communityGrp: { facilityId: self.facilities[0].FacilityId}});
                this.enrollForm.patchValue({communityGrp: { facilityCode: self.facilities[0].FacilityCode}});
                this.enrollForm.patchValue({communityGrp: { pharmacyCode: self.facilities[0].PharmacyCode}});
                this.enrollForm.patchValue({communityGrp: { pharmacyName: self.facilities[0].PharmacyName}});
                this.enrollForm.get('communityGrp').get('facilityName').clearValidators();
                this.enrollForm.get('communityGrp').get('facilityName').updateValueAndValidity();
                this.enrollForm.get('communityGrp').get('zipcode').clearValidators();
                this.enrollForm.get('communityGrp').get('zipcode').updateValueAndValidity();
              }

            })
              
             
         
        }
        else
        {
          const zipcodeRegex = /^\d{5}$/;
          this.enrollForm.get('communityGrp').get('facilityName').setValidators([Validators.required]);
          this.enrollForm.get('communityGrp').get('facilityName').updateValueAndValidity();  
          this.enrollForm.get('communityGrp').get('zipcode').setValidators([Validators.required, Validators.pattern(zipcodeRegex)]);
          this.enrollForm.get('communityGrp').get('zipcode').updateValueAndValidity();

        }
        
      }
    else{
      
        
        const zipcodeRegex = /^\d{5}$/;
        this.enrollForm.get('communityGrp').get('facilityName').setValidators([Validators.required]);
        this.enrollForm.get('communityGrp').get('facilityName').updateValueAndValidity();  
        this.enrollForm.get('communityGrp').get('zipcode').setValidators([Validators.required, Validators.pattern(zipcodeRegex)]);
        this.enrollForm.get('communityGrp').get('zipcode').updateValueAndValidity();

      
      
    }
     
  }
 
   
  
  step1Submitted() {
    if ( !this.enrollForm.get('communityGrp').get('referal').value){
      this.isTouchCommunity = true;
     
    }
    

    //this.enrollForm.patchValue({communityGrp: { facilityName: this.facilityCtrl.value}});
   
  }

  setStatus(status: string) {
    this.pageStatus = status;
    this._pmcPerfectScrollbar.scrollToTop();
  }

}
  
