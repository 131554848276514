import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { PmcSharedModule } from "@pmc/shared.module";
import { NotificationsComponent } from "./notifications.component";
import { AuthGuard } from "app/services/auth.guard";
import { MatCardModule } from "@angular/material/card";
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';


const routes = [
    {
        path: "notifications",
        component: NotificationsComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [NotificationsComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        MatTableModule,
        MatSliderModule,
        MatCardModule,
        PmcSharedModule
    ],
    exports: [NotificationsComponent],
    providers: [AuthGuard]
})
export class NotificationsModule {}
