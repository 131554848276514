import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { PmcSharedModule } from "@pmc/shared.module";
import { AccountSettingsComponent } from "./account-settings.component";
import { AuthGuard } from "app/services/auth.guard";
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { ChangePasswordDialogComponent } from "./change-password-dialog/change-password-dialog.component";
import { ChangePaymentDialogComponent } from "./change-payment-dialog/change-payment-dialog.component";

const routes = [
    {
        path: "settings",
        component: AccountSettingsComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        AccountSettingsComponent,
        ChangePasswordDialogComponent,
        ChangePaymentDialogComponent
        
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        MatButtonModule,
        MatDialogModule,
        MatCardModule,
        MatInputModule,
        PmcSharedModule
    ],
    exports: [AccountSettingsComponent],
    entryComponents: [
        ChangePasswordDialogComponent,
        ChangePaymentDialogComponent,
       

    ]
})
export class AccountSettingsModule {}
