import { NgModule } from "@angular/core";
import { PmcSharedModule } from "@pmc/shared.module";
import { HeaderComponent } from "app/layout/components/header/header.component";

@NgModule({
    imports: [PmcSharedModule],
    declarations: [HeaderComponent],
    exports: [HeaderComponent]
})
export class HeaderModule {}
