import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { PmcPerfectScrollbarDirective } from "@pmc/directives/pmc-perfect-scrollbar/pmc-perfect-scrollbar.directive";
import { FormGroup } from '@angular/forms';
import { Enrollment } from 'app/models/enrollment';
import {EnrollService } from "app/services/enroll.service";
import { EnrollResponse } from 'app/models/EnrollResponse';
import { AppInsightsService } from 'app/appInsights/app-insights.service';

@Component({
  selector: 'app-enroll-step4',
  templateUrl: './enroll-step4.component.html',
  styleUrls: ['./enroll-step4.component.scss']
})
export class EnrollStep4Component implements OnInit {
  TermsAndConditions: any;
  errorMessage: string;
  formSubmitted: boolean;
  includeMail: any;
  pageStatus: string = "";
  isSigned: boolean;
  isLoading: boolean;
  @Input() index: number;
  @Output() indexChanged: EventEmitter<number> =   new EventEmitter();
  @Output() submitEvent = new EventEmitter<string>();

  public enrollResponse : EnrollResponse;

  constructor(
    private _pmcPerfectScrollbar: PmcPerfectScrollbarDirective,
    private enrollService: EnrollService,
    private appInsightsService: AppInsightsService) { }
  
  @Input() enrollForm: FormGroup;
  

  ngOnInit() {
  }

  backMove(index: number)
  {
    console.log(index);
    this.indexChanged.emit(index);
  }

  showChecked(){
    this.isSigned = !this.isSigned;

  }
  submit() {
    this.isLoading = true;
    var self = this;
    console.log('submitted');
    console.log(this.enrollForm.value);
    var enroll = new Enrollment();
    enroll.UserType = this.enrollForm.get('communityGrp').get('userType').value;
    
    if ( this.enrollForm.get('communityGrp').get('referal').value == null)
    {
     
      enroll.FacilityName = this.enrollForm.get('communityGrp').get('facilityName').value;
      enroll.PharmacyName = this.enrollForm.get('communityGrp').get('zipcode').value;
     
      enroll.FacilityId = '';
      enroll.FacilityCode = '';
      //enroll.PharmacyCode = '';
    }
    else
    {
      enroll.FacilityName = this.enrollForm.get('communityGrp').get('facilityNamelbl').value;
      enroll.FacilityCode = this.enrollForm.get('communityGrp').get('facilityCode').value;
      enroll.PharmacyCode = this.enrollForm.get('communityGrp').get('pharmacyCode').value;
      enroll.Referal = this.enrollForm.get('communityGrp').get('referal').value;
      enroll.PharmacyName = this.enrollForm.get('communityGrp').get('pharmacyName').value;
     
    }
    //resident
    enroll.ResidentFirstName = this.enrollForm.get('residentGrp').get('firstname').value;
    enroll.ResidentLastName = this.enrollForm.get('residentGrp').get('lastname').value;
    enroll.ResidentPrescriber = this.enrollForm.get('residentGrp').get('prescriber').value;
    enroll.ResidentAdmissionDate = this.enrollForm.get('residentGrp').get('admissionDate').value;
    enroll.ResidentBirthdate = this.enrollForm.get('residentGrp').get('birthdate').value;
    enroll.ResidentPhoneNumber = this.enrollForm.get('residentGrp').get('phoneNumber').value;
    enroll.ResidentSsn = this.enrollForm.get('residentGrp').get('ssn').value;
    enroll.ResidentEmail = this.enrollForm.get('residentGrp').get('email').value;
    enroll.ResidentGender = this.enrollForm.get('residentGrp').get('gender').value;
    enroll.ResidentStation = this.enrollForm.get('residentGrp').get('station').value;
    enroll.ResidentRoomNumber = this.enrollForm.get('residentGrp').get('roomNumber').value;
    enroll.ResidentBedNumber = this.enrollForm.get('residentGrp').get('bedNumber').value;
    if (this.enrollForm.get('residentGrp').get('isAllergy').value == "yes"){
      enroll.ResidentAllergies = this.enrollForm.get('residentGrp').get('allergies').value;
    }
    else
      enroll.ResidentAllergies = "";
    enroll.ResidentInsType = this.enrollForm.get('residentGrp').get('insType').value;
    if (this.enrollForm.get('residentGrp').get('insType').value == 'Medicare/Medicaid'){
      enroll.ResidentMedicare = this.enrollForm.get('residentGrp').get('medicareNumber').value;
      enroll.Residentmedicaid= this.enrollForm.get('residentGrp').get('medicaidNumber').value;
      enroll.ResidentGrp = this.enrollForm.get('residentGrp').get('grp').value;
      enroll.ResidentPcn = this.enrollForm.get('residentGrp').get('pcn').value;
      enroll.ResidentBin = this.enrollForm.get('residentGrp').get('bin').value;
    }
    else if (this.enrollForm.get('residentGrp').get('insType').value == 'Insurance Plan'){
      enroll.ResidentPcn = this.enrollForm.get('residentGrp').get('pcn').value;
      enroll.ResidentBin = this.enrollForm.get('residentGrp').get('bin').value;
      enroll.ResidentGrp = this.enrollForm.get('residentGrp').get('grp').value;
      enroll.ResidentInsCarrier = this.enrollForm.get('residentGrp').get('insuranceCarrier').value;
      enroll.ResidentInsMemberId = this.enrollForm.get('residentGrp').get('memberId').value;
      enroll.ResidentInsuranceGroup = this.enrollForm.get('residentGrp').get('insuranceGroup').value;
     
     
    }
    // responsible party

    enroll.ResponsiblePartyName = this.enrollForm.get('responsiblePartyGrp').get('name').value;
    enroll.ResponsiblePartyRelationship = this.enrollForm.get('responsiblePartyGrp').get('relationship').value;
    enroll.ResponsiblePartyPhoneNumber = this.enrollForm.get('responsiblePartyGrp').get('phoneNumber').value;
    enroll.ResponsiblePartyEmail = this.enrollForm.get('responsiblePartyGrp').get('email').value;
    enroll.ResponsiblePartyAddress = this.enrollForm.get('responsiblePartyGrp').get('address').value;
    enroll.ResponsiblePartyCity = this.enrollForm.get('responsiblePartyGrp').get('city').value;
    enroll.ResponsiblePartyState = this.enrollForm.get('responsiblePartyGrp').get('state').value;
    enroll.ResponsiblePartyZip = this.enrollForm.get('responsiblePartyGrp').get('zip').value;

    //review
    enroll.IsSigned = this.TermsAndConditions;
    //enroll.IncludeMail = this.includeMail;
    enroll.IncludeMail = '0';
    

    this.enrollService.enroll(enroll).subscribe(
      data => {
        self.enrollResponse = data;
        if ( self.enrollResponse !== null )
        {
          console.log(self.enrollResponse.ErollId);
          var json = {
            "EnrollmentId": self.enrollResponse.ErollId,
            "UserType": enroll.UserType,
            "Facility": enroll.FacilityCode,
            "Pharmacy": enroll.PharmacyCode,
            "Referral": enroll.Referal,
            "ResidentName": enroll.ResidentFirstName + " " + enroll.ResidentLastName
          };
          this.appInsightsService.logEventJson("Enroll Success", json);
          this.formSubmitted = true;
          this.onSubmitChange();
          this.isLoading = false;
        }
       
      }
    )

   

  }
  onSubmitChange(){
    this.submitEvent.emit("status--restricted");

  }


}
