import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    Output,
    EventEmitter,
    AfterViewInit,
    OnChanges
} from "@angular/core";
import { Subscription } from "rxjs/Subscription";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Router, ActivatedRoute } from "@angular/router";
import { PmcConfigService } from "@pmc/services/config.service";
import { PmcSidebarService } from "@pmc/components/sidebar/sidebar.service";

import { UserProfile, ResidentAccount } from "app/models/user/userProfile";
import { UserService } from "app/services/user.service";
import { CachingService } from "app/services/caching.service";
import { Resident } from "app/models/resident";
import { EventEmitService } from "app/services/event-emit.service";
import { PmcNavigationService } from "@pmc/components/navigation/navigation.service";
import { StatusIndicatorService } from "app/services/status-indicator.service";

@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent
    implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    private userProfileSubscription: Subscription;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    containWidth: boolean;
    isDischargedResident: boolean = false;

    // Private
    private _unsubscribeAll: Subject<any>;
    public userData: any;
    public selectedResident: ResidentAccount;
    public displayResidents: ResidentAccount[];
    public currrentResidentId: number = 0;
    public currentContextName: string;
    public userProfile: UserProfile;
    public residents: ResidentAccount[];
    public residentId: Number;
    public residentInfo: Resident;

    /**
     * Constructor
     *
     * @param {PmcConfigService} _pmcConfigService
     * @param {PmcSidebarService} _pmcSidebarService
     * @param {TranslateService} _translateService
     * @param {EventEmitterService} _eventEmitService
     */
    constructor(
        private _pmcConfigService: PmcConfigService,
        private _pmcSidebarService: PmcSidebarService,
        private _translateService: TranslateService,
        private _eventEmitService: EventEmitService,
        private _pmcNavigationService: PmcNavigationService,
        private _statusIndicatorService: StatusIndicatorService,
        private _userService: UserService,
        private _router: Router,
        private _cachingService: CachingService
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: "Online",
                icon: "icon-checkbox-marked-circle",
                color: "#4CAF50"
            },
            {
                title: "Away",
                icon: "icon-clock",
                color: "#FFC107"
            },
            {
                title: "Do not Disturb",
                icon: "icon-minus-circle",
                color: "#F44336"
            },
            {
                title: "Invisible",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#BDBDBD"
            },
            {
                title: "Offline",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#616161"
            }
        ];

        this.languages = [
            {
                id: "en",
                title: "English",
                flag: "us"
            }
        ];

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._pmcConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(settings => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === "top";
                this.rightNavbar = settings.layout.navbar.position === "right";
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
                this.containWidth = settings.layout.toolbar.contain === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang
        });
        this.loadResidents(true);
    }

    ngAfterViewInit() {
        this.userProfileSubscription = this._eventEmitService.addAccountEvent.subscribe(
            () => {
                this.loadResidents(false);
            }
        );
    }

    goToHome() {
        if (
            this._router.url != "/dashboard" &&
            this._router.url != ""
        ) {
            this._router.navigate(["dashboard"]);
        }
    }

    goToLogout() {
        this._router.navigate(["login"]);
    }

    goToAccountSettings() {
        this._router.navigate(["account/settings"]);
    }

    goToAddAccount() {
        this._router.navigate(["account/add-account"]);
    }

    private loadResidents(loadDefault: boolean) {
        this.residents = this._userService.getAllResidents();
        if (this.residents !== null) {
            this.setResident(
                loadDefault
                    ? this.residents[0]
                    : this._userService.getActiveResident()
            );
            this.loadNavigation();
        }
    }

    setResident(resident: ResidentAccount) {
        this._userService.setActiveResident(resident.Id);
        this.selectedResident = resident;
        this.currentContextName = resident.FirstName + " " + resident.LastName;
        let self = this;
        this.displayResidents = this.residents.filter(function (obj) {
            return obj.Id !== self.selectedResident.Id;
        });
    }

    onSelectResident(selectedResident: any) {
        var residents = this._userService.getAllResidents();
        let r1: ResidentAccount = residents.find(
            r => r.ResidentCode === selectedResident.ResidentCode
        );
        if (r1.NotificationCount === "") {
            selectedResident.NotificationCount = "";
        }

        this.setResident(selectedResident);
        this.loadNavigation();
        this._cachingService.clearAllCache();
        this._eventEmitService.userContextChangeEvent.emit(selectedResident);
    }

    loadNavigation() {
        var user = this._userService.getMyUserProfile();
        var navs = user.Navigations;
        this.initUserStatus();
        navs = this.setMutedMenuOption(
            "store",
            navs,
            this.selectedResident.OtcUrl
        );

        navs = this.setBadgeMenuOption(
            "notifications",
            navs,
            this.selectedResident.NotificationCount
        );

        this._pmcNavigationService.unregister("main");

        // Register the navigation to the service
        this._pmcNavigationService.register("main", navs);

        // Set the main navigation as our current navigation
        this._pmcNavigationService.setCurrentNavigation("main");
    }

    initUserStatus() {
        if (this.selectedResident.IsDischarged) {
            this._statusIndicatorService.readOnly();
            this.isDischargedResident = true;
        } else {
            this._statusIndicatorService.active();
            this.isDischargedResident = false;
        }
    }

    setMutedMenuOption(
        menuName: string,
        userNav: any,
        externalUrl?: string
    ): any {
        let i = userNav.indexOf(userNav.find(m => m.id == menuName));
        if (i > 0) {
            if (this.selectedResident.IsDischarged) {
                userNav[i].url = "";
                userNav[i].externalUrl = false;
                userNav[i].function = () => void 0;
            } else {
                userNav[i].url = externalUrl;
                userNav[i].externalUrl = true;
                delete userNav[i]["function"];
            }
            userNav[i].classes = "nav-" + menuName;
        }
        return userNav;
    }

    setBadgeMenuOption(
        menuName: string,
        userNav: any,
        notificationNum: string
    ): any {
        let i = userNav.indexOf(userNav.find(m => m.id == menuName));
        if (i > -1) {
            userNav[i].badge.title = notificationNum;
        }
        return userNav;
    }
    ngOnChanges(): void { }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.userProfileSubscription.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._pmcSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }
}
