import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from "@angular/material/card";
import { MatTabsModule } from '@angular/material/tabs';
import { PmcSharedModule } from "@pmc/shared.module";
import { DashboardComponent } from "./dashboard.component";
import { AuthGuard } from "app/services/auth.guard";
import { EhealthDailogComponent } from "./ehealth-dailog/ehealth-dailog.component";



const routes = [
    {
        path: "",
        component: DashboardComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [DashboardComponent,
    EhealthDailogComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatInputModule,
        MatCardModule,
        MatTabsModule,
        PmcSharedModule
    ],
    exports: [DashboardComponent, RouterModule],
    entryComponents: [
        EhealthDailogComponent
    ],
    providers: [AuthGuard]
})
export class DashboardModule {}
