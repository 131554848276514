import { NgModule } from '@angular/core';
import { MatButtonModule} from '@angular/material/button';
import { MatDialogModule} from '@angular/material/dialog';

import { PmcConfirmDialogComponent } from '@pmc/components/confirm-dialog/confirm-dialog.component';

@NgModule({
    declarations: [
        PmcConfirmDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule
    ],
    entryComponents: [
        PmcConfirmDialogComponent
    ],
})
export class PmcConfirmDialogModule
{
}
