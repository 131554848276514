import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, ValidationErrors, AbstractControl } from '@angular/forms';



// export function validateDOB(control: AbstractControl)
// {
    
//     const birthdayControl = control.get("birthdate");
  
//     let currentDateTime = new Date();
//     currentDateTime.setHours(0,0,0,0);
//     let controlValue = new Date(birthdayControl.value);
//     controlValue.setHours(0,0,0,0);
//     //console.log(currentDateTime+'-'+controlValue)
//     if(currentDateTime<control.value)
//     {
//         return {response: true};
//     }
//     return {response: false};
// }
const checkValidator = (control: AbstractControl) => {
    console.log(control);
    
        const birthdayControl = control.get("birthdate");
  
    let currentDateTime = new Date();
    currentDateTime.setHours(0,0,0,0);
    let controlValue = new Date(birthdayControl.value);
    controlValue.setHours(0,0,0,0);
    //console.log(currentDateTime+'-'+controlValue)
    if(currentDateTime<control.value)
    {
        return {response: true};
    }
    return {response: false};
};
@Component({
  selector: 'app-enroll-step2',
  templateUrl: './enroll-step2.component.html',
  styleUrls: ['./enroll-step2.component.scss']
})
export class EnrollStep2Component implements OnInit {
  
  public showTextArea = true;
  public isSubmitted = false;
  
  constructor() { }
  @Input() enrollForm: FormGroup;
  ngOnInit() {
  }
  textShowChecked(){
    if ( this.enrollForm.get('residentGrp').get('isAllergy').value == 'yes')
      this.showTextArea = true;
    else
      this.showTextArea = false;
    

  }
  
  onChangeInsType(){
   
    const phoneRegex = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}|(\d{10})$/;
    const dateRegex = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;
    this.enrollForm.get('residentGrp').get('pcn').setValidators([Validators.required]);
    this.enrollForm.get('residentGrp').get('pcn').updateValueAndValidity();
    this.enrollForm.get('residentGrp').get('bin').setValidators([Validators.required]);
    this.enrollForm.get('residentGrp').get('bin').updateValueAndValidity();
    this.enrollForm.get('residentGrp').get('grp').setValidators([Validators.required]);
    this.enrollForm.get('residentGrp').get('grp').updateValueAndValidity();
    if (this.enrollForm.get("residentGrp").get("insType").value == "Medicare/Medicaid"){
      //clear value set validiator
     /* insurance plan */
      this.enrollForm.patchValue({
          residentGrp: {
           
            insuranceCarrier: "",
            memberId: "",
            insuranceGroup: ""
           
             }
            });
    
      this.enrollForm.get('residentGrp').get('insuranceCarrier').clearValidators();
      this.enrollForm.get('residentGrp').get('insuranceCarrier').updateValueAndValidity();
      this.enrollForm.get('residentGrp').get('memberId').clearValidators();
      this.enrollForm.get('residentGrp').get('memberId').updateValueAndValidity();
      this.enrollForm.get('residentGrp').get('insuranceGroup').clearValidators();
      this.enrollForm.get('residentGrp').get('insuranceGroup').updateValueAndValidity();
      //set validator
      this.enrollForm.get('residentGrp').get('medicareNumber').setValidators([Validators.required]);
      this.enrollForm.get('residentGrp').get('medicareNumber').updateValueAndValidity(); 
    
     
    }
    else if (this.enrollForm.get("residentGrp").get("insType").value == "Insurance Plan")
    {
      this.enrollForm.patchValue({
        residentGrp: {   
          medicareNumber: "",
          medicaidNumber: ""     
           }
          });
      this.enrollForm.get('residentGrp').get('medicareNumber').clearValidators();
      this.enrollForm.get('residentGrp').get('medicareNumber').updateValueAndValidity();
  
      this.enrollForm.get('residentGrp').get('memberId').setValidators([Validators.required]);
      this.enrollForm.get('residentGrp').get('memberId').updateValueAndValidity();
      this.enrollForm.get('residentGrp').get('insuranceGroup').setValidators([Validators.required]);
      this.enrollForm.get('residentGrp').get('insuranceGroup').updateValueAndValidity();
      this.enrollForm.get('residentGrp').get('insuranceCarrier').setValidators([Validators.required]);
      this.enrollForm.get('residentGrp').get('insuranceCarrier').updateValueAndValidity();
      // control.setValidators([Validators.required]);

    }
    else 
    {
      this.enrollForm.patchValue({
        residentGrp: {
          pcn: "",
          bin: "",
          grp: "",
          medicareNumber: "",
          medicaidNumber: "",
          insuranceCarrier: "",
          memberId: "",
          insuranceGroup: ""
         
           
           }
          });
        this.enrollForm.get('residentGrp').get('medicareNumber').clearValidators();
        this.enrollForm.get('residentGrp').get('medicareNumber').updateValueAndValidity();
        this.enrollForm.get('residentGrp').get('pcn').clearValidators();
        this.enrollForm.get('residentGrp').get('pcn').updateValueAndValidity();
        this.enrollForm.get('residentGrp').get('bin').clearValidators();
        this.enrollForm.get('residentGrp').get('bin').updateValueAndValidity();
        this.enrollForm.get('residentGrp').get('grp').clearValidators();
        this.enrollForm.get('residentGrp').get('grp').updateValueAndValidity();
        this.enrollForm.get('residentGrp').get('insuranceCarrier').clearValidators();
        this.enrollForm.get('residentGrp').get('insuranceCarrier').updateValueAndValidity();
        this.enrollForm.get('residentGrp').get('memberId').clearValidators();
        this.enrollForm.get('residentGrp').get('memberId').updateValueAndValidity();
        this.enrollForm.get('residentGrp').get('insuranceGroup').clearValidators();
        this.enrollForm.get('residentGrp').get('insuranceGroup').updateValueAndValidity();
        }
      
      // control.setValidators([Validators.required]);

  }

  
  step2Submitted() {
   this.isSubmitted = true;
   // check user click next without touch
   this.enrollForm.get('residentGrp').get('gender').markAsTouched();
   this.enrollForm.get('residentGrp').get('gender').updateValueAndValidity();
   this.enrollForm.get('residentGrp').get('firstname').markAsTouched();
   this.enrollForm.get('residentGrp').get('firstname').updateValueAndValidity();
   this.enrollForm.get('residentGrp').get('lastname').markAsTouched();
   this.enrollForm.get('residentGrp').get('lastname').updateValueAndValidity();
   this.enrollForm.get('residentGrp').get('birthdate').markAsTouched();
   this.enrollForm.get('residentGrp').get('birthdate').updateValueAndValidity();
   this.enrollForm.get('residentGrp').get('phoneNumber').markAsTouched();
   this.enrollForm.get('residentGrp').get('phoneNumber').updateValueAndValidity();
   this.enrollForm.get('residentGrp').get('ssn').markAsTouched();
   this.enrollForm.get('residentGrp').get('ssn').updateValueAndValidity();
   this.enrollForm.get('residentGrp').get('email').markAsTouched();
   this.enrollForm.get('residentGrp').get('email').updateValueAndValidity();
   this.enrollForm.get('residentGrp').get('lastname').markAsTouched();
   this.enrollForm.get('residentGrp').get('lastname').updateValueAndValidity();
   this.enrollForm.get('residentGrp').get('lastname').markAsTouched();
   this.enrollForm.get('residentGrp').get('lastname').updateValueAndValidity();
   this.enrollForm.get('residentGrp').get('lastname').markAsTouched();
   this.enrollForm.get('residentGrp').get('lastname').updateValueAndValidity();
   this.enrollForm.get('residentGrp').get('pcn').markAsTouched();
   this.enrollForm.get('residentGrp').get('pcn').updateValueAndValidity();
   this.enrollForm.get('residentGrp').get('bin').markAsTouched();
   this.enrollForm.get('residentGrp').get('bin').updateValueAndValidity();
   this.enrollForm.get('residentGrp').get('grp').markAsTouched();
   this.enrollForm.get('residentGrp').get('grp').updateValueAndValidity();

   if (this.enrollForm.get("residentGrp").get("insType").value == "Medicare/Medicaid"){
    //clear value set validiator
    this.enrollForm.get('residentGrp').get('medicareNumber').markAsTouched();
    this.enrollForm.get('residentGrp').get('medicareNumber').updateValueAndValidity(); 
  }
  else if (this.enrollForm.get("residentGrp").get("insType").value == "Insurance Plan")
  {
    
    
    this.enrollForm.get('residentGrp').get('insuranceCarrier').markAsTouched();
    this.enrollForm.get('residentGrp').get('insuranceCarrier').updateValueAndValidity();
    this.enrollForm.get('residentGrp').get('memberId').markAsTouched();
    this.enrollForm.get('residentGrp').get('memberId').updateValueAndValidity();
    this.enrollForm.get('residentGrp').get('insuranceGroup').markAsTouched();
    this.enrollForm.get('residentGrp').get('insuranceGroup').updateValueAndValidity();
    

    
    // control.setValidators([Validators.required]);

  }
   
  }
  
}