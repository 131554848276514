import { NgModule } from "@angular/core";

import { PmcIfOnDomDirective } from "./pmc-if-on-dom/pmc-if-on-dom.directive";
import { PmcInnerScrollDirective } from "./pmc-inner-scroll/pmc-inner-scroll.directive";
import { PmcPerfectScrollbarDirective } from "./pmc-perfect-scrollbar/pmc-perfect-scrollbar.directive";
import { PmcLoadProgressDirective } from "./pmc-load-progress/pmc-load-progress.directive";
import {
    PmcMatSidenavHelperDirective,
    PmcMatSidenavTogglerDirective
} from "./pmc-mat-sidenav/pmc-mat-sidenav.directive";
import { CdkDetailRowDirective } from "./cdk-detail-row/cdk-detail-row.directive";
import { PmcDynamicDirective } from "./pmc-dynamic/pmc-dynamic.directive";
import { PmcLoadingDotsDirective } from "./pmc-loading-dots/pmc-loading-dots.directive";

@NgModule({
    declarations: [
        PmcIfOnDomDirective,
        PmcInnerScrollDirective,
        PmcMatSidenavHelperDirective,
        PmcMatSidenavTogglerDirective,
        PmcPerfectScrollbarDirective,
        PmcLoadProgressDirective,
        CdkDetailRowDirective,
        PmcDynamicDirective,
        PmcLoadingDotsDirective
    ],
    imports: [],
    exports: [
        PmcIfOnDomDirective,
        PmcInnerScrollDirective,
        PmcMatSidenavHelperDirective,
        PmcMatSidenavTogglerDirective,
        PmcPerfectScrollbarDirective,
        PmcLoadProgressDirective,
        CdkDetailRowDirective,
        PmcDynamicDirective,
        PmcLoadingDotsDirective
    ]
})
export class PmcDirectivesModule {}
