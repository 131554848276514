import { NgModule } from '@angular/core';

import { PmcWidgetComponent } from './widget.component';
import { PmcWidgetToggleDirective } from './widget-toggle.directive';

@NgModule({
    declarations: [
        PmcWidgetComponent,
        PmcWidgetToggleDirective
    ],
    exports     : [
        PmcWidgetComponent,
        PmcWidgetToggleDirective
    ],
})
export class PmcWidgetModule
{
}
