import { Component, AfterViewInit, OnInit, OnDestroy } from "@angular/core";
import { UserService } from "app/services/user.service";
import { Subscription } from "rxjs/Subscription";
import { EventEmitService } from "app/services/event-emit.service";

@Component({
    selector: "user-role-banner",
    templateUrl: "./user-role-banner.component.html",
    styleUrls: ["./user-role-banner.component.scss"]
})
export class UserRoleBannerComponent
    implements OnInit, AfterViewInit, OnDestroy {
    private contextSubscription: Subscription;
    currentResidentId: number;
    userName: string;
    roleName: string;

    constructor(
        private _userService: UserService,
        private _eventEmitService: EventEmitService
    ) {
        this.userName = "";
        this.roleName = "";
        this.contextSubscription = this._eventEmitService.userContextChangeEvent.subscribe(
            (data: any) => {
                this.currentResidentId = data.Id;
                this.loadProfile();
            }
        );
    }

    ngAfterViewInit() {}

    ngOnInit() {
        this.loadProfile();
    }

    ngOnDestroy() {
        this.contextSubscription.unsubscribe();
    }

    private loadProfile() {
        let profile = this._userService.getMyUserProfile();
        if (profile !== null) {
            this.userName = `${profile.FirstName} ${profile.LastName}`;
            if (profile.ResidentAccounts.length === 1) {
                this.setRoleName(profile.ResidentAccounts[0].Role);
            } else {
                let context = this._userService.getActiveResident();
                this.setRoleName(context.Role);
            }
        }
    }

    private setRoleName(roleName: string) {
        switch (roleName.toLocaleLowerCase()) {
            case "responsibleparty": {
                this.roleName = "Responsible Party";
                break;
            }
            case "authorizedrepresentative": {
                this.roleName = "Authorized Representative";
                break;
            }
            case "resident": {
                this.roleName = "Resident";
                break;
            }
            default: {
                break;
            }
        }
    }
}
