import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { FormGroup, ControlContainer, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';


@Component({
  selector: 'app-enroll-step3',
  templateUrl: './enroll-step3.component.html',
  styleUrls: ['./enroll-step3.component.scss']
})
export class EnrollStep3Component implements OnInit {
  
  public isRequire: boolean;
  constructor() { }
  @Input() enrollForm: FormGroup;
  formSubmitted: boolean = false;
 
 
  ngOnInit() {
    let roleSelected = this.enrollForm.get('communityGrp').get('userType').value;
    if (roleSelected === 'responsibleparty'){
      this.isRequire = true;
    }
    else
    {
      this.isRequire =false;
    }
    
  }
  selectStateFilter($event: any){

    this.enrollForm.get('responsiblePartyGrp').get('state').patchValue($event.value);

  }
  step3Submitted() {
    const phoneRegex = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}|(\d{10})$/;
    const zipcodeRegex = /^\d{5}$/;
    this.enrollForm.get('responsiblePartyGrp').get('email').markAsTouched();
    this.enrollForm.get('responsiblePartyGrp').get('email').updateValueAndValidity();
    this.enrollForm.get('responsiblePartyGrp').get('phoneNumber').markAsTouched();
    this.enrollForm.get('responsiblePartyGrp').get('phoneNumber').updateValueAndValidity();
    
    let roleSelected = this.enrollForm.get('communityGrp').get('userType').value;
    if ( roleSelected === "resident")
    {
      if ((this.enrollForm.get('responsiblePartyGrp').get('name').value === "" || this.enrollForm.get('responsiblePartyGrp').get('name').value === null) &&
          (this.enrollForm.get('responsiblePartyGrp').get('relationship').value === "" || this.enrollForm.get('responsiblePartyGrp').get('relationship').value === null)&& 
          (this.enrollForm.get('responsiblePartyGrp').get('email').value === "" || this.enrollForm.get('responsiblePartyGrp').get('email').value === null )&&
          (this.enrollForm.get('responsiblePartyGrp').get('phoneNumber').value === "" || this.enrollForm.get('responsiblePartyGrp').get('phoneNumber').value === null) &&
          (this.enrollForm.get('responsiblePartyGrp').get('address').value === ""  || this.enrollForm.get('responsiblePartyGrp').get('address').value === null) &&
          (this.enrollForm.get('responsiblePartyGrp').get('city').value === ""  ||  this.enrollForm.get('responsiblePartyGrp').get('city').value === null) &&
          (this.enrollForm.get('responsiblePartyGrp').get('state').value === ""|| this.enrollForm.get('responsiblePartyGrp').get('state').value === null) &&
          (this.enrollForm.get('responsiblePartyGrp').get('zip').value === "" || this.enrollForm.get('responsiblePartyGrp').get('zip').value === null)
      ) {
          this.clearValidators(this.enrollForm.get('responsiblePartyGrp') as FormGroup);  
          this.isRequire = false;
      }
      else{
        
        this.setValidators(this.enrollForm.get('responsiblePartyGrp') as FormGroup); 
        this.enrollForm.get('responsiblePartyGrp').get('phoneNumber').setValidators([Validators.required, Validators.pattern(phoneRegex)]);
        this.enrollForm.get('responsiblePartyGrp').get('phoneNumber').updateValueAndValidity();
        this.enrollForm.get('responsiblePartyGrp').get('email').setValidators([Validators.required, Validators.email]);
        this.enrollForm.get('responsiblePartyGrp').get('email').updateValueAndValidity();  
        this.enrollForm.get('responsiblePartyGrp').get('zip').setValidators([Validators.required, Validators.pattern(zipcodeRegex)]);
        this.enrollForm.get('responsiblePartyGrp').get('zip').updateValueAndValidity();  

        this.setTouched(this.enrollForm.get('responsiblePartyGrp') as FormGroup);
        this.isRequire = true;

      }
    }
    else
    {
      this.setTouched(this.enrollForm.get('responsiblePartyGrp') as FormGroup);
    }
  }
  enableValidation()
  {
    let roleSelected = this.enrollForm.get('communityGrp').get('userType').value;
    if ( roleSelected === "resident")
    {
      this.setValidators(this.enrollForm.get('responsiblePartyGrp') as FormGroup); 
    }

  }
  setValidators(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key] as FormControl | FormGroup ;
      if(control instanceof FormControl) {
        console.log(`set Validators of ${key}`);
        
        control.setValidators([Validators.required]);
        control.updateValueAndValidity()
      } else if(control instanceof FormGroup ) {
        console.log(`control '${key}' is nested group or array. calling setValidators recursively`);
        this.setValidators(control);
      } else {
        console.log("ignoring control")
      }
    });
  }

  setTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key] as FormControl | FormGroup ;
      if(control instanceof FormControl) {
        
        control.markAsTouched();
        control.updateValueAndValidity()
      } else if(control instanceof FormGroup ) {
        console.log(`control '${key}' is nested group or array. calling setValidators recursively`);
        this.setValidators(control);
      } else {
        console.log("ignoring control")
      }
    });
  }
  clearValidators(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key] as FormControl | FormGroup ;
      if(control instanceof FormControl) {
        console.log(`Clearing Validators of ${key}`);
        control.clearValidators();
        control.updateValueAndValidity()
      } else if(control instanceof FormGroup ) {
        console.log(`control '${key}' is nested group or array. calling clearValidators recursively`);
        this.clearValidators(control);
      } else {
        console.log("ignoring control")
      }
    });
  }
  
  
}
