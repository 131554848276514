import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Config } from "app/config/config";
import { ResetPswd } from "app/models/user/resetPswd";
import { tap } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class ForgotpasswordService {
    protected apiServer = Config.settings.apiServer;
    constructor(private http: HttpClient, private config: Config) {
        this.apiServer = Config.settings.apiServer;
    }
    sendVerifyCode(entity: ResetPswd): Observable<ResetPswd> {
        return this.http.post<ResetPswd>(
            this.apiServer.metadata + "/verifyemail",
            entity
        );
    }
    validateUser(username: string, email: string): Observable<Boolean> {
        return this.http.get<Boolean>(
            this.apiServer.metadata + "/isuservalid/" + username + "/" + email
        );
    }
    GetResetPswd(link: string): Observable<ResetPswd>{
        return this.http 
                 .get<ResetPswd>(this.apiServer.metadata + "/getResetPswd/" + link)
                .pipe(tap(data => console.log("All: " + JSON.stringify(data))));

    }
    
    ResetPswd(entity: ResetPswd): Observable<ResetPswd> {
        return this.http.post<ResetPswd>(
            this.apiServer.metadata + "/resetpswd",
            entity
        );
    }
}
