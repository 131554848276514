import { Component, OnInit, Input } from "@angular/core";
import { InfoIcon } from "app/models/ui/infoIcon";

@Component({
    selector: "app-info-icon",
    templateUrl: "./info-icon.component.html",
    styleUrls: ["./info-icon.component.scss"]
})
export class InfoIconComponent {
    @Input() infoIcon: InfoIcon;

    constructor() {}
}
