import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { PmcSharedModule } from "@pmc/shared.module";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

import { MedicationListComponent } from "./medication-list.component";
import { AuthGuard } from "app/services/auth.guard";
import { PrintMedicationsModule } from "../print-medications/print-medications.module";
import { PrintHeaderModule } from "../print-header/print-header.module";
import { PrintFooterModule } from "../print-footer/print-footer.module";

const routes = [
    {
        path: "list",
        component: MedicationListComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatCardModule,
        MatTabsModule,
        MatCheckboxModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatTableModule,
        MatSortModule,
        PmcSharedModule,
        PrintMedicationsModule,
        PrintHeaderModule,
        PrintFooterModule
    ],
    declarations: [MedicationListComponent],
    exports: [MedicationListComponent]
})
export class MedicationListModule {}
