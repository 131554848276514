import { Component, Input } from '@angular/core';
import { ResidentFinPlan } from 'app/models/residentFinPlan';
import { UpdatePaymentService } from '../../../../services/update-payment.service';
import { UserService } from '../../../../services/user.service';
import { ResidentAccount } from 'app/models/user/userProfile';
import { ResidentRequest } from 'app/models/ResidentRequest';
import { FinPlan } from 'app/models/finPlan';
import { BofaSAMLResponse } from 'app/models/bofaSAMLResponse';

import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-change-payment-dialog',
  templateUrl: './change-payment-dialog.component.html',
  styleUrls: ['./change-payment-dialog.component.scss']
})
export class ChangePaymentDialogComponent {

  @Input()
  residentFinPlans: ResidentFinPlan;
  errorMessage: string;

  constructor(public dialogRef: MatDialogRef<ChangePaymentDialogComponent>,
    private updatePaymentService: UpdatePaymentService, private userService: UserService) {
    this.errorMessage = '';
  }

  finPlanClicked(plan: FinPlan) {
    let residentRequest = this.getResidentRequest();
    residentRequest.accountNumber = plan.AccountNumber;
    this.getSAMLResponse(plan.AccountNumber, this);
  }

  getResidentRequest(): ResidentRequest {
    var resident: ResidentAccount = this.userService.getActiveResident();
    var residentRequest: ResidentRequest = new ResidentRequest();;
    residentRequest.FacilityCode = resident.FacilityCode;
    residentRequest.PharmacyCode = resident.PharmacyCode;
    residentRequest.ResidentCode = resident.ResidentCode;
    return residentRequest;
  }

  getSAMLResponse(accountNumber: string, context: any) {
    let me = context;
    this.dialogRef.close();
    this.updatePaymentService.getSAMLResponse(accountNumber);
  }
}
