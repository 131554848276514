import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import {
    AbstractControl,
    FormGroup,
    FormBuilder,
    Validators
} from "@angular/forms";
import { UserService } from "app/services/user.service";
import { AlertService } from "app/services/alert.service";
import { Constants } from "app/services/constants";
import { AppInsightsService } from "app/appInsights/app-insights.service";


function passwordMatcher(
    c: AbstractControl
): { [key: string]: boolean } | null {
    const passwordControl = c.get("password");
    const confirmControl = c.get("confirmPassword");

    if (passwordControl.pristine || confirmControl.pristine) {
        return null;
    }

    if (passwordControl.value === confirmControl.value) {
        return null;
    }
    return { match: true };
}
@Component({
    selector: "app-change-password-dialog",
    templateUrl: "./change-password-dialog.component.html",
    styleUrls: ["./change-password-dialog.component.scss"]
})
export class ChangePasswordDialogComponent implements OnInit {
    pswdForm: FormGroup;
    errorMessage: string = "";
    constructor(
        public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
        private fb: FormBuilder,
        private userService: UserService,
        private alertService: AlertService,
        private appInsightsService: AppInsightsService
    ) { }

    ngOnInit() {
        const passwordRegex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&amp;*\-_+=[\]{}|\\:',?/`~&quot;();!]|\.(?!@)){8,16}$/;
        this.pswdForm = this.fb.group({
            pswdGroup: this.fb.group(
                {
                    password: [
                        "",
                        [Validators.required, Validators.pattern(passwordRegex)]
                    ],
                    confirmPassword: ["", Validators.required]
                },
                { validator: passwordMatcher }
            )
        });
    }

    submitChangePassword(): void {
        if (this.pswdForm.valid) {
            if (this.pswdForm.dirty) {
                let newPassword = this.pswdForm.get("pswdGroup").get("password").value;
                this.userService
                    .changeMyPassword(newPassword)
                    .subscribe(
                        data => {
                            this.appInsightsService.logEvent("Password Change Success.");
                            this.alertService.info(
                                "Password change successful"
                            );
                            this.dialogRef.close();
                        },
                        error => {
                            this.appInsightsService.logEvent("Password Change Failure.");
                            this.alertService.info(
                                "New Password cannot be the same as old Password."
                            );
                        }
                    );
            }
        }
    }
}
