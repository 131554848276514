import { Component, Input } from "@angular/core";
import { DynamicComponent } from "app/models/ui/dynamicComponent";
import { ChangePaymentDialogComponent } from "app/main/account/settings/change-payment-dialog/change-payment-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ResidentAccount } from "app/models/user/userProfile";
import { UserService } from "app/services/user.service";
import { ResidentRequest } from "app/models/ResidentRequest";
import { UpdatePaymentService } from "app/services/update-payment.service";
import { ResidentFinPlan } from "app/models/residentFinPlan";
import { AlertService } from "app/services/alert.service";
import { pmcConfig } from "app/pmc-config";
import { AppInsightsService } from "app/appInsights/app-insights.service";

@Component({
    template: `
        <div class="card pb-0 rounded">
            <div class="tile default tile-order-summary">
                <div class="tile-header">
                    <span class="fa fa-balance-scale" aria-hidden="true"></span>
                    {{ data.title }}
                </div>
                <div class="tile-body">
                    <div
                        *ngIf="
                            data.tileData?.PaymentDetails?.length > 0;
                            else noOrderHistory
                        "
                    >
                        <p>
                            Outstanding Balance Amount:
                            <strong>{{
                                data.tileData?.OutstandingBalanceAmount
                                    | currency
                            }}</strong>
                        </p>
                        <p>
                            Last Statement Balance:
                            <strong>
                                {{
                                    data.tileData?.LastStatementBalance
                                        | currency
                                }}
                            </strong>
                        </p>
                        <p>
                            Last Payment Amount:
                            <strong>{{
                                data.tileData?.LastPaymentAmount | currency
                            }}</strong>
                        </p>
                        <p>
                            Last Service Date:
                            <strong>{{
                                data.tileData?.LastServiceDate
                                    | date: "MM/dd/yyyy"
                            }}</strong>
                        </p>
                    </div>
                </div>
                <div class="update-payment-container"><button mat-button class="btn-default mat-button" (click)="openChangePaymentDialog()">Pay My Bill</button></div>
            </div>
            
        </div>
        <ng-template #noOrderHistory>
            <p>Your account summary cannot be found at this time.</p> 
        </ng-template>  
    `
})
export class TileOrderSummaryComponent implements DynamicComponent {
     //update payment stuff ** to be removed and made as a separate component
     errorMessage: string = "";
     residentFinPlans: ResidentFinPlan;
 
    @Input() data: any;
    constructor(
        private userService: UserService,
        private alertService: AlertService,
        private updatePaymentService: UpdatePaymentService,
         private dialog: MatDialog,
         private appInsightsService: AppInsightsService) {}
    openChangePaymentDialog() {
        var resident: ResidentAccount = this.userService.getActiveResident();
        var residentRequest: ResidentRequest = new ResidentRequest();
        residentRequest.FacilityCode = resident.FacilityCode;
        residentRequest.PharmacyCode = resident.PharmacyCode;
        residentRequest.ResidentCode = resident.ResidentCode;
        let self = this;
        this.updatePaymentService
            .getResidentFinPlans(residentRequest)
            .subscribe(
                (finPlans: ResidentFinPlan) => {
                    if (
                        finPlans !== null &&
                        finPlans.FinancialPlans !== null &&
                        finPlans.FinancialPlans.length > 0
                    ) {
                        self.errorMessage = "";
                        self.residentFinPlans = finPlans;
                        if (self.residentFinPlans.FinancialPlans.length == 1) {
                            self.getSAMLResponse(
                                self.residentFinPlans.FinancialPlans[0]
                                    .AccountNumber,
                                self
                            );
                        } else {
                            let paymentDailog = this.dialog.open(
                                ChangePaymentDialogComponent,
                                {
                                    width: "380px",
                                    autoFocus: false
                                }
                            );
                            this.appInsightsService.logEvent("Bill Pay Redirect Success.");
                            paymentDailog.componentInstance.residentFinPlans = finPlans;
                        }
                    } else {
                        this.appInsightsService.logEvent("Bill Pay Redirect Failure.");
                        this.alertService.info(
                            "Account Information is missing, please call " + pmcConfig.layout.header.tollFreeNumber
                        );
                    }
                },
                () => {
                    this.appInsightsService.logEvent("Bill Pay Redirect Failure");
                    this.alertService.info(
                        "Account Information is missing, please call " + pmcConfig.layout.header.tollFreeNumber
                    );
                }
            );
    }
    getSAMLResponse(accountNumber: string, context: any) {
        this.updatePaymentService.getSAMLResponse(accountNumber);
    }
}
