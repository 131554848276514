import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PmcSearchBarModule, PmcShortcutsModule } from "@pmc/components";
import { PmcSharedModule } from "@pmc/shared.module";
import { NavbarModule } from "app/layout/components/navbar/navbar.module"; //added for valuemed match
import { ToolbarComponent } from "app/layout/components/toolbar/toolbar.component";

@NgModule({
    declarations: [ToolbarComponent],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        PmcSharedModule,
        PmcSearchBarModule,
        PmcShortcutsModule,
        NavbarModule
    ],
    exports: [ToolbarComponent]
})
export class ToolbarModule {}
