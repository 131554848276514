import { NgModule } from "@angular/core";
import { UserRoleBannerComponent } from "./user-role-banner.component";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [CommonModule],
    declarations: [UserRoleBannerComponent],
    exports: [UserRoleBannerComponent]
})
export class UserRoleBannerModule {}
