import {
    Component,
    OnInit,
    ViewEncapsulation,
    Output,
    ViewChild
} from "@angular/core";
import { pmcAnimations } from "@pmc/animations";
import { PmcConfigService } from "@pmc/services/config.service";
import { ForgotpasswordService } from "app/services/forgotpassword.service";
import { ResetPswd } from "app/models/user/resetPswd";
import { Router, ActivatedRoute } from "@angular/router";
import { pmcConfig } from "app/pmc-config";
import { NgForm } from "@angular/forms";
import { PmcPerfectScrollbarDirective } from "@pmc/directives/pmc-perfect-scrollbar/pmc-perfect-scrollbar.directive";

@Component({
    selector: "app-forgot-password",
    templateUrl: "./forgot-password.component.html",
    styleUrls: ["./forgot-password.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: pmcAnimations
})
export class ForgotPasswordComponent implements OnInit {
    @ViewChild("f", { static: true }) addAccountForm: NgForm;
    user: ResetPswd = new ResetPswd();
    errorMessage: string = "";
    pageStatus: string = "";
    @Output()
    isLoading: boolean;

    constructor(
        private _pmcConfigService: PmcConfigService,
        private forgotpasswordService: ForgotpasswordService,
        private router: Router,
        private route: ActivatedRoute,
        private _pmcPerfectScrollbar: PmcPerfectScrollbarDirective
    ) {
        this._pmcConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                header: {
                    hidden: true
                },
                body: {
                    contain: false
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit() {}
    
    onSubmit() {
        var self = this;
        this.isLoading = true;
        this.forgotpasswordService.sendVerifyCode(this.user).subscribe(
            data => {
                self.user = data;
                //check message if
                if (self.user.Message == "OK") {
                    this.errorMessage =
                        "Please check your email reset password link just sent to you";
                    this.isLoading = false;
                    this.setStatus("status--success");
                    this.addAccountForm.resetForm();
                } else {
                    this.errorMessage = self.user.Message;
                    this.addAccountForm.resetForm();
                    this.addAccountForm.controls["userName"].setValue(
                        this.user.UserName
                    );
                    this.addAccountForm.controls["email"].setValue(
                        this.user.Email
                    );
                }
            },
            () => {},
            () => {
                this.isLoading = false;
                window.scrollTo(0, 0);
            }
        );
    }
    
    cancel(e:any) {
        e.preventDefault()
        this.router.navigate(["/login"]);
    }

    setStatus(status: string) {
        this.pageStatus = status;
        this._pmcPerfectScrollbar.scrollToTop();
    }
}
