import { Type } from "@angular/core";

export class Tile {
    public component: Type<any>;
    public tileData: any;
    public tileClassName: string;
    public subtitle: string;
    public iconClassName: string;
    public rightTitleText: string;
    public rightSubTitleText: string;
    public linkUrlLocation: string;
    public tileItems: TileItem[];
    public tileItemMarkup: string;
    public dataUrl: string;
    public dataParameters: string = "";
    public successFn: Function = () => void 0;
    public failFn: Function = () => void 0;
    public errorMessage: string;
    public combined: any;
    public showSpinner: boolean;
    public cacheKey: string;
    constructor(public title: string, public overrides?: Partial<Tile>) {
        let self = this;
        this.failFn;
        const merged = Object.assign(self, overrides);
        this.tileClassName = overrides.tileClassName || "pmc-tile";
        delete merged.overrides;
    }
}

export class TileItem {
    public id: number;
    public itemTitle: string;
    public tileItemDataExtra: any;
    public tileItemTemplateLocation: string;
    public subtitle: string;
}

export interface TileTemplateComponent {
    data: any;
}
