import { AzureLoginUser } from './azureLoginUser';
import { Resident } from '../resident';
import { PmcNavigation } from '@pmc/types';

export class UserProfile{
    ObjectId:string;
    FirstName:string;
    LastName:string;
    PreferredName:string;
    Email:string;
    EmailStatus: string;
    UserType: string;
    UserEmail: string;
    RoleId: number;
    ResidentAccounts: ResidentAccount[];
    CurrentResident: Resident;
    NotificationCount: string;
    Navigations: PmcNavigation[];
    ForcePasswordChange: boolean;
    UniqueId: string;
    EHealthUrl: string;
}
export class LogUser{
    FacilityName:string;
    PharmacyName: string;
    UserType: string;
}

export class ResidentAccount {
    Id: number;
    ResidentCode:string;
    FacilityCode:string;
    PharmacyCode: string;
    FacilityName:string;
    PharmacyName: string;
    FirstName: string;
    LastName: string;
    Role: string;
    SSN: string;
    IsDischarged: boolean;
    DischargedDate: string;
    OtcUrl: string;
    Permission: ActivityPermissions;
    NotificationCount: string;
    
}

export class ActivityPermissions{
    AddAccount: boolean;
    InviteFamilyMember: boolean;
    Notification: boolean;
    Reorder: boolean;
    UpdatePaymentInformation: boolean;
}
