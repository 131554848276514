import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { DynamicComponent } from "app/models/ui/dynamicComponent";
import { UserProfile } from "app/models/user/userProfile";
import { InfoIcon, InfoIconType } from "app/models/ui/infoIcon";
import { UserService } from "app/services/user.service";
import { Router } from "@angular/router";
import { AlertService } from "app/services/alert.service";
import { AppInsightsService } from "app/appInsights/app-insights.service";
@Component({
    template: `
        <div class="card med-list-card pb-0 rounded">
            <div class="tile default tile-resident-info pb-0">
               
                <div class="tile-body">
                    <div class="card-body" style="padding-top: 0.015em; padding-bottom: 0.015em;">
                    <div class="pmc-flex-wrap">
                            <div class="pmc-flex-item">
                               <div class="info-icons" *ngFor="let ra of infoIcons" style="height:30px;">
                                    <app-info-icon [infoIcon]="ra"></app-info-icon>
                                </div>
                        
                                <div *ngIf="userProfile.EmailStatus == 'initial'" style="float: right; padding-bottom: 1.5rem;">
                                
                                    <button mat-button class="btn-default mat-button" (click)="confirm()">
                                        Confirm
                                    </button>
                                    &nbsp;&nbsp;&nbsp;
                                    <button mat-button class="btn-default mat-button"  (click)="change()">
                                        Update Email
                                    </button>
                                </div>
                                <div *ngIf="userProfile.EmailStatus == 'empty'" style="float: right; padding-bottom: 1.5rem;">
                                    <button mat-button class="btn-default mat-button"  (click)="change()">
                                        Add Email Address
                                    </button>
                                </div>
                            
                                <div *ngIf="userProfile.EmailStatus == 'diff'" style="float: right; padding-bottom: 1.5rem;">
                                    <button mat-button class="btn-default mat-button" (click)="confirm()">
                                        Accept
                                    </button>
                                    &nbsp;&nbsp; &nbsp;
                                    <button mat-button class="btn-default mat-button"  (click)="reject()">
                                        Reject
                                    </button>
                                </div>
                        </div>
                        </div>    
                    </div>
                    
                </div>
               
            </div>
        </div>
    `
    
})
export class TileEMailNotifyComponent
    implements DynamicComponent, OnInit {
    @Input() data: any;
    @Output() confirmEvent = new EventEmitter<string>();

    infoIcons: InfoIcon[];
    userProfile: UserProfile;
    subject: string;
    body: string;

    constructor( 
         private _userService: UserService,
         private router: Router,
         private alertService: AlertService,
         private appInsightsService: AppInsightsService
    ) {
        this.infoIcons = [];
    }

    initTile() {
        
        this.loadComponent();
    }

    ngOnInit() {
        this.initTile();
    }

    loadComponent() {    
        this.userProfile = this._userService.getMyUserProfile();
       
        if (this.userProfile.EmailStatus == "initial")
        {
            this.subject = "Email Address Not Confirmed"
            this.body = "Our system shows that you have not confirmed the email " + this.userProfile.UserEmail +  ". Confirm or Update now.";
        }
        if (this.userProfile.EmailStatus == "diff")
        {
            this.subject = "Email Address Changed"
            this.body = "Our system shows that your email address may have changed to " + this.userProfile.UserEmail + ". Would you like to accept this change?";
        }
        if (this.userProfile.EmailStatus == "empty")
        {
            this.subject = "Email Address Missing"
            this.body = "Our system shows that you do not have an email address associated with your account. Please add email now";
        }
       
        let icon = new InfoIcon();
        //icon.type = InfoIconType.CustomImage;
        //icon.iconInstruction = "/assets/icons/custom/info.ico";
       // icon.iconName = "";
        icon.type = InfoIconType.FontAwesomeIcon;
        icon.iconInstruction = "fa-bell icon-2x icon-cog text-red";
        icon.list =[this.subject, this.body];
        this.infoIcons.push(icon);

               
    }
    reject(){
        console.log("reject");
        this.userProfile.EmailStatus = "changed";
        this._userService.changeMyEmail(this.userProfile)
        .subscribe(
             value => {
                 if (value === true) {
                 
                     this.appInsightsService.logEvent("Email has been changed Success.");
                     this._userService.setMyUserProfile(this.userProfile);
                     this.alertService.success(
                         "Your email address has been updated."
                     );
                     this.onConfirmChange();
                 
                 }
             },
             (error: any) => {
                 
                 this.appInsightsService.logEvent("Email Change Failure.");
                 this.alertService.error(
                     "Unable to change email please try again"
                 );
             },
           
         );

    }
    confirm(){

       console.log("confirm");
       this.userProfile.Email = this.userProfile.UserEmail;
       this.userProfile.EmailStatus = "changed";
       this._userService.changeMyEmail(this.userProfile)
       .subscribe(
            value => {
                if (value === true) {
                
                    this.appInsightsService.logEvent("Email has been confirmed Success.");
                   
                    this._userService.setMyUserProfile(this.userProfile);
                    this.alertService.success(
                        "Your email address has been updated."
                    );
                   
                    this.onConfirmChange();
                
                }
            },
            (error: any) => {
                
                this.appInsightsService.logEvent("Email Change Failure.");
                this.alertService.error(
                    "Unable to change email please try again"
                );
            },
          
        );


    }
    change(){

        console.log("change");
       
        this.router.navigate(["/account/settings"]);
    }

    onConfirmChange(){
        this.confirmEvent.emit("changed");
    
      }
}
