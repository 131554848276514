import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { PmcSharedModule } from "@pmc/shared.module";
import { OrderHistoryComponent } from "./order-history.component";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatCardModule } from "@angular/material/card";
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

const routes = [
    {
        path: "order-history",
        component: OrderHistoryComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        MatPaginatorModule,
        MatInputModule,
        MatFormFieldModule,
        MatSortModule,
        MatTableModule,
        MatSliderModule,
        MatCardModule,
        TranslateModule,
        PmcSharedModule,
        CommonModule,
        MatButtonModule,
        MatOptionModule,
        MatSelectModule,
        MatIconModule
    ],
    declarations: [OrderHistoryComponent],
    exports: [OrderHistoryComponent]
})
export class OrderHistoryModule {}
