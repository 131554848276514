export class SignupUser  {
    public constructor(init?: Partial<SignupUser>) {
      Object.assign(this, init);
      }
      Message: string;
      Token: string;
      UserName: string;
      FirstName: string;
      LastName: string;
      AzureObjectId?: string;
      UserType: string;
      Email: string;
      Password: string;
      Id?: string;
      MemberId: string;
      ResidentId: string;
      Last4Ssn: string;
      Attempts: number;
    };
    export class InviteResponse{
      public constructor(init?: Partial<InviteResponse>) {
        Object.assign(this, init);
      }
      Email: string;
      FirstName: string;
      LastName: string;
      MemberId: string;
      PharmacyCode: string;
      Query: string;
      Redirect: string;
      ResidentId: string;
      Result: string;
      UserType: string;
      ValidFrom: string;
      ValidTo: string;
      Attempts: number;
    };
  
    export class InviteRequest {
      public constructor(init?: Partial<InviteRequest>) {
        Object.assign(this, init);
      }
      token: string;
      memberId: string;
      firstName: string;
      lastName: string;
      email: string;
      last4Ssn: string;
    };
    
  
    
    