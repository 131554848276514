import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";


import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';


import { PmcSharedModule } from "@pmc/shared.module";
import { NavbarHorizontalStyle1Module } from "app/layout/components/navbar/horizontal/style-1/style-1.module";
import { FooterComponent } from "app/layout/components/footer/footer.component";
import { PrivacyDialogComponent } from './privacy-dialog/privacy-dialog.component';
import { TermsOfUseDialogComponent } from './terms-of-use-dialog/terms-of-use-dialog.component';

@NgModule({
    declarations: [
        FooterComponent,
        PrivacyDialogComponent,
        TermsOfUseDialogComponent
    ],
    imports: [
        RouterModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatToolbarModule,
        NavbarHorizontalStyle1Module,
        PmcSharedModule
    ],
    exports: [FooterComponent],
    entryComponents: [
        PrivacyDialogComponent, 
        TermsOfUseDialogComponent
    ]
})
export class FooterModule {}
