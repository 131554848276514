import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Config } from "../config/config";
import { ResidentFinPlan } from 'app/models/residentFinPlan';
import { ResidentRequest } from 'app/models/ResidentRequest';
import { UserService } from './user.service';
import { ResidentAccount } from 'app/models/user/userProfile';
import { BofaSAMLResponse } from 'app/models/bofaSAMLResponse';
import { AppInsightsService } from 'app/appInsights/app-insights.service';

@Injectable({
  providedIn: 'root'
})
export class UpdatePaymentService {

  protected apiServer = Config.settings.apiServer;
  constructor(private http: HttpClient, 
    private config: Config,
    private userService: UserService,
    private appInsightsService: AppInsightsService) {
    this.apiServer = Config.settings.apiServer;
  }

  getResidentFinPlans(request: ResidentRequest): Observable<ResidentFinPlan> {
    this.appInsightsService.logEvent("Pay my bill clicked", request.accountNumber);
    return this.http.post<ResidentFinPlan>(this.apiServer.metadata + "/UpdatePayment/GetFinancialPlans", request);
  }

  getSAMLResponse(accountNumber: string) {
    var resident: ResidentAccount = this.userService.getActiveResident();
    var user = this.userService.getMyUserProfile();
    var residentRequest: ResidentRequest = new ResidentRequest();
    
    residentRequest.FacilityCode = resident.FacilityCode;
    residentRequest.PharmacyCode = resident.PharmacyCode;
    residentRequest.ResidentCode = resident.ResidentCode;
    residentRequest.accountNumber = accountNumber;
    let self= this;
    this.http.post<any>(this.apiServer.metadata + "/UpdatePayment/GetSAMLResponse", residentRequest).subscribe((result:any)=>{
      self.redirectToBofa(result);
    })
  }

  redirectToBofa(result: BofaSAMLResponse) {
    let form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('target', '_blank');
    form.setAttribute('target', '_blank');
    form.setAttribute('action', result.Action);

    let input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'SAMLResponse');
    input.setAttribute('value', result.SAMLResponse);

    form.appendChild(input);
    document.body.append(form);
    this.appInsightsService.logEvent("Bill Pay Redirect Success");
    form.submit();
  }
}
