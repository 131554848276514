import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    ElementRef,
    ViewChild,
    AfterViewInit,
    Self
} from "@angular/core";
import { PmcTranslationLoaderService } from "@pmc/services/translation-loader.service";
import { locale as english } from "./i18n/en";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserService } from "app/services/user.service";
import { PrintSaveService } from "app/services/print-save.service";
import { EventEmitService } from "app/services/event-emit.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { MatTableDataSource } from '@angular/material/table';
import { MatTab } from '@angular/material/tabs';
import { MatTabGroup } from '@angular/material/tabs';
import { MatTabChangeEvent } from "@angular/material/tabs";

import { MatSort } from '@angular/material/sort';
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

import {Sort} from "@angular/material/sort";
import { MedicationList, Medication } from "app/models/medicationList";
import { SelectionModel } from "@angular/cdk/collections";
import { AngularWaitBarrier } from "blocking-proxy/built/lib/angular_wait_barrier";
import { stringToKeyValue } from "@angular/flex-layout/extended/typings/style/style-transforms";
import { AllergyList } from "app/models/allergyList";
import { DemographicInfo } from "app/models/demographicInfo";
import { AlertService } from "app/services/alert.service";
import { PmcConfigService } from "@pmc/services/config.service";
import { StringService } from "app/services/string.service";
import { pmcConfig } from "app/pmc-config";
import { Constants } from "app/services/constants";
import { DoctorByAddress } from "app/models/doctorByAddress";
import { AppInsightsService } from "app/appInsights/app-insights.service";


@Component({
    selector: "medication-list",
    templateUrl: "./medication-list.component.html",
    styleUrls: ["./medication-list.component.scss"]
})
export class MedicationListComponent
    implements OnInit, OnDestroy, AfterViewInit {
    private contextSubscription: Subscription;
    apiObject: MedicationList;
    allMedications: Medication[];
    activeMedications: Medication[];
    printActiveMeds: Medication[];
    discontinuedMedications: Medication[];
    printDiscontinuedMeds: Medication[];
    currentResidentId: number;
    selectedResident: any;
    activeDayMax: string;
    activeDaySelect: DropdownValue[];
    historyDayMax: string;
    historyDaySelect: DropdownValue[];
    activeLastDispensedVaild: string[];
    isLoading: boolean;
    hasMeds: boolean = true;
    tabIndex: number = 0;
    tollFreeNumber: string;
    tollFreeNumberTel: string;
    dateSortDesc = function (m1: Medication, m2: Medication) {
        // This is a comparison function that will result in dates being sorted in
        // DESCENDING order.
        let a = new Date(m1.LastFillDate);
        let b = new Date(m2.LastFillDate);
        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
    };

    dateSortAsc = function (m1: Medication, m2: Medication) {
        // This is a comparison function that will result in dates being sorted in
        // ASCENDING order.
        let a = new Date(m1.LastFillDate);
        let b = new Date(m2.LastFillDate);
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    };

    medNameSortAsc = function (m1: Medication, m2: Medication) {
        // This is a comparison function that will result in medication name being sorted in
        // ASCENDING order.
        if (m1.MedicationName < m2.MedicationName) return -1;
        if (m1.MedicationName > m2.MedicationName) return 1;
        return 0;
    };

    medNameSortDesc = function (m1: Medication, m2: Medication) {
        // This is a comparison function that will result in medication name being sorted in
        // DESCENDING order.
        if (m1.MedicationName > m2.MedicationName) return -1;
        if (m1.MedicationName < m2.MedicationName) return 1;
        return 0;
    };

    allergyList: AllergyList;
    doctors: DoctorByAddress[];
    primaryDoctor: string;
    demographicInfo: DemographicInfo;
    allergies: string;
    public activeMedicationsMTDS: MatTableDataSource<Medication>;
    public discontinuedMedicationsMTDS: MatTableDataSource<Medication>;
    @ViewChild("activeMedicationSort", {static: false}) sort: MatSort;
    activeMedicationSort: MatSort;
    
    @ViewChild("discontinuedMedicationSort",{ read: true, static: false })
    discontinuedMedicationSort: MatSort;
    @ViewChild("tab1", {static: false})
    activeMedsTab: MatTab;
    @ViewChild("tab2", {static: false})
    discontinuedMedsTab: MatTab;
    @ViewChild("tabs", {static: false})
    tabs: MatTabGroup;

    displayedColumns: string[] = ["LastFillDate", "MedicationName"];
    displayedColumns2: string[] = ["LastFillDate", "MedicationName"];
    selection = new SelectionModel<Medication>(true, []);

    tollFreeNumberMarkup: string;

    constructor(
        private _pmcTranslationLoaderService: PmcTranslationLoaderService,
        private _pmcConfigService: PmcConfigService,
        private _userService: UserService,
        private _eventEmitService: EventEmitService,
        private _router: Router,
        private _prtSaveService: PrintSaveService,
        private _alertService: AlertService,
        private _stringService: StringService,
        private appInsightsService: AppInsightsService
    ) {
        this.allergies = '';
        this._pmcTranslationLoaderService.loadTranslations(english);
        this.contextSubscription = this._eventEmitService.userContextChangeEvent.subscribe(
            (data: any) => {
                if (
                    this._router.url === "/medications/list" &&
                    data.Id !== this.currentResidentId
                )
                    this.setDefaultFilterValues();
                this.displayMedicationList();
            }
        );
    }

    ngAfterViewInit(): void { 
        
      // this.activeMedicationsMTDS.sort = this.sort;
      // this.discontinuedMedicationsMTDS.sort = this.sort;
    }

    ngOnDestroy() {
       
    }

    ngOnInit() {
        this.activeLastDispensedVaild = ["L", "D", "A"];
        this.setDefaultFilterValues();
        this.displayMedicationList();
        this.tollFreeNumberMarkup = this._stringService.markupGetTollFree();
        this.tollFreeNumber = pmcConfig.layout.header.tollFreeNumber;
        this.tollFreeNumberTel = pmcConfig.layout.header.tollFreeNumber.replace(
            /\D/g,
            ""
        );
    }

    setDefaultFilterValues() {
        this.activeDaySelect = this.getDropDownDays([30, 60], 60, true);
        this.historyDaySelect = this.getDropDownDays(
            [30, 60, 90, 120],
            60,
            false
        );
    }

    bindAndFilterActiveMeds() {
        let self = this;
        //filter data - no discontinued meds in active collection

        let today = new Date();
        this.activeMedications = this.allMedications.filter(d => {
            return (
                Date.parse(d.LastFillDate) >= Date.parse(self.activeDayMax) &&
                ((d.DiscontinueDate !== null &&
                    Date.parse(d.DiscontinueDate) >
                    Date.parse(today.toString())) ||
                    d.DiscontinueDate === null)
            );
        });
        this.activeMedications = this.activeMedications.filter(function (i, n) {
            return i.PrescriptionInformation.Status.toLowerCase() === "a";
        });

        //bind data to mat table data source
        this.activeMedicationsMTDS = new MatTableDataSource<Medication>(
            self.activeMedications
        );

        this.activeMedications = this.defaultSort(this.activeMedications);

        this.printActiveMeds = this.activeMedications;
        //sort
        /*
        this.activeMedicationSort.sortChange.subscribe((data: any) => {
            self.printActiveMeds = self.sortMedications(
                self.activeMedications,
                data.active,
                data.direction
            );
        });
        */
        this.activeMedicationsMTDS.sort = this.activeMedicationSort;
    }

    bindAndFilterDiscontinuedMeds() {
        let self = this;
        //filter data
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let lastPrescriptionNumber = 0;
        this.discontinuedMedications = this.allMedications.sort(function (a, b) {
            return a.PrescriptionNumber < b.PrescriptionNumber ? 1 : -1;
        });
        this.discontinuedMedications = this.allMedications.filter(d => {
            let pn = parseInt(d.PrescriptionNumber.toString());
            let returnValue = false;
            let discontinueDate = new Date(
                d.PrescriptionInformation.DiscontinueDate
            );

            if (
                Date.parse(d.LastFillDate) >= Date.parse(self.historyDayMax) &&
                pn !== lastPrescriptionNumber &&
                d.DiscontinueDate !== null &&
                discontinueDate <= today
            ) {
                returnValue = true;
            }
            lastPrescriptionNumber = pn;
            return returnValue;
        });

        this.discontinuedMedications = this.defaultSort(
            this.discontinuedMedications
        );

        this.printDiscontinuedMeds = this.discontinuedMedications;
        //bind data to mat table data source
        this.discontinuedMedicationsMTDS = new MatTableDataSource<Medication>(
            this.discontinuedMedications
        );
        /*
        this.discontinuedMedicationSort.sortChange.subscribe((data: any) => {
            self.printDiscontinuedMeds = self.sortMedications(
                self.discontinuedMedications,
                data.active,
                data.direction
            );
        });
        */
        //sort
        this.discontinuedMedicationsMTDS.sort = this.discontinuedMedicationSort;
    }

    defaultSort(meds: Medication[]): Medication[] {
        return meds
            .sort(function (a, b) {
                var c = new Date(a.LastFillDate),
                    d = new Date(b.LastFillDate);
                if (c > d) return 1;
                if (c < d) return -1;
                if (a.PrescriptionNumber > b.PrescriptionNumber) return 1;
                if (a.PrescriptionNumber < b.PrescriptionNumber) return -1;
            })
            .reverse();
    }

    changeDayFilter($event: any, isActiveMeds: boolean) {
        let self = this;
        if (isActiveMeds) {
            self.activeDayMax = $event.value;
            this.bindAndFilterActiveMeds();
        } else {
            self.historyDayMax = $event.value;
            this.bindAndFilterDiscontinuedMeds();
        }
    }

    displayMedicationList() {
        this.isLoading = true;
        this._userService.getAllMedicationList().subscribe(value => {
            this.isLoading = false;
            this.apiObject = value; //save api value
            this.allMedications = value.Medications;
            this.allMedications = this.allMedications.filter(function (i, n) {
                return (
                    i.PrescriptionInformation.LastDispenseFunction.toLowerCase() ==
                    "l" ||
                    i.PrescriptionInformation.LastDispenseFunction.toLowerCase() ==
                    "d"
                );
            });
            this.allMedications = this.allMedications.sort();

            this.bindAndFilterActiveMeds();
            this.bindAndFilterDiscontinuedMeds();
            if (value.Medications.length === 0) this.hasMeds = false;
        });
        let self = this;
        self.selectedResident = this._userService.getActiveResident();
        this.currentResidentId = self.selectedResident.Id;
        this._userService.getResidentDemographicInfo().subscribe(
            value => {
                self.demographicInfo = value;
                if (
                    self.demographicInfo.Plan.PlanName === null ||
                    self.demographicInfo.Plan.PlanName === ""
                ) {
                    self.demographicInfo.Plan.PlanName = "Not Available";
                }
                if (
                    self.demographicInfo.Plan.InsurerProviderWebAddress ===
                    null ||
                    self.demographicInfo.Plan.InsurerProviderWebAddress === ""
                ) {
                    self.demographicInfo.Plan.InsurerProviderWebAddress =
                        "Not Available";
                }
                if (
                    self.demographicInfo.Plan.InsurerProviderPhone === null ||
                    self.demographicInfo.Plan.InsurerProviderPhone === ""
                ) {
                    self.demographicInfo.Plan.InsurerProviderPhone =
                        "Not Available";
                }
                if (
                    self.demographicInfo.Plan.InsurerProviderName === null ||
                    self.demographicInfo.Plan.InsurerProviderName === ""
                ) {
                    self.demographicInfo.Plan.InsurerProviderName =
                        "Not Available";
                }
            },
            () => {
                this._alertService.info(
                    "Error ocurred while fetching medication list"
                );
            }
        );

        this._userService.getAllergies().subscribe(
            value => {
                self.allergyList = value;
                self.allergies = "";
                self.allergyList.Allergies.forEach((a) => {
                    self.allergies += a.ClassificationDescription + ',';
                })
                if (self.allergies.length > 0) {
                    self.allergies = self.allergies.slice(0, -1);
                }
            },
            () => {
                this._alertService.info(
                    "Error ocurred while fetching medication list"
                );
            }
        );
        this._userService.getDoctorList().subscribe(
            value => {
                self.doctors = value;
                var doctor = self.doctors.find((e) => {
                    return e.Doctor.PrimaryDoctorFlag.trim().toLocaleLowerCase() == 'primary care physician';
                })
                self.primaryDoctor = doctor.Doctor.FirstName + ' ' + doctor.Doctor.LastName;
            },
            () => {
                this._alertService.info(
                    "Error ocurred while fetching doctors list"
                );
            }
        );
    }

    getDateStringFromNow(dayAmount: number): string {
        let dt = new Date(),
            v = new Date(dt.setDate(dt.getDate() - dayAmount));
        var dateString =
            v.getUTCFullYear() +
            "/" +
            ("0" + (v.getUTCMonth() + 1)).slice(-2) +
            "/" +
            ("0" + v.getUTCDate()).slice(-2) +
            " " +
            ("0" + v.getUTCHours()).slice(-2) +
            ":" +
            ("0" + v.getUTCMinutes()).slice(-2) +
            ":" +
            ("0" + v.getUTCSeconds()).slice(-2);

        return dateString;
    }

    getDropDownDays(
        values: number[],
        defaultValue: number,
        isActiveMeds: boolean
    ): DropdownValue[] {
        let retval = [];
        values.forEach(obj => {
            let ddd = new DropdownValue();
            ddd.value = this.getDateStringFromNow(obj);
            ddd.viewValue = obj.toString();
            if (isActiveMeds && defaultValue === obj)
                this.activeDayMax = ddd.value;
            if (!isActiveMeds && defaultValue === obj)
                this.historyDayMax = ddd.value;
            retval.push(ddd);
        });
        return retval;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.activeMedicationsMTDS.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.activeMedicationsMTDS.data.forEach(row =>
                this.selection.select(row)
            );
    }

    printMedicationList(elementId: string) {
       
        this._prtSaveService.printAsPdf(elementId);
    }

    saveMedList(elementId: string) {
      
        this._prtSaveService.saveAsPdf(elementId, "med_list");
    }

    onTabChange(event: MatTabChangeEvent) {

    }

    selectTab(index: number) {
        this.tabIndex = index;
        this.tabs.selectedIndex = index;
    }
    discontinuedMedicationsMTDSData(sort: Sort){
        if (!sort.active || sort.direction === '') {
            
            return;
          }
        this.discontinuedMedicationsMTDS = new MatTableDataSource<Medication>(
            this.sortMedications(this.discontinuedMedications, sort.active, sort.direction)
        );
      
        return;

    }
    activeMedicationSortData(sort: Sort){
        if (!sort.active || sort.direction === '') {
            
            return;
          }
        this.activeMedicationsMTDS = new MatTableDataSource<Medication>(
            this.sortMedications(this.activeMedications, sort.active, sort.direction)
        );
      
        return;

    }
    sortMedications(
        meds: Medication[],
        column: string,
        direction: string
    ): Medication[] {
        var sortedArray: Medication[];
        if (column === "MedicationName") {
            var sortMap: {
                [direction: string]: (m1: Medication, m2: Medication) => number;
            } = {};
            sortMap["asc"] = this.medNameSortAsc;
            sortMap["desc"] = this.medNameSortDesc;
            sortedArray = meds.sort(sortMap[direction.toLowerCase()]);
        }
        if (column === "LastFillDate") {
            var sortMap: {
                [direction: string]: (m1: Medication, m2: Medication) => number;
            } = {};
            sortMap["asc"] = this.dateSortAsc;
            sortMap["desc"] = this.dateSortDesc;
            sortedArray = meds.sort(sortMap[direction.toLowerCase()]);
        }
        return sortedArray;
    }

    getDrugInfo(med: Medication) {
        this._router.navigate([
            "medications/drug-reference",
            med.NationalDrugCode,
            med.MedicationName
        ]);
    }
}

export class DropdownValue {
    value: string;
    viewValue: string;
}
