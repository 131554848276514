import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { PmcSharedModule } from "@pmc/shared.module";

import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';

import { ManageDoctorsComponent } from "./manage-doctors.component";
import { AuthGuard } from "app/services/auth.guard";

const routes = [
    {
        path: "manage-doctors",
        component: ManageDoctorsComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [ManageDoctorsComponent],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        MatTableModule,
        MatSliderModule,
        MatCardModule,
        PmcSharedModule
    ],
    exports: [ManageDoctorsComponent],
    providers: [AuthGuard]
})
export class ManageDoctorsModule {}
