import { Component, Output, OnInit, ViewEncapsulation } from "@angular/core";
import { PmcConfigService } from "@pmc/services/config.service";
import { pmcAnimations } from "@pmc/animations";
import { AppUser, AppUserAuth } from "app/models/user/app-user";
import { AuthService } from "app/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "app/services/user.service";
import { UserProfile, ResidentAccount, LogUser } from "app/models/user/userProfile";
import { PmcNavigationService } from "@pmc/components/navigation/navigation.service";
import { ChangePasswordService } from "app/services/change-password.service";
import { AppInsightsService } from 'app/appInsights/app-insights.service';

@Component({
    selector: "account-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: pmcAnimations
})
export class LoginComponent implements OnInit {
    user: AppUser = new AppUser();
    authObject: AppUserAuth = null;
    userProfile: UserProfile;
    residents: ResidentAccount[];
    errorMessage: string = "";
    navigation: any;
    logUser: LogUser;

    @Output()
    isLoading: boolean;

    constructor(
        private _pmcConfigService: PmcConfigService,
        private _pmcNavigationService: PmcNavigationService,
        private authService: AuthService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private changePasswordService: ChangePasswordService,
        private appInsightsService: AppInsightsService
    ) {
        // Configure the layout
        this._pmcConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                header: {
                    hidden: true
                },
                body: {
                    contain: false
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.authService.userLogout().subscribe(data => { });
        this.authService.logout();
    }
    activate() {
        this.router.navigate(["/activate"]);
    }
    forgotPswd() {
        this.router.navigate(["/forgotpswd"]);
    }
    login() {
        var self = this;
        this.isLoading = true;
        self.errorMessage = "";
        
        this.authService.login(
            this.user,
            (authObj: AppUserAuth) => {
                self.authObject = authObj;
                
                this.userService.getUserProfile().subscribe(value => {
                    self.isLoading = false;
                    self.userProfile = value;
                  
                    if ( self.userProfile != null && self.userProfile.ResidentAccounts != null ) {
                        self.residents = self.userProfile.ResidentAccounts;
                        self.userService.setMyUserProfile(self.userProfile);
                        self.userService.setAllResidents(self.residents);
                        self.userService.setActiveResident(
                            self.residents[0].Id
                        );
                        self.logUser = new LogUser();
                        self.logUser.FacilityName = self.residents[0].FacilityName;
                        self.logUser.PharmacyName = self.residents[0].PharmacyName;
                        self.logUser.UserType = self.residents[0].Role;
                        
                        self.loadNavigation();
                        // check otc store redirect
                        var returnUrl = this.route.snapshot.queryParamMap.get(
                            "redirect_uri"
                        );
                        if (self.userProfile.ForcePasswordChange) {
                            self.changePasswordService.setPasswordChange(true);
                            self.changePasswordService.setUserName(
                                self.user.userName
                            );
                            self.changePasswordService.setPassword(
                                self.user.password
                            );
                            self.router.navigate(["/account/changepassword"]);
                        } else if (returnUrl == null)
                            self.router.navigate(["/dashboard"]);
                        else {
                            returnUrl =
                                returnUrl +
                                "?id_token=" +
                                self.authObject.bearerToken +
                                "&object_id=" +
                                self.userProfile.ObjectId;
                            window.location.href = returnUrl;
                        }
                        this.appInsightsService.setAuthenticatedUserId(self.user.userName);
                        this.appInsightsService.logEventJson("Login Success", self.logUser);

                    }
                    else
                    {
                        self.errorMessage = "Unable to validate your credentials. Please verify your credentials and try again.";
                        self.isLoading = false;
                    }
                });
            },
            (data: any) => {
                self.isLoading = false;
                if (data == "lock")
                {
                    
                    self.errorMessage = "Your account has been locked due to invalid login attempts. Please use the Forgot Password link to reset your password or call the Customer Service Center.";

                }
                else
                {
                    self.errorMessage = "Unable to validate your credentials. Please verify your credentials and try again.";
                }
               
            
            },
            true
        );
    }

    loadNavigation() {
        var user = this.userService.getMyUserProfile();
        var navs = user.Navigations;
        this._pmcNavigationService.onNavigationUnregistered;
        this._pmcNavigationService.unregister("main");
        // Register the navigation to the service
        this._pmcNavigationService.register("main", navs);

        // Set the main navigation as our current navigation
        this._pmcNavigationService.setCurrentNavigation("main");
    }
    signup() {
        this.router.navigate(["/activate"]);
    }
}
