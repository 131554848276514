import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PmcConfigService } from "@pmc/services/config.service";
import { MatDialog } from "@angular/material/dialog";
import { PrivacyDialogComponent } from "./privacy-dialog/privacy-dialog.component";
import { TermsOfUseDialogComponent } from "./terms-of-use-dialog/terms-of-use-dialog.component";

@Component({
    selector: "footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit, OnDestroy {
    containWidth: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;
    private _currentYear: number;
    constructor(
        private _pmcConfigService: PmcConfigService,
        private _dialog: MatDialog
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        // Subscribe to the config changes
        this._pmcConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(settings => {
                this.containWidth = settings.layout.footer.contain === true;
            });
        this._currentYear = new Date().getFullYear();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    openPrivacyDialog() {
        this._dialog.open(PrivacyDialogComponent, {
            width: "500px",
            autoFocus: false
        });
    }

    openTermsDialog() {
        this._dialog.open(TermsOfUseDialogComponent, {
            width: "500px",
            autoFocus: false
        });
    }
}
