import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Config } from 'app/config/config';
import { SignupUser, InviteRequest, InviteResponse } from '../models/signup';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  protected apiServer = Config.settings.apiServer;
  constructor(private http: HttpClient, 
    private config: Config ) { 
      this.apiServer = Config.settings.apiServer;
    }
    signup(entity: SignupUser): Observable<SignupUser> {
      
      return this.http.post<SignupUser>(this.apiServer.metadata + '/signup/', entity);
    }
    invite(request: InviteRequest): Observable<InviteResponse> {
      return this.http.post<InviteResponse>(this.apiServer.metadata + '/invite/', request);
    }
   

    validateUserName(username: string): Observable<Boolean> {

      return this.http.get<Boolean>(this.apiServer.metadata + '/username/' + username);
     
    } 
}
