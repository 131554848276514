import { NgModule } from "@angular/core";
import { TileComponent } from "./tile.component";
import { CommonModule } from "@angular/common";
import { InfoIconModule } from "@pmc/components/info-icon/info-icon.module";
import { TileResidentInfoComponent } from "./templates/tile-resident-info.component";
import { TileOrderSummaryComponent } from "./templates/tile-order-summary.component";
import { TileResidentAllergiesComponent } from "./templates/tile-resident-allergies.component";
import { PmcPipesModule } from "@pmc/pipes/pipes.module";
import { PmcDirectivesModule } from "@pmc/directives/directives";
import { TileEHealthHelpComponent } from "./templates/tile-eheath-help.component";
import { TileEMailNotifyComponent } from "./templates/tile-email-notify.component";

@NgModule({
    imports: [
        CommonModule,
        InfoIconModule,
        PmcPipesModule,
        PmcDirectivesModule
    ],
    declarations: [
        TileComponent,
        TileResidentInfoComponent,
        TileOrderSummaryComponent,
        TileResidentAllergiesComponent,
        TileEHealthHelpComponent,
        TileEMailNotifyComponent
    ],
    exports: [
        TileComponent,
        TileResidentInfoComponent,
        TileOrderSummaryComponent,
        TileResidentAllergiesComponent,
        TileEHealthHelpComponent,
        TileEMailNotifyComponent
    ],
    entryComponents: [
        TileResidentInfoComponent,
        TileOrderSummaryComponent,
        TileResidentAllergiesComponent,
        TileEHealthHelpComponent,
        TileEMailNotifyComponent
    ]
})
export class TileModule {}
