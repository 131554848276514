import { Component, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppValService } from "../../services/app-val.service";
import { AppVal } from "../../models/app-val";
import { StringService } from "../../services/string.service";

@Component({
    selector: "app-error-page",
    templateUrl: "./error-page.component.html",
    styleUrls: ["./error-page.component.scss"]
})
export class ErrorPageComponent implements OnInit {
    pageValues: any;

    @Output()
    isLoading: Boolean;

    constructor(
        private route: ActivatedRoute,
        private appValService: AppValService,
        private stringService: StringService
    ) {}

    ngOnInit() {
        var self = this;
        this.isLoading = true;

        this.appValService.getLocalVal(
            "ErrorMessage",
            this.route.snapshot.params["id"],
            (appVal: AppVal) => {
                if (appVal && appVal.value != null) {
                    this.registerPageValues(appVal.value);
                }
            },
            error => {
                console.log("error", error);
                this.pageValues.subHeader =
                    "We have made note of this error. Please call us if you need immediate help." +
                this.stringService.markupGetTollFree();
            },
            () => (this.isLoading = false)
        );
    }

    registerPageValues(pageValues: any) {
        if (pageValues) {
            pageValues.pageClass =
                pageValues.pageClass || this.route.snapshot.params["id"];
            if (pageValues.subHeader) {
                pageValues.subHeader = this.stringService.replaceContentMatch(
                    pageValues.subHeader,
                    "phone",
                    this.stringService.markupGetTollFree()
                );
            }
        }
        this.pageValues = pageValues;
        this.isLoading = false;
    }
}
