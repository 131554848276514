import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { FlexLayoutModule } from "@angular/flex-layout";

import { PmcDirectivesModule } from "@pmc/directives/directives";
import { PmcPipesModule } from "@pmc/pipes/pipes.module";
import { LegacyFormModule } from "app/layout/wrappers/legacy-form/legacy-form.module";
import { InfoIconModule } from "./components/info-icon/info-icon.module";
import { TileModule } from "./components/tile/tile.module";
import { UserRoleBannerModule } from "./components/user-role-banner/user-role-banner.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        PmcDirectivesModule,
        PmcPipesModule,
        UserRoleBannerModule,
        LegacyFormModule,
        TileModule,
        InfoIconModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        PmcDirectivesModule,
        PmcPipesModule,
        UserRoleBannerModule,
        LegacyFormModule,
        TileModule,
        InfoIconModule
    ]
})
export class PmcSharedModule {}
