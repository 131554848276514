import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintFooterComponent } from './print-footer.component';

@NgModule({
  declarations: [PrintFooterComponent],
  imports: [
    CommonModule
  ],
  exports: [PrintFooterComponent]

})
export class PrintFooterModule { }
