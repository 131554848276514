import {
    Component,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DOCUMENT } from "@angular/common";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { pmcAnimations } from "@pmc/animations";
import { PmcConfigService } from "@pmc/services/config.service";
import { PmcNavigationService } from "@pmc/components/navigation/navigation.service";
import { PmcSidebarService } from "@pmc/components/sidebar/sidebar.service";

@Component({
    selector: "pmc-theme-options",
    templateUrl: "./theme-options.component.html",
    styleUrls: ["./theme-options.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: pmcAnimations
})
export class PmcThemeOptionsComponent implements OnInit, OnDestroy {
    pmcConfig: any;
    form: FormGroup;

    @HostBinding("class.bar-closed")
    barClosed: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FormBuilder} _formBuilder
     * @param {PmcConfigService} _pmcConfigService
     * @param {PmcNavigationService} _pmcNavigationService
     * @param {PmcSidebarService} _pmcSidebarService
     * @param {Renderer2} _renderer
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _formBuilder: FormBuilder,
        private _pmcConfigService: PmcConfigService,
        private _pmcNavigationService: PmcNavigationService,
        private _pmcSidebarService: PmcSidebarService,
        private _renderer: Renderer2
    ) {
        // Set the defaults
        this.barClosed = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Build the config form
        // noinspection TypeScriptValidateTypes
        this.form = this._formBuilder.group({
            colorTheme: new FormControl(),
            customScrollbars: new FormControl(),
            layout: this._formBuilder.group({
                style: new FormControl(),
                width: new FormControl(),
                navbar: this._formBuilder.group({
                    primaryBackground: new FormControl(),
                    secondaryBackground: new FormControl(),
                    folded: new FormControl(),
                    hidden: new FormControl(),
                    position: new FormControl(),
                    variant: new FormControl(),
                    contain: new FormControl()
                }),
                toolbar: this._formBuilder.group({
                    background: new FormControl(),
                    customBackgroundColor: new FormControl(),
                    hidden: new FormControl(),
                    position: new FormControl(),
                    contain: new FormControl()
                }),
                header: this._formBuilder.group({
                    shrinkOnScroll: new FormControl(),
                    hidden: new FormControl(),
                    contain: new FormControl()
                }),
                footer: this._formBuilder.group({
                    background: new FormControl(),
                    customBackgroundColor: new FormControl(),
                    hidden: new FormControl(),
                    position: new FormControl(),
                    contain: new FormControl()
                }),
                sidepanel: this._formBuilder.group({
                    hidden: new FormControl(),
                    position: new FormControl()
                })
            })
        });

        // Subscribe to the config changes
        this._pmcConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                // Update the stored config
                this.pmcConfig = config;

                // Set the config form values without emitting an event
                // so that we don't end up with an infinite loop
                this.form.setValue(config, { emitEvent: false });
            });

        // Subscribe to the specific form value changes (layout.style)
        this.form
            .get("layout.style")
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                // Reset the form values based on the
                // selected layout style
                this._resetFormValues(value);
            });

        // Subscribe to the form value changes
        this.form.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                // Update the config
                this._pmcConfigService.config = config;
            });

        // Add customize nav item that opens the bar programmatically
        /*const customFunctionNavItem = {
            'id'      : 'custom-function',
            'title'   : 'Custom Function',
            'type'    : 'group',
            'icon'    : 'settings',
            'children': [
                {
                    'id'      : 'customize',
                    'title'   : 'Customize',
                    'type'    : 'item',
                    'icon'    : 'settings',
                    'function': () => {
                        this.toggleSidebarOpen('themeOptionsPanel');
                    }
                }
            ]
        };

        this._pmcNavigationService.addNavigationItem(customFunctionNavItem, 'end');*/
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        // Remove the custom function menu
        this._pmcNavigationService.removeNavigationItem("custom-function");
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Reset the form values based on the
     * selected layout style
     *
     * @param value
     * @private
     */
    private _resetFormValues(value): void {
        switch (value) {
            // Vertical Layout #1
            case "vertical-layout-1": {
                this.form.patchValue({
                    layout: {
                        width: "fullwidth",
                        navbar: {
                            primaryBackground: "pmc-navy-700",
                            secondaryBackground: "pmc-navy-900",
                            folded: false,
                            hidden: false,
                            position: "left",
                            variant: "vertical-style-1"
                        },
                        toolbar: {
                            background: "pmc-white-500",
                            customBackgroundColor: false,
                            hidden: false,
                            position: "below-static",
                            contain: true
                        },
                        header: {
                            tollFreeNumber: "1-800-555-5555",
                            logoUrl: "assets/images/logos/valuemed-logo.jpg",
                            shrinkOnScroll: false,
                            contain: true,
                            hidden: false
                        },
                        body: {
                            contain: true
                        },
                        footer: {
                            background: "pmc-navy-900",
                            customBackgroundColor: true,
                            hidden: false,
                            position: "below-static",
                            contain: true
                        },
                        sidepanel: {
                            hidden: false,
                            position: "right"
                        }
                    }
                });

                break;
            }

            // Vertical Layout #2
            case "vertical-layout-2": {
                this.form.patchValue({
                    layout: {
                        width: "fullwidth",
                        navbar: {
                            primaryBackground: "pmc-navy-700",
                            secondaryBackground: "pmc-navy-900",
                            folded: false,
                            hidden: false,
                            position: "left",
                            variant: "vertical-style-1"
                        },
                        toolbar: {
                            background: "pmc-white-500",
                            customBackgroundColor: false,
                            hidden: false,
                            position: "below",
                            contain: true
                        },
                        header: {
                            tollFreeNumber: "1-800-555-5555",
                            logoUrl: "assets/images/logos/valuemed-logo.jpg",
                            shrinkOnScroll: false,
                            hidden: false,
                            contain: true
                        },
                        body: {
                            contain: true
                        },
                        footer: {
                            background: "pmc-navy-900",
                            customBackgroundColor: true,
                            hidden: false,
                            position: "below",
                            contain: true
                        },
                        sidepanel: {
                            hidden: false,
                            position: "right"
                        }
                    }
                });

                break;
            }

            // Vertical Layout #3
            case "vertical-layout-3": {
                this.form.patchValue({
                    layout: {
                        width: "fullwidth",
                        navbar: {
                            primaryBackground: "pmc-navy-700",
                            secondaryBackground: "pmc-navy-900",
                            folded: false,
                            hidden: false,
                            position: "left",
                            layout: "vertical-style-1"
                        },
                        toolbar: {
                            background: "pmc-white-500",
                            customBackgroundColor: false,
                            hidden: false,
                            position: "above-static",
                            contain: true
                        },
                        header: {
                            tollFreeNumber: "1-800-555-5555",
                            logoUrl: "assets/images/logos/valuemed-logo.jpg",
                            shrinkOnScroll: false,
                            hidden: false,
                            contain: true
                        },
                        body: {
                            contain: true
                        },
                        footer: {
                            background: "pmc-navy-900",
                            customBackgroundColor: true,
                            hidden: false,
                            position: "above-static",
                            contain: true
                        },
                        sidepanel: {
                            hidden: false,
                            position: "right"
                        }
                    }
                });

                break;
            }

            // Horizontal Layout #1
            case "horizontal-layout-1": {
                this.form.patchValue({
                    layout: {
                        width: "fullwidth",
                        navbar: {
                            primaryBackground: "pmc-navy-700",
                            secondaryBackground: "pmc-navy-900",
                            folded: false,
                            hidden: false,
                            position: "top",
                            variant: "vertical-style-1"
                        },
                        toolbar: {
                            background: "pmc-white-500",
                            customBackgroundColor: false,
                            hidden: false,
                            position: "above",
                            contain: true
                        },
                        header: {
                            tollFreeNumber: "1-800-555-5555",
                            logoUrl: "assets/images/logos/valuemed-logo.jpg",
                            shrinkOnScroll: false,
                            hidden: false,
                            contain: true
                        },
                        body: {
                            contain: true
                        },
                        footer: {
                            background: "pmc-navy-900",
                            customBackgroundColor: true,
                            hidden: false,
                            position: "above-fixed",
                            contain: true
                        },
                        sidepanel: {
                            hidden: false,
                            position: "right"
                        }
                    }
                });

                break;
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._pmcSidebarService.getSidebar(key).toggleOpen();
    }
}
