import { Component, OnInit } from "@angular/core";

import { PmcTranslationLoaderService } from "@pmc/services/translation-loader.service";
import { EventEmitService } from "app/services/event-emit.service";
import { Router } from "@angular/router";
import { UserService } from "app/services/user.service";
import { locale as english } from "./i18n/en";
import { Subscription } from "rxjs/Subscription";
import { DoctorByAddress } from "app/models/doctorByAddress";
import { ResidentAccount } from "app/models/user/userProfile";
import { Medication } from "app/models/medicationList";
import { AlertService } from "app/services/alert.service";

@Component({
    selector: "manage-doctors",
    templateUrl: "./manage-doctors.component.html",
    styleUrls: ["./manage-doctors.component.scss"]
})
export class ManageDoctorsComponent implements OnInit {
    private contextSubscription: Subscription;

    doctorByAddress: DoctorByAddress[];
    currentResidentId: number;
    selectedResident: any;
    isLoading: boolean;
    residentName: string;
    showError: boolean;

    /**
     * Constructor
     *
     * @param {PmcTranslationLoaderService} _pmcTranslationLoaderService
     */
    constructor(
        private _pmcTranslationLoaderService: PmcTranslationLoaderService,
        private _userService: UserService,
        private _eventEmitService: EventEmitService,
        private _router: Router,
        private alertService: AlertService
    ) {
        this.showError = false;
        this._pmcTranslationLoaderService.loadTranslations(english);
        this.contextSubscription = this._eventEmitService.userContextChangeEvent.subscribe(
            (data: any) => {
                if (
                    this._router.url === "/account/manage-doctors" &&
                    data.Id !== this.currentResidentId
                )
                    this.displayDoctorList();
            }
        );
    }

    bindResidentInfo() {
        let activeResident: ResidentAccount = this._userService.getActiveResident();
        this.residentName = `${activeResident.FirstName} ${
            activeResident.LastName
            }`;
    }

    ngOnInit() {
        this.displayDoctorList();
    }

    displayDoctorList() {
        let self = this;
        this.isLoading = true;
        self.doctorByAddress = [];
        this.showError = false;

        this._userService.getDoctorList().subscribe(
            value => {
                this.isLoading = false;
                self.doctorByAddress = value as DoctorByAddress[];
                if (value === null || value.length === 0) 
                {
                    this.showError = true;
                    this.bindResidentInfo();
                }
            },
            () => {
                this.isLoading = false;
                this.showError = true;
                this.bindResidentInfo();
                this.alertService.info(
                    "Error ocurred while fetching my doctors information"
                );
            }
        );
    }
    getDrugInfo(med: Medication) {
        this._router.navigate([
            "medications/drug-reference",
            med.NationalDrugCode,
            med.MedicationName
        ]);
    }
}
