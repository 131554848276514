import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Config } from "app/config/config";
//import "rxjs/add/operator/map";
import { CachingService } from "app/services/caching.service";
import { CacheItem } from "app/models/generic/cacheItem";
import { shareReplay } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class TileService {
    protected apiServer = Config.settings.apiServer;
    _tileData: Observable<any> = null;
    constructor(
        private http: HttpClient,
        private cachingService: CachingService
    ) {
        this.apiServer = Config.settings.apiServer;
    }

    clearCache() {
        this._tileData = null;
    }

    getTileDataByStringParameters(
        route: string,
        parameters: string = "",
        cacheKey: string
    ): Observable<any> {
        let item: CacheItem = this.cachingService.getCacheItem(cacheKey);
        if (!item) {
            item = new CacheItem();
            item.data = this.http
                .get<any>(this.apiServer.metadata + route + parameters)
                .pipe(shareReplay(1));
            item.expiryTime = 900;
            this.cachingService.setCacheItem(item, cacheKey);
            let item2: CacheItem = this.cachingService.getCacheItem(cacheKey);
        }
        this._tileData = item.data;
        return this._tileData;
    }
}
