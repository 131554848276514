import { PmcConfig } from "@pmc/types";

/**
 * Default Pmc Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const pmcConfig: PmcConfig = {
    // Color themes can be defined in src/app/app.theme.scss
    colorTheme: "theme-default",
    customScrollbars: true,
    lockoutAttemptMax: 3,
    layout: {
        style: "vertical-layout-1",
        width: "fullwidth",
        navbar: {
            primaryBackground: "pmc-navy-700",
            secondaryBackground: "pmc-navy-900",
            folded: false,
            hidden: false,
            position: "left",
            variant: "vertical-style-1"
        },
        toolbar: {
            customBackgroundColor: false,
            background: "pmc-white-500",
            hidden: false,
            position: "below-static",
            contain: true
        },
        header: {
            tollFreeNumber: "1-888-588-1633",
            logoUrl: "assets/images/logos/valuemed-logo.svg",
            shrinkOnScroll: false,
            hidden: false,
            contain: true
        },
        body: {
            contain: true
        },
        footer: {
            customBackgroundColor: true,
            background: "pmc-navy-900",
            hidden: false,
            position: "below-static",
            contain: true
        },
        sidepanel: {
            hidden: false,
            position: "right"
        }
    }
};
