import {
    Component,
    OnInit,
    OnDestroy,
    Output,
    ViewChild,
    ViewEncapsulation,
    AfterViewInit
} from "@angular/core";
import { PmcTranslationLoaderService } from "@pmc/services/translation-loader.service";
import { PmcConfigService } from "@pmc/services/config.service";
import { locale as english } from "./i18n/en";
import { Subscription } from "rxjs/Subscription";
import { ResidentAccount, UserProfile } from "app/models/user/userProfile";
import { AllergyList } from "app/models/allergyList";
import { DemographicInfo } from "app/models/demographicInfo";
import { UserService } from "app/services/user.service";
import { EventEmitService } from "app/services/event-emit.service";
import { AlertService } from "app/services/alert.service";
import { AppInsightsService } from "app/appInsights/app-insights.service";

import { Router } from "@angular/router";
import { Tile } from "app/models/ui/tile";
import { InfoIcon, InfoIconType } from "app/models/ui/infoIcon";
import { TileResidentInfoComponent } from "@pmc/components/tile/templates/tile-resident-info.component";
import { TileOrderSummaryComponent } from "@pmc/components/tile/templates/tile-order-summary.component";
import { TileResidentAllergiesComponent } from "@pmc/components/tile/templates/tile-resident-allergies.component";
import { TileEHealthHelpComponent } from "@pmc/components/tile/templates/tile-eheath-help.component";
//import { TileEMailNotifyComponent } from "@pmc/components/tile/templates/tile-email-notify.component";

import {
    ORDER_HISTORY,
    DEMOGRAPHICINFO,
    ALLERGIES
} from "../../services/caching.service";

@Component({
    selector: "dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
    private contextSubscription: Subscription;
    currentResident: ResidentAccount;
    userProfile: UserProfile;
    allergyList: AllergyList;
    demographicInfo: DemographicInfo;
    tileResidentInfo: Tile;
    tileAccountSummary: Tile;
    tileResidentAllergies: Tile;
    tileEHealthHelp: Tile;
    //tileEmailNotify: Tile;
    tileResidentAllergiesStr: string;
    tileAccountSummaryStr: string;
    tileResidentInfoStr: string;
    isNotification: boolean = false;
    subject: string;
    body: string;
    infoIcons: InfoIcon[];
   

    constructor(
        private _pmcTranslationLoaderService: PmcTranslationLoaderService,
        private _userService: UserService,
        private _eventEmitService: EventEmitService,
        private _router: Router,
        private _pmcConfigService: PmcConfigService,
        private _alertService: AlertService,
        private _appInsightsService: AppInsightsService
    ) {
        this._pmcTranslationLoaderService.loadTranslations(english);
        this.allergyList = new AllergyList();
       
        let self = this;
        this.contextSubscription = this._eventEmitService.userContextChangeEvent.subscribe(
            (data: any) => {
                if (
                    (this._router.url === "/dashboard" ||
                        this._router.url === "") &&
                    data.Id !== this.currentResident.Id
                ) {
                    self.initializeTiles();
                }
            }
        );

        this._pmcConfigService.config = {
            layout: {
                body: { contain: false }
            }
        };
    }
    
    ngAfterViewInit(): void {}

    ngOnDestroy() {
        this.contextSubscription.unsubscribe();
    }

    ngOnInit() {
        this.userProfile = this._userService.getMyUserProfile();
        if (this.userProfile.EmailStatus == "changed" )
        {
            this.isNotification = false;
        }
        else
        {
            
            this.loadEmailNotify();
            this.isNotification = true;
        }
        this.initializeTiles();
       
    }
    // openEhealthDialog(){

    //     this.dialog.open(EhealthDailogComponent, {
    //         width: "500px"
           
    //     });

    // }
    
    initializeTiles() {
        let activeResident: ResidentAccount = this._userService.getActiveResident();
        this.currentResident = activeResident;

        let params = `/${activeResident.ResidentCode}/${
            activeResident.FacilityCode
        }/${activeResident.PharmacyCode}`;

        let params2 = `/${activeResident.ResidentCode}/${
            activeResident.FacilityCode
        }/${activeResident.PharmacyCode}`;


        this.tileResidentInfo = new Tile("Resident Info", {
            component: TileResidentInfoComponent,
            dataUrl: "/Resident/GetResidentDemographicInfo",
            dataParameters: params,
            cacheKey: DEMOGRAPHICINFO + activeResident.ResidentCode
        });

        this.tileAccountSummary = new Tile("Account Summary", {
            component: TileOrderSummaryComponent,
            showSpinner: true,
            dataUrl: "/payment/GetPaymentHistory",
            dataParameters: params,
            cacheKey: ORDER_HISTORY + activeResident.ResidentCode
        });

        this.tileResidentAllergies = new Tile("Resident Allergies", {
            component: TileResidentAllergiesComponent,
            dataUrl: "/Values/GetAllergies",
            showSpinner: true,
            dataParameters: params,
            cacheKey: ALLERGIES + activeResident.ResidentCode
        });

        this.tileEHealthHelp = new Tile("EHealth Help", {
            component: TileEHealthHelpComponent,
            dataUrl: "/Resident/GetResidentDemographicInfo",
            dataParameters: params,
            cacheKey: DEMOGRAPHICINFO + activeResident.ResidentCode

        });
        
    }
    loadEmailNotify()
    {
        
       
        if (this.userProfile.EmailStatus == "initial")
        {
            this.subject = "Email Address Not Confirmed"
            this.body = "Our system shows that you have not confirmed the email " + this.userProfile.UserEmail +  ". Confirm or Update now.";
        }
        if (this.userProfile.EmailStatus == "diff")
        {
            this.subject = "Email Address Changed"
            this.body = "Our system shows that your email address may have changed to " + this.userProfile.UserEmail + ". Would you like to accept this change?";
        }
        if (this.userProfile.EmailStatus == "empty")
        {
            this.subject = "Email Address Missing"
            this.body = "Our system shows that you do not have an email address associated with your account. Please add email now";
        }
       
        // let icon = new InfoIcon();
       
        // icon.type = InfoIconType.FontAwesomeIcon;
        // icon.iconInstruction = "fa-bell icon-2x icon-cog text-red";
        // icon.list =[this.subject, this.body];
        //this.infoIcons.push(icon);

    }
    reject(){
        console.log("reject");
        this.userProfile.EmailStatus = "changed";
        this._userService.changeMyEmail(this.userProfile)
        .subscribe(
             value => {
                 if (value === true) {
                 
                     this._appInsightsService.logEvent("Email has been changed Success.");
                     this._userService.setMyUserProfile(this.userProfile);
                     this._alertService.success(
                         "Your email address has been updated."
                     );
                     this.isNotification = false;
                 
                 }
             },
             (error: any) => {
                 
                 this._appInsightsService.logEvent("Email Change Failure.");
                 this._alertService.error(
                     "Unable to change email please try again"
                 );
             },
           
         );

    }
    confirm(){

       console.log("confirm");
       this.userProfile.Email = this.userProfile.UserEmail;
       this.userProfile.EmailStatus = "changed";
       this._userService.changeMyEmail(this.userProfile)
       .subscribe(
            value => {
                if (value === true) {
                
                    this._appInsightsService.logEvent("Email has been confirmed Success.");
                   
                    this._userService.setMyUserProfile(this.userProfile);
                    this._alertService.success(
                        "Your email address has been updated."
                    );
                    this.isNotification = false;
                   
                   
                
                }
            },
            (error: any) => {
                
                this._appInsightsService.logEvent("Email Change Failure.");
                this._alertService.error(
                    "Unable to change email please try again"
                );
            },
          
        );


    }
    change(){

        console.log("change");
       
        this._router.navigate(["/account/settings"]);
    }

    
}
