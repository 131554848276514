import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatMomentDateModule } from "@angular/material-moment-adapter";

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from "@angular/material/dialog";
import { MatDialog } from "@angular/material/dialog";
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginator } from "@angular/material/paginator";

import { MatInputModule } from '@angular/material/input';


import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { ServiceWorkerModule } from "@angular/service-worker";
import "hammerjs";

import { PmcModule } from "@pmc/pmc.module";
import { PmcSharedModule } from "@pmc/shared.module";
import {
    PmcProgressBarModule,
    PmcSidebarModule,
    PmcThemeOptionsModule
} from "@pmc/components";

import { pmcConfig } from "app/pmc-config";

import { Config } from "./config/config";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { PagesModule } from "app/main/pages.module";
import { environment } from "../environments/environment";
import { AlertService } from "../app/services/alert.service";
import { TranslateModule } from "@ngx-translate/core";

export function initializeApp(config: Config) {
    return () => config.load();
}
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        ServiceWorkerModule.register("./ngsw-worker.js", {
            enabled: environment.production
        }),
        HttpModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
        MatSortModule,
        MatTableModule,

        // Pmc modules
        PmcModule.forRoot(pmcConfig),
        PmcProgressBarModule,
        PmcSharedModule,
        PmcSidebarModule,
        PmcThemeOptionsModule,
        // App modules
        LayoutModule,
        PagesModule
    ],
    providers: [
        Config,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [Config],
            multi: true
        },
        MatDialog,
        AlertService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
