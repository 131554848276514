import { Component, OnInit } from "@angular/core";

import { pmcConfig } from "app/pmc-config";

@Component({
    selector: "legacy-form-wrap",
    templateUrl: "./legacy-form.component.html",
    styleUrls: ["./legacy-form.component.scss"]
})
export class LegacyFormComponent implements OnInit {
    tollFreeNumber: string;
    tollFreeNumberTel: string;

    constructor() {}

    ngOnInit() {
        this.tollFreeNumber = pmcConfig.layout.header.tollFreeNumber;
        this.tollFreeNumberTel = pmcConfig.layout.header.tollFreeNumber.replace(
            /\D/g,
            ""
        );
    }
}
