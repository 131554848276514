import { Component, Input, OnInit } from "@angular/core";
import { DynamicComponent } from "app/models/ui/dynamicComponent";
import { InfoIcon, InfoIconType } from "app/models/ui/infoIcon";
import { MatDialog } from "@angular/material/dialog";
import { EhealthDailogComponent } from "app/main/dashboard/ehealth-dailog/ehealth-dailog.component";

@Component({
    template: `
    <div class="card pb-0 full rounded">
        <div class="tile default tile-resident-allergies">
            <div class="tile-header">
                Medicare Savings
            </div>
            <div class="tile-body">
                <div class="info-icons" *ngFor="let ra of infoIcons">
                    <app-info-icon [infoIcon]="ra"></app-info-icon>
                </div>
                <div style="height: 40px;">
                    <button mat-button class="btn-default mat-button" style="float: right;" (click)="openEhealthDialog()">
                        Visit eHealth
                    </button>
                </div>
            
            
            </div>
        </div>
    </div>
    `
})
export class TileEHealthHelpComponent
    implements DynamicComponent, OnInit {
    @Input() data: any;
    infoIcons: InfoIcon[];

    constructor(
        private dialog: MatDialog,
    ) {
        this.infoIcons = [];
    }

    initTile() {
        
        this.loadComponent();
    }

    ngOnInit() {
        this.initTile();
    }

    loadComponent() {        
        let icon = new InfoIcon();
        icon.type = InfoIconType.CustomImage;
        icon.iconName = "";
        icon.list = "Medicare’s Annual Election Period starts October 15 and ends December 7. My PharMerica has partnered with eHealth, a licensed health insurance agency to provide a free, no obligation review of your Medicare coverage. Shop now to find affordable Medicare insurance plans.".split("|");
        icon.iconInstruction = "/assets/icons/custom/ehealth.png";
        this.infoIcons.push(icon);

               
    }
    openEhealthDialog(){

        this.dialog.open(EhealthDailogComponent, {
            width: "500px"
           
        });

    }
}
