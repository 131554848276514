import { PmcNavigation } from "@pmc/types";

export const navigation: PmcNavigation[] = [
    // {
    //     id: "home",
    //     title: "",
    //     type: "item",
    //     icon: "dashboard",
    //     url: "/dashboard"
    // },
    {
        id: "medications",
        title: "My Medications",
        type: "group",
        children: [
            {
                id: "medication-list",
                title: "Medication List",
                type: "item",
                icon: "list",
                url: "/medications/list"
            },
            {
                id: "order-history",
                title: "Order History",
                type: "item",
                icon: "history",
                url: "/medications/order-history"
            }
        ]
    },
    {
        id: "store",
        title: "Store",
        faIcon: "fa-shopping-cart",
        type: "item",
        url: "http://store.myvaluemed.com",
        externalUrl: true
    },
    {
        id: "notifications",
        title: "Notifications",
        type: "item",
        faIcon: "fa-bell",
        url: "/notifications",
         badge: {
            title: "",
            translate: "",
            bg: "#F44336",
            fg: "#FFFFFF"
        } 
    },
    {
        id: "account",
        title: "My Account",
        type: "group",
        children: [
            {
                id: "account-manage-doctors",
                title: "Manage My Doctors",
                type: "item",
                icon: "contacts",
                url: ""
            },
            {
                id: "account-settings",
                title: "Account Settings",
                type: "item",
                icon: "settings",
                url: "/account/settings"
            },
            {
                id: "logout",
                title: "Logout",
                type: "item",
                icon: "exit_to_app",
                url: "/login"
            }
        ]
    }
];
