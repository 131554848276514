import { NgModule } from '@angular/core';

import { PmcSidebarComponent } from './sidebar.component';

@NgModule({
    declarations: [
        PmcSidebarComponent
    ],
    exports     : [
        PmcSidebarComponent
    ]
})
export class PmcSidebarModule
{
}
