export class Enrollment{
    public constructor(init?: Partial<Enrollment>) {
        Object.assign(this, init);
        }
    FacilityName: string;
    FacilityCode: string;
    FacilityId: string;
    PharmacyCode: string;
    PharmacyName: string;
    Referal: string;
    UserType: string;
    ResidentFirstName: string;
    ResidentLastName: string;
    ResidentPrescriber: string;
    ResidentAdmissionDate: string;
    ResidentBirthdate: string;
    ResidentPhoneNumber: string;
    ResidentSsn: string;
    ResidentEmail: string;
    ResidentGender: string; 
    ResidentStation: string;
    ResidentRoomNumber: string;
    ResidentBedNumber: string;
    ResidentInsType: string;
    ResidentMedicare: string;
    Residentmedicaid: string;
    ResidentInsCarrier: string;
    ResidentInsMemberId: string;
    ResidentInsuranceGroup: string;
    ResidentInsurancePhone: string;
    ResidentBin: string;
    ResidentPcn: string;
    ResidentGrp: string; 
    ResidentAllergies: string;
    ResponsiblePartyName: string;
    ResponsiblePartyRelationship: string;
    ResponsiblePartyPhoneNumber: string;
    ResponsiblePartyEmail: string;
    ResponsiblePartyAddress: string;
    ResponsiblePartyCity: string;
    ResponsiblePartyState: string;
    ResponsiblePartyZip: string;
    IsSigned: string;
    IncludeMail: string;
    SignDate: string;
    };



    
