import { Directive, Input, OnInit, HostListener, OnDestroy, HostBinding } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaObserver } from '@angular/flex-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PmcMatchMediaService } from '@pmc/services/match-media.service';
import { PmcMatSidenavHelperService } from '@pmc/directives/pmc-mat-sidenav/pmc-mat-sidenav.service';

@Directive({
    selector: '[pmcMatSidenavHelper]'
})
export class PmcMatSidenavHelperDirective implements OnInit, OnDestroy
{
    @HostBinding('class.mat-is-locked-open')
    isLockedOpen: boolean;

    @Input()
    pmcMatSidenavHelper: string;

    @Input()
    matIsLockedOpen: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {PmcMatchMediaService} _pmcMatchMediaService
     * @param {PmcMatSidenavHelperService} _pmcMatSidenavHelperService
     * @param {MatSidenav} _matSidenav
     * @param {ObservableMedia} _observableMedia
     */
    constructor(
        private _pmcMatchMediaService: PmcMatchMediaService,
        private _pmcMatSidenavHelperService: PmcMatSidenavHelperService,
        private _matSidenav: MatSidenav,
        private _observableMedia: MediaObserver
    )
    {
        // Set the defaults
        this.isLockedOpen = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Register the sidenav to the service
        this._pmcMatSidenavHelperService.setSidenav(this.pmcMatSidenavHelper, this._matSidenav);

        if ( this._observableMedia.isActive(this.matIsLockedOpen) )
        {
            this.isLockedOpen = true;
            this._matSidenav.mode = 'side';
            this._matSidenav.toggle(true);
        }
        else
        {
            this.isLockedOpen = false;
            this._matSidenav.mode = 'over';
            this._matSidenav.toggle(false);
        }

        this._pmcMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if ( this._observableMedia.isActive(this.matIsLockedOpen) )
                {
                    this.isLockedOpen = true;
                    this._matSidenav.mode = 'side';
                    this._matSidenav.toggle(true);
                }
                else
                {
                    this.isLockedOpen = false;
                    this._matSidenav.mode = 'over';
                    this._matSidenav.toggle(false);
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

@Directive({
    selector: '[pmcMatSidenavToggler]'
})
export class PmcMatSidenavTogglerDirective
{
    @Input()
    pmcMatSidenavToggler: string;

    /**
     * Constructor
     *
     * @param {PmcMatSidenavHelperService} _pmcMatSidenavHelperService
     */
    constructor(
        private _pmcMatSidenavHelperService: PmcMatSidenavHelperService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On click
     */
    @HostListener('click')
    onClick(): void
    {
        this._pmcMatSidenavHelperService.getSidenav(this.pmcMatSidenavToggler).toggle();
    }
}
