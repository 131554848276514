import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Config } from "../config/config";
import { AppVal, AppValSubject } from "../models/app-val";

@Injectable({
    providedIn: "root"
})
export class AppValService {
    protected apiServer = Config.settings.apiServer;
    private localValLocation = "./assets/app-vals.json";

    constructor(private http: HttpClient) {}

    localVals(): Observable<AppValSubject[]> {
        //check cache first? timeout?
        let vals = this.http.get<AppValSubject[]>(this.localValLocation);
        return vals;
    }

    getLocalVal(
        categoryName: string,
        keyName: string,
        callback: Function,
        errorCallback: Function,
        finalCallback: Function
    ) {
        var obj = this.localVals();
        obj.subscribe(
            data => {
                let t = data;
                let category = t.filter(x => x.category === categoryName)[0];
                if (category && category.values.length > 0) {
                    let y = (<AppVal[]>category.values).filter(
                        x => x.key === keyName
                    );
                    callback(y[0]);
                } else {
                    callback(new AppVal());
                }
            },
            error => errorCallback(error),
            () => finalCallback()
        );
    }
}
