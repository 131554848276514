import { Component, Input } from "@angular/core";
import { DynamicComponent } from "app/models/ui/dynamicComponent";
import { InfoIcon, InfoIconType } from "app/models/ui/infoIcon";

@Component({
    template: `
        <div class="card med-list-card pb-0 rounded">
            <div class="tile default tile-resident-info pb-0">
                <div class="tile-header" pmcLoadingDots>
                    {{ data.tileData?.FirstName }}
                    {{ data.tileData?.LastName
                    }}<span *ngIf="data.tileData?.FacilityName">,</span>
                    {{ data.tileData?.FacilityName }}
                </div>
                <div class="tile-body">
                    <div class="card-body">
                        <div class="pmc-flex-wrap rs-50">
                            <div class="pmc-flex-item">
                                <app-info-icon
                                    class="plan-name-ii"
                                    [infoIcon]="plan"
                                ></app-info-icon>
                                <ul class="nvp-list">
                                    <li>
                                        <div>
                                            <label>Provider:</label>
                                            <span
                                                pmcLoadingDots
                                                *ngIf="
                                                    data.tileData?.Plan?.InsurerProviderName?.trim()
                                                        .length > 0;
                                                    else notAvailable
                                                "
                                            >
                                                {{
                                                    data.tileData?.Plan
                                                        ?.InsurerProviderName
                                                }}
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <label>Phone:</label>
                                            <span
                                                pmcLoadingDots
                                                *ngIf="
                                                    data.tileData?.Plan?.InsurerProviderPhone?.trim()
                                                        .length > 0;
                                                    else notAvailable
                                                "
                                            >
                                                {{
                                                    data.tileData?.Plan
                                                        ?.InsurerProviderPhone
                                                        | phone
                                                }}
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="pmc-flex-item">
                                <app-info-icon
                                    class="responsible-party-ii"
                                    [infoIcon]="rp"
                                ></app-info-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #notAvailable> Not Available </ng-template>
            </div>
        </div>
    `
})
export class TileResidentInfoComponent implements DynamicComponent {
    @Input() data: any;
    plan: InfoIcon = new InfoIcon();
    rp: InfoIcon = new InfoIcon();

    constructor() {}

    ngOnInit() {
        this.initTile();
    }

    initTile() {
        this.data.successFn = () => {
            this.loadComponent();
        };
        this.loadComponent();
    }

    loadComponent() {
        if (this.data.tileData) {
            let IIplan = new InfoIcon();
            let planName =
                this.data.tileData.Plan.PlanName !== ""
                    ? this.data.tileData.Plan.PlanName
                    : "Not Available";
            IIplan.list = ["PLAN NAME", planName];
            IIplan.type = InfoIconType.FontAwesomeIcon;
            IIplan.iconInstruction = "fa-file-text";
            this.plan = IIplan;

            let IIrp = new InfoIcon();
            let rpName =
                this.data.tileData.ResponsibleParty.ResponsiblePartyFirstName +
                " " +
                this.data.tileData.ResponsibleParty.ResponsiblePartyLastName;
            IIrp.list = ["RESPONSIBLE PARTY", rpName];
            IIrp.type = InfoIconType.FontAwesomeIcon;
            IIrp.iconInstruction = "fa-users";
            this.rp = IIrp;
        }
    }
}
