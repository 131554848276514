import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from "@angular/material/radio";
import { MatStepperModule} from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PmcSharedModule } from "@pmc/shared.module";
import { EnrollComponent } from "./enroll.component";
import { EnrollStep1Component } from './step1/enroll-step1.component';
import { EnrollStep2Component } from './step2/enroll-step2.component';
import { EnrollStep3Component } from './step3/enroll-step3.component';
import { EnrollStep4Component } from './step4/enroll-step4.component';



const routes = [
    {
        path: "enroll",
        component: EnrollComponent
    },
    {
        path: "enroll/:code",
        component: EnrollComponent
    }
];

@NgModule({
    declarations: [EnrollComponent,
        EnrollStep1Component,
        EnrollStep2Component,
        EnrollStep3Component,
        EnrollStep4Component],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatStepperModule,
        MatAutocompleteModule,
        TranslateModule,
        MatTableModule,
        MatSliderModule,
        MatCardModule,
        MatSelectModule,
        MatTooltipModule,
        PmcSharedModule
    ],
    exports: [EnrollComponent]
})
export class EnrollModule {}
