import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-of-use-dialog',
  templateUrl: './terms-of-use-dialog.component.html'
})
export class TermsOfUseDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
