import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { Config } from "../config/config";
import { AppUserAuth, AppUser } from "app/models/user/app-user";
import { tap } from "rxjs/operators";
import { AppInsightsService } from 'app/appInsights/app-insights.service';

//import { UserManager, User, WebStorageStateStore, Log } from 'oidc-client';
//import { Constants } from "./constants";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    authObject: AppUserAuth = new AppUserAuth();

    protected apiServer = Config.settings.apiServer;
   
    constructor(private http: HttpClient, private config: Config, 
        private appInsightsService: AppInsightsService ) {
        this.apiServer = Config.settings.apiServer;
    }

    login(entity: AppUser, callBack: Function, errorCallBack: Function, reset: boolean = false) {
       
        this.appInsightsService.clearAuthenticatedUserId();
        if (reset)
            this.resetAuthObject();
        this.loginUser(entity).subscribe(
            t => {
                if ( t == "")
                {
                    this.appInsightsService.logEvent("Login Failure");
                    this.authObject = new AppUserAuth();
                    errorCallBack("Unable to validate your credentials. Please verify your credentials and try again.");
                }
                else if( t== "lock")
                {
                    this.appInsightsService.logEvent("Login Failure Account Locked");
                    this.authObject = new AppUserAuth();
                    errorCallBack("lock");

                }
                else{
                    this.authObject.bearerToken = t;
                    sessionStorage.setItem(
                        "currentUser",
                        JSON.stringify(this.authObject)
                    );
                    callBack(this.authObject);
                   
                }               
            },
            error => {
                this.appInsightsService.logEvent("Login Failure");
                
                this.authObject = new AppUserAuth();
                errorCallBack(error);
                
            }
        )
       
    }
    loginNewUser(entity: AppUser, callBack: Function, errorCallBack: Function, reset: boolean = false) {
        this.appInsightsService.clearAuthenticatedUserId();
        this.authObject.bearerToken = entity.token;
        sessionStorage.setItem(
            "currentUser",
            JSON.stringify(this.authObject)
        );
        callBack(this.authObject);
       
    }
    
    loginUser(entity: AppUser): Observable<string> {
        return this.http.post<string>(
            this.apiServer.metadata + "/login",
            entity
        );
    }
    logout(): void {
        this.resetAuthObject();
    }
    userLogout(): Observable<any> {
        return this.http.get<any>(
            this.apiServer.metadata + "/logout/"
        );
    }
    resetAuthObject(): void {
        this.appInsightsService.clearAuthenticatedUserId();
        this.authObject.userName = "";
        this.authObject.bearerToken = "";
        this.authObject.isAuthenticated = false;
        this.authObject.isExternalLink = false;
        this.authObject.isNotification = false;
        this.authObject.isStoreLink = false;

        sessionStorage.clear();
    }
}
