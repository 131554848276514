import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ChangePasswordService {
    private oldPassword: string;
    private needsPasswordChange: boolean;
    private userName: string;

    setPasswordChange(needsChange: boolean) {
        sessionStorage.setItem("needsPasswordChange", needsChange.toString());
        this.needsPasswordChange = needsChange;
    }

    getPasswordChange(): boolean {
        this.needsPasswordChange = (sessionStorage.getItem("needsPasswordChange") === "true");
        return this.needsPasswordChange;
    }

    setPassword(oldPassword: string) {
        sessionStorage.setItem("oldPassword", oldPassword);
        this.oldPassword = oldPassword;
    }

    getPassword(): string {
        this.oldPassword = sessionStorage.getItem("oldPassword");
        return this.oldPassword;
    }

    setUserName(userName: string) {
        sessionStorage.setItem("userName", userName);
        this.userName = userName;
    }

    getUserName(): string {
        this.userName = sessionStorage.getItem("userName");
        return this.userName;
    }

    clearPasswordData() {
        this.needsPasswordChange = false;
        this.oldPassword = "";
        sessionStorage.removeItem("oldPassword");
        sessionStorage.removeItem("needsPasswordChange");
    }
}
