import { ResidentRequest } from './ResidentRequest'
import { AllergyInfo } from './allergyInfo'

export class AllergyList {
    constructor(
    public ResidentRequest?: ResidentRequest,
    public Allergies?: AllergyInfo[])
    {}
}

