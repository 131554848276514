import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { PmcConfigService } from "@pmc/services/config.service";
import { navigation } from "app/navigation/navigation";

import { EventEmitService } from "app/services/event-emit.service";
import { UserService } from "app/services/user.service";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertService } from "app/services/alert.service";
import { StatusIndicatorService } from "app/services/status-indicator.service";
import { Alert } from "app/models/alert";
import { UserStatusIndicator } from "app/models/ui/userStatusIndicator";

@Component({
    selector: "vertical-layout-1",
    templateUrl: "./layout-1.component.html",
    styleUrls: ["./layout-1.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
    pmcConfig: any;
    navigation: any;
    currentContextName: string;
    currentUser: any;
    currentContext: any;
    displayResidents: any;
    currentUserStatusIndicator: UserStatusIndicator = new UserStatusIndicator();

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {PmcConfigService} _pmcConfigService
     * @param {EventEmitService} _eventEmitService
     * @param {AlertService} alertService
     * @param {UserService} _userService
     * @param {Router} _router
     * @param {MatSnackBar} snackBar
     * @param {StatusIndicatorService} statusIndicatorService
     */
    constructor(
        private _pmcConfigService: PmcConfigService,
        private _eventEmitService: EventEmitService,
        private alertService: AlertService,
        private _router: Router,
        public snackBar: MatSnackBar,
        public statusIndicatorService: StatusIndicatorService
    ) {
        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        _router.events.subscribe(e => {
            if (e instanceof NavigationStart) {
            }

            if (e instanceof NavigationEnd) {
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._pmcConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                this.pmcConfig = config;
            });

        this.statusIndicatorService.subject.subscribe(
            (userStatusIndicator: UserStatusIndicator) => {
                this.currentUserStatusIndicator = userStatusIndicator;
            }
        );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.alertService.subject.unsubscribe();
    }

    ngAfterViewInit() {
        this.alertService.subject.subscribe((alert: Alert) => {
            this.snackBar.open(alert.message, "", {
                duration: 5000
            });
        });
    }
}
