import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Subject } from "rxjs/Subject";
import {
    UserStatusIndicator,
    UserStatusIndicatorType
} from "../models/ui/userStatusIndicator";

@Injectable({
    providedIn: "root"
})
export class StatusIndicatorService {
    public subject = new Subject<UserStatusIndicator>();

    constructor() {}

    active() {
        this.display(new UserStatusIndicator());
    }

    clear() {
        this.subject.next(new UserStatusIndicator());
    }

    display(userStatusIndicator: UserStatusIndicator) {
        this.subject.next(userStatusIndicator);
    }

    getStatusIndicator(): Observable<any> {
        return this.subject.asObservable();
    }

    readOnly() {
        this.display(
            new UserStatusIndicator({
                message: "Status: Read-Only",
                type: UserStatusIndicatorType.ReadOnly,
                keepAfterRouteChange: true,
                userStatusIndicatorId: "read-only"
            })
        );
    }
}
