import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap, shareReplay } from "rxjs/operators";
import { UserProfile, ResidentAccount } from "../models/user/userProfile";
import { Config } from "../config/config";
import { AllergyList } from "../models/allergyList";
import { DemographicInfo } from "../models/demographicInfo";
import { PaymentHistory } from "../models/paymentHistory";
import { MedicationList } from "app/models/medicationList";
import { DoctorByAddress } from "app/models/doctorByAddress";
import {
    CachingService,
    ORDER_HISTORY,
    ALLERGIES,
    DEMOGRAPHICINFO
} from "./caching.service";
import { CacheItem } from "../models/generic/cacheItem";

@Injectable({
    providedIn: "root"
})
export class UserService {
    //private userUrl = 'assets/mock-data/user.json';
    //private userUrl = 'http://localhost:61582/api/Values/GetUser';
    protected apiServer = Config.settings.apiServer;
    constructor(
        private http: HttpClient,
        private config: Config,
        private cachingService: CachingService
    ) {
        this.apiServer = Config.settings.apiServer;
    }

    getUserProfile(): Observable<UserProfile> {
        return this.http.get<UserProfile>(
            this.apiServer.metadata + "/Values/GetUser"
        );
    }
   
    changeMyPassword(
        newPassword: string
    ): Observable<boolean> {
        return this.http
            .get<boolean>(
                this.apiServer.metadata +
                    "User/ChangeMyPassword/" +
                    newPassword
            )
            .pipe(tap(data => console.log("All: " + JSON.stringify(data))));
    }
    getUserUrl(): Observable<string> {
        let activeResident: ResidentAccount = this.getActiveResident();
        return this.http.get<string>(
            this.apiServer.metadata +
                "/user/GetUrl/" +
                activeResident.ResidentCode +
                "/" +
                activeResident.FacilityCode +
                "/" +
                activeResident.PharmacyCode
            )
            .pipe(tap(data => console.log("All: " + JSON.stringify(data))));
            
    }
    changeMyEmail(entity: UserProfile): Observable<boolean> {
        return this.http.post<boolean>(
            this.apiServer.metadata + "/changeemail",
            entity
        );
    }
    getNotification(): Observable<Notification> {
        let activeResident: ResidentAccount = this.getActiveResident();
        if (activeResident.NotificationCount !== "")
        {
            activeResident.NotificationCount = "";
            //update resident notification count
            var residents = this.getAllResidents();
            let r1: ResidentAccount = residents.find(
                r => r.ResidentCode=== activeResident.ResidentCode
            );
            r1.NotificationCount = "";
            this.setAllResidents(residents);
            
        }
        return this.http.get<Notification>(
            this.apiServer.metadata +
                "/user/GetNotificationList/" +
                activeResident.ResidentCode
        );
    }

    getResidentDemographicInfo(): Observable<DemographicInfo> {
        let activeResident: ResidentAccount = this.getActiveResident();
        let item: CacheItem = this.cachingService.getCacheItem(
            DEMOGRAPHICINFO + activeResident.ResidentCode
        );
        let demographicInfo: Observable<DemographicInfo>;
        if (!item) {
            demographicInfo = this.http
                .get<DemographicInfo>(
                    this.apiServer.metadata +
                        "/Resident/GetResidentDemographicInfo/" +
                        activeResident.ResidentCode +
                        "/" +
                        activeResident.FacilityCode +
                        "/" +
                        activeResident.PharmacyCode
                )
                .pipe(shareReplay(1));
            item = new CacheItem();
            item.data = demographicInfo;
            item.expiryTime = 900;
            this.cachingService.setCacheItem(
                item,
                DEMOGRAPHICINFO + activeResident.ResidentCode
            );
        }
        demographicInfo = item.data;
        return demographicInfo;
    }

    getAllMedicationList(): Observable<MedicationList> {
        let activeResident: ResidentAccount = this.getActiveResident();
        return this.http.get<MedicationList>(
            this.apiServer.metadata +
                "/medication/GetAllMedications/" +
                activeResident.ResidentCode +
                "/" +
                activeResident.FacilityCode +
                "/" +
                activeResident.PharmacyCode
        );
    }

    

    getDoctorList(): Observable<DoctorByAddress[]> {
        let activeResident: ResidentAccount = this.getActiveResident();
        return this.http.get<DoctorByAddress[]>(
            this.apiServer.metadata +
                "/doctor/GetDoctorByAddressList/" +
                activeResident.ResidentCode +
                "/" +
                activeResident.FacilityCode +
                "/" +
                activeResident.PharmacyCode
        );
    }

    getDrugInfo(ndc: string): Observable<Response> {
        return this.http
            .get<Response>(
                this.apiServer.metadata + "/DrugsInfo/GetDrugInformation/" + ndc
            )
            .pipe(tap(data => console.log("All: " + JSON.stringify(data))));
    }

    getPaymentHistory(): Observable<PaymentHistory> {
        let activeResident: ResidentAccount = this.getActiveResident();
        let item: CacheItem = this.cachingService.getCacheItem(
            ORDER_HISTORY + activeResident.ResidentCode
        );
        let paymentHistory: Observable<PaymentHistory>;
        if (!item) {
            let params = `/${activeResident.ResidentCode}/${
                activeResident.FacilityCode
            }/${activeResident.PharmacyCode}`;
            paymentHistory = this.http
                .get<PaymentHistory>(
                    this.apiServer.metadata +
                        "/payment/GetPaymentHistory" +
                        params
                )
                .pipe(shareReplay(1));

            item = new CacheItem();
            item.data = paymentHistory;
            item.expiryTime = 900;
            this.cachingService.setCacheItem(
                item,
                ORDER_HISTORY + activeResident.ResidentCode
            );
        }
        paymentHistory = item.data;
        return paymentHistory;
    }

    checkExpiryTime(item: CacheItem): boolean {
        let currentDate = new Date();
        item.addedTime.setMinutes(item.expiryTime);
        return item.addedTime < currentDate;
    }

    getFamilyMemberCount(residentCode: string): Observable<number> {
        return this.http.get<number>(
            this.apiServer.metadata + "/user/getFamilyMemberCount/" + residentCode
        );
    }

    getAllergies(): Observable<AllergyList> {
        let activeResident: ResidentAccount = this.getActiveResident();
        let item: CacheItem = this.cachingService.getCacheItem(
            ALLERGIES + activeResident.ResidentCode
        );
        let allergyList: Observable<AllergyList>;
        if (!item) {
            allergyList = this.http
                .get<AllergyList>(
                    this.apiServer.metadata +
                        "/Values/GetAllergies/" +
                        activeResident.ResidentCode +
                        "/" +
                        activeResident.FacilityCode +
                        "/" +
                        activeResident.PharmacyCode
                )
                .pipe(shareReplay(1));
            item = new CacheItem();
            item.data = allergyList;
            item.expiryTime = 900;
            this.cachingService.setCacheItem(
                item,
                ALLERGIES + activeResident.ResidentCode
            );
        }
        allergyList = item.data;
        return allergyList;
    }

    getActiveResident(): ResidentAccount {
        return JSON.parse(sessionStorage.getItem("activeResident"));
    }

    updateViewNotification(id: number): Observable<number> {
        return this.http.get<number>(
            this.apiServer.metadata + "/user/UpdateViewNotification/" + id
        );
    }

    setActiveResident(reisdentId: number) {
        let allresidents: ResidentAccount[] = JSON.parse(
            sessionStorage.getItem("allResident")
        );
        let activeResident: ResidentAccount = allresidents.find(
            r => r.Id === reisdentId
        );
        sessionStorage.setItem(
            "activeResident",
            JSON.stringify(activeResident)
        );
    }

    setAllResidents(reisdents: ResidentAccount[]) {
        sessionStorage.setItem("allResident", JSON.stringify(reisdents));
    }

    getAllResidents(): ResidentAccount[] {
        return JSON.parse(sessionStorage.getItem("allResident"));
    }

    setMyUserProfile(userProfile: UserProfile) {
        sessionStorage.setItem("userProfile", JSON.stringify(userProfile));
    }

    getMyUserProfile(): UserProfile {
        return JSON.parse(sessionStorage.getItem("userProfile"));
    }

    private handleError(err: HttpErrorResponse) {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage = "";
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Server returned code: ${
                err.status
            }, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}
