export class InfoIcon {
    public list: string[];
    public iconInstruction: string;
    public iconName: string;
    public type: InfoIconType;
    constructor() {
        this.type = InfoIconType.CustomImage;
    }
}

export enum InfoIconType {
    MatIcon,
    FontAwesomeIcon,
    CustomImage
}
